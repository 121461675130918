<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select Resource</h2>
  </div>

  <mat-dialog-content>
    <app-autocomplete [label]="'Resource'"
                      [placeholder]="'Select a Resource'"
                      [(value)]="selectionId"
                      [options]="options"
                      [canBeNull]="false">
    </app-autocomplete>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()">Close</button>
  </mat-dialog-actions>
</section>
