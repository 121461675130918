import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { ResourceModel } from "../../../../models/resource-model";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from "@angular/material/dialog";
import { ProcessModel } from "../../../../models/process-model";
import { AuthService } from "../../../../services/auth.service";
import { ResourceService } from "../../../../services/resource.service";
import { ToastrService } from "ngx-toastr";
import { MatTableUtility } from "../../../../utility/mat-table-utility";
import { ResourceSelectDialogComponent } from "../../../../shared/resource-select-dialog/resource-select-dialog.component";

@Component({
  selector: 'app-process-resource-list-dialog',
  templateUrl: './process-resource-list-dialog.component.html',
  styleUrls: ['./process-resource-list-dialog.component.scss']
})

export class ProcessResourceListDialogComponent implements OnInit {
  public isLoading: boolean = true;
  public resources: Array<ResourceModel> = [];
  public processResourceTableData = new MatTableDataSource<ResourceModel>([]);
  public processResourceColumns: string[] = ['resourceGroupTitle', 'title'];

  public resourceSort: MatSort;

  @ViewChild('processResourceTable', { read: MatSort, static: false }) set processResourceSortValue(value: MatSort) {
    if (value) {
      this.resourceSort = value;
      this.processResourceTableData.sort = this.resourceSort;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public process: ProcessModel,
    private dialogRef: MatDialogRef<ProcessResourceListDialogComponent>,
    private authService: AuthService,
    private resourceServcie: ResourceService,
    private toastr: ToastrService,
    private dialog: MatDialog){
  }

  public ngOnInit(): void {
    this.processResourceTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    if (this.canEdit()) {
      this.processResourceColumns.push("controls");
    }

    this.resourceServcie.getResourcesForProcess(this.process.processId).subscribe({
      next: (response: Array<ResourceModel>) => {
        this.resources = response;
        this.refreshProcessResourceTableData();
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  private refreshProcessResourceTableData(): void {
    this.processResourceTableData.data = this.resources;
  }

  public createProcessResource() {
    if (!this.canEdit()) {
      return;
    }

    const selectedResourceIds = this.resources.map(i => i.resourceId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedResourceIds: selectedResourceIds
      },
      width:"60%",
    }

    const dialogRef = this.dialog.open(ResourceSelectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: number | null) => {
        if (result) {
          this.resourceServcie.saveProcessResource(this.process.processId, result).subscribe({
            next: (response: ResourceModel) => {
              this.resources.push(response);
              this.refreshProcessResourceTableData();
              this.toastr.success("Resource added");
            }
          });
        }
      }
    });
  }

  public deleteProcessResource(resource: ResourceModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Resource "${resource.title}"`)) {
      return
    }

    this.resourceServcie.deleteProcessResource(this.process.processId, resource.resourceId!).subscribe({
      next: () => {
        this.resources.splice(this.resources.indexOf(resource), 1);
        this.refreshProcessResourceTableData();
        this.toastr.success("Process Resource deleted");
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
