import { AutocompleteOptionModel } from "./autocomplete-option-model";

export class CategoryModel extends AutocompleteOptionModel {
  public categoryId: number;
  public companyId: number;
  public name: string;
  public description: string | null;
  public createdUtc: Date;
  public modifiedUtc: Date;

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.categoryId;
      this.displayValue = data.name;
      Object.assign(this, data);
    }
  }
}
