import { Component, OnInit, ViewChild } from "@angular/core";
import { RoleRegisterModel } from "../../../models/role-register-model";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { RoleService } from "../../../services/role.service";
import { MatTableUtility } from "../../../utility/mat-table-utility";
import { FileDocumentUtility } from "../../../utility/file-document-utility";

@Component({
  selector: 'app-role-register',
  templateUrl: './role-register.component.html',
  styleUrls: ['./role-register.component.scss']
})

export class RoleRegisterComponent implements OnInit {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public roleRegisterTableData = new MatTableDataSource<RoleRegisterModel>([]);
  public roleRegisterColumns: string[] = ['roleGroup', 'roleName', 'reportsTo', 'description'];

  private roleSort: MatSort

  @ViewChild('roleRegisterTable', { read: MatSort, static: false }) set roleRegisterSortValue(value: MatSort) {
    if (value) {
      this.roleSort = value;
      this.roleRegisterTableData.sort = this.roleSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.roleRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private roleService: RoleService) {
  }

  public ngOnInit(): void {
    this.roleRegisterTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.roleService.getRoleRegister().subscribe({
      next: (response: Array<RoleRegisterModel>) => {
        this.roleRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.roleService.exportRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
