import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from '../../../../services/document.service';
import { ToastrService } from 'ngx-toastr';
import { ProcessTaskDocumentTypeConstant } from '../../../../constants/process-task-document-type-constant';
import { ProcessTaskService } from '../../../../services/process-task.service';
import { ProcessTaskDocumentModel } from '../../../../models/process-task-document-model';
import { DocumentModel } from '../../../../models/document-model';

@Component({
  selector: 'app-process-task-document-dialog',
  templateUrl: './process-task-document-dialog.component.html',
  styleUrls: ['./process-task-document-dialog.component.scss']
})
export class ProcessTaskDocumentDialogComponent {
  public documents: Array<DocumentModel> = [];
  public types = ProcessTaskDocumentTypeConstant.ValuesWithDescriptions;
  public model: ProcessTaskDocumentModel;
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public isNew: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private processTaskDocument : ProcessTaskDocumentModel,
    private dialogRef: MatDialogRef<ProcessTaskDocumentDialogComponent>,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private processTaskService: ProcessTaskService) {
    this.model = processTaskDocument;
  }

  public ngOnInit(): void {
    if (!this.model.processTaskDocumentId || this.model.processTaskDocumentId == 0) {
      this.isNew = true;
    }

    this.documentService.getProcessTaskDocumentSelections(this.model.processTaskId, this.model.documentId).subscribe({
      next: (response: Array<DocumentModel>) => {
        this.documents = response
        this.isLoading = false;
      },
      error: () => {
        this.dialogRef.close();
      }
    });
  }

  public save() {
    if (this.model.documentId == null) {
      this.toastr.error("Document must be selected");
      return;
    }

    if (this.model.type == null) {
      this.toastr.error("Action Type must be selected");
      return;
    }

    this.isBusy = true;
    this.processTaskService.saveProcessTaskDocument(this.model).subscribe({
      next: (response: ProcessTaskDocumentModel) => {
        this.isBusy = false;
        this.toastr.success("Task Document Saved");
        this.dialogRef.close(response);
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public cancel() {
    this.dialogRef.close();
  }
}
