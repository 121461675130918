import { Component } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { ChangePasswordModel } from '../../models/change-password-model';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatCard, MatCardHeader, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardHeader, MatCardContent, FormsModule, MatFormField, MatLabel, MatInput, NgIf, MatError, MatButton]
})
export class ChangePasswordComponent {
  public model: ChangePasswordModel = {
    userId: undefined,
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  };

  constructor(private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router) {
  }

  public changePassword = (form: NgForm) => {
    if (form.valid) {
      this.model.userId = this.authService.currentUser?.userId!;
      this.userService.changePassword(this.model).subscribe({
        next: (response: any) => {
          this.toastr.success("Password Reset");
          this.router.navigate(["/"]);
        }
      });
    }
  }
}
