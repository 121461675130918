import { Component } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { AuthService } from '../../services/auth.service';
import { Observable, forkJoin } from 'rxjs';
import { ProcessService } from '../../services/process.service';
import { TreeNodeModel } from '../../models/tree-node-model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTree, MatTreeNodeDef, MatTreeNode, MatNestedTreeNode, MatTreeNodeToggle, MatTreeNodeOutlet } from '@angular/material/tree';
import { Router } from '@angular/router';
import { FileDocumentUtility } from '../../utility/file-document-utility';
import { RoleService } from '../../services/role.service';
import { DocumentService } from '../../services/document.service';
import { CompanyBaseModel } from '../../models/company-base-model';
import { RoleModel } from '../../models/role-model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoleDialogComponent } from '../roles/role-dialog/role-dialog.component';
import { DocumentModel } from '../../models/document-model';
import { DocumentDialogComponent } from '../document/document-dialog/document-dialog.component';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle, MatCardSubtitle } from '@angular/material/card';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatCardHeader, MatCardTitle, MatIconButton, MatTooltip, MatIcon, MatCardSubtitle, MatDivider, MatFormField, MatLabel, MatInput, FormsModule, CdkTextareaAutosize, MatButtonToggleGroup, MatButtonToggle, MatTree, MatTreeNodeDef, MatTreeNode, MatButton, MatNestedTreeNode, MatTreeNodeToggle, MatTreeNodeOutlet]
})
export class HomeComponent {
  public isLoading: boolean = true;
  public isEditing: boolean = false;
  public companyDetails: CompanyBaseModel;
  public blobUrl: string | null = null;

  public treeControl: NestedTreeControl<TreeNodeModel>;
  public treeDataSource = new MatTreeNestedDataSource<TreeNodeModel>();

  public processTreeData: Array<TreeNodeModel>;
  public roleTreeData: Array<TreeNodeModel>;
  public documentTreeData: Array<TreeNodeModel>;

  public activeTreeId: number = 1;
  public processTreeId: number = 1;
  public roleTreeId: number = 2;
  public documentTreeId: number = 3;

  constructor(private companyService: CompanyService,
    public authService: AuthService,
    private processService: ProcessService,
    private roleService: RoleService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private router: Router) {
  }

  public ngOnInit(): void {
    const sources: Array<Observable<any>> = [
      this.companyService.getCompanyHome(this.authService.currentUser?.companyId!),
      this.companyService.getCompanyLogo(this.authService.currentUser?.companyId!),
      this.processService.getProcessesTree(),
    ];

    forkJoin(sources).subscribe({
      next: (response) => {
        this.companyDetails = response[0];
        this.blobUrl = FileDocumentUtility.getBlobUrl(response[1]);
        this.processTreeData = response[2] as TreeNodeModel[];
        this.initialiseTreeControl(this.processTreeData);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  private initialiseTreeControl(nodes: Array<TreeNodeModel>): void {
    this.treeControl = new NestedTreeControl<TreeNodeModel>(node => node.children);
    this.treeDataSource.data = nodes;
  }

  public nodeHasChild(_: number, node: TreeNodeModel): boolean {
    return !!node.children && node.children.length > 0;
  }

  public newProcess(node: TreeNodeModel): void {
    if (!this.canEdit() || !node.parentId) {
      return;
    }

    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${node.parentId}/0`);
  }

  public openEntity(node: TreeNodeModel): void {
    if (this.activeTreeId == this.processTreeId) {
      this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${node.parentId}/${node.id}`);
    } else if (this.activeTreeId == this.roleTreeId) {
      if (node.id !== null && node.id !== undefined) {
        this.roleService.getRoleById(node.id).subscribe({
          next: (role: RoleModel) => {
            const dialogConfig: MatDialogConfig = {
              data: { ...role }
            };

            const dialogRef = this.dialog.open(RoleDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe({
              next: (result: RoleModel | null) => {
                if (result) {
                  node.title = result.title;
                }
              }
            });
          }
        });
      }
    } else if (this.activeTreeId == this.documentTreeId) {
      if (node.id !== null && node.id !== undefined) {
        this.documentService.getDocumentById(node.id).subscribe({
          next: (document: DocumentModel) => {
            const dialogConfig: MatDialogConfig = {
              data: { ...document }
            };

            const dialogRef = this.dialog.open(DocumentDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe({
              next: (result: DocumentModel | null) => {
                if (result) {
                  node.title = result.documentName;
                }
              }
            });
          }
        });
      }
    }
  }

  public setActiveTree(treeId: number): void {
    this.activeTreeId = treeId;

    if (this.activeTreeId == this.processTreeId) {
      this.initialiseTreeControl(this.processTreeData);
    } else if (this.activeTreeId == this.roleTreeId) {
      if (!this.roleTreeData) {
        this.roleService.getRolesTree().subscribe({
          next: (response) => {
            this.roleTreeData = response as TreeNodeModel[];
            this.initialiseTreeControl(this.roleTreeData);
          }
        });
      } else {
        this.initialiseTreeControl(this.roleTreeData);
      }
    } else if (this.activeTreeId == this.documentTreeId) {
      if (!this.documentTreeData) {
        this.documentService.getDocumentsTree().subscribe({
          next: (response) => {
            this.documentTreeData = response as TreeNodeModel[];
            this.initialiseTreeControl(this.documentTreeData);
          }
        });
      } else {
        this.initialiseTreeControl(this.documentTreeData);
      }
    }
  }

  public toggleIsEditing(): void {
    this.isEditing = !this.isEditing;
  }

  public save(): void {
    this.companyService.saveCompanyHome(this.companyDetails).subscribe({
      next: (response: CompanyBaseModel) => {
        this.companyDetails = response;
        this.isEditing = false;
      }
    });
  }

  public undo(): void {
    this.companyService.getCompanyHome(this.companyDetails.companyId).subscribe({
      next: (response: CompanyBaseModel) => {
        this.companyDetails = response;
        this.isEditing = false;
      }
    });
  }
}
