export class WikiPageModel {
  public wikiPageId: number | null;
  public wikiGroupId: number | null;
  public header: string | null;
  public text: string | null;
  public isViewerPage: boolean = false;
  public isEditorPage: boolean = false;
  public isAdminPage: boolean = false;

  public wikiGroupTitle: string | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
