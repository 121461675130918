import { environment } from './environments/environment';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './app/utility/auth-interceptor';
import { RequestInterceptor } from './app/utility/request-interceptor';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing-module';
import { FormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { JwtModule } from '@auth0/angular-jwt';
import { StorageKeyConstant } from './app/constants/storage-key-constant';
import { MsalModule } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { NativeDateAdapter, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

if (environment.instance == "live") {
  enableProdMode();
}

export function tokenGetter() {
  return localStorage.getItem(StorageKeyConstant.TOKEN_KEY);
}

export class CustomDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: any): string {
    const days = date.getDate();
    const months = date.getMonth() + 1;
    const year = date.getFullYear();
    return days + '/' + months + '/' + year;
  }
}

const DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule,
      AppRoutingModule,
      FormsModule,
      NgApexchartsModule,
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ["*"],
          disallowedRoutes: []
        },
      }),
      MsalModule.forRoot(new PublicClientApplication({
        auth: {
          clientId: environment.clientId,
          authority: "https://login.microsoftonline.com/organizations/oauth2/v2.0",
          redirectUri: environment.clientBaseUrl,
        },
        cache: {
          cacheLocation: "localStorage",
        },
      }),
        // We do not currently use the MSAL Guard or Interceptor but they must be defined
        {
          interactionType: InteractionType.Popup,
        }, {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map(),
      }),
      QuillModule.forRoot({
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['image']
          ]
        }
      }),
      ToastrModule.forRoot({
        positionClass: 'toast-bottom-right',
        closeButton: true,
        enableHtml: true,
      })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', hideRequiredMarker: true } },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 1000 } },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
        height: '80%',
        width: '80%',
        disableClose: true,
        autoFocus: true,
        restoreFocus: false,
      } as MatDialogConfig
    },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
})
  .catch(err => console.error(err));
