import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthenticatedResponseModel } from '../../models/authenticated-response-model';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { NgFor } from '@angular/common';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'app-company-select',
    templateUrl: './company-select.component.html',
    styleUrls: ['./company-select.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatFormField, MatLabel, MatSelect, NgFor, MatOption]
})
export class CompanySelectComponent {
  public companyId: number | null = null;

  constructor(public authService: AuthService,
    private router: Router,
    private userService: UserService) {
  }

  get sortedCompanies() {
    if (this.authService.currentUser) {
      return this.authService.currentUser!.companies.slice().sort((a, b) => {
        if (a.displayName < b.displayName) return -1;
        if (a.displayName > b.displayName) return 1;
        return 0;
      });
    }
    return [];
  }

  public onSelectionChanged() {
    if (this.companyId != null) {
      if (this.companyId != this.authService.currentUser?.companyId) {
        let companyName = this.sortedCompanies.filter(i => i.companyId == this.companyId)[0]?.name;

        this.userService.refreshUser(this.authService.currentUser!.userId!, companyName).subscribe({
          next: (response: AuthenticatedResponseModel) => {
            this.authService.setToken(response.token);
            this.authService.setRefreshToken(response.refreshToken);
            this.authService.setCurrentUser(response.user, true);
            this.router.navigate([`/${this.authService.currentUser?.companyName}`]);
          }
        });
      } else {
        this.authService.setCurrentUser(this.authService.currentUser, true);
        this.router.navigate([`/${this.authService.currentUser?.companyName}`]);
      }
    }
  }
}
