import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { ProcessAuditModel } from "../models/process-audit-model";
import { ProcessAuditTaskModel } from "../models/process-audit-task-model";
import { ProcessAuditDashboardModel } from "../models/process-audit-dashboard-model";

@Injectable({
  providedIn: 'root'
})
export class ProcessAuditService {
  constructor(private apiService: ApiService) {
  }

  public getAuditsForProcess(processId: number): Observable<Array<ProcessAuditModel>> {
    return this.apiService.get(`processAudit/get-audits-for-process/${processId}`)
      .pipe(map((response: Array<ProcessAuditModel>) => response.map(i => new ProcessAuditModel(i))));
  }

  public getProcessAuditById(processAuditId: number): Observable<ProcessAuditModel> {
    return this.apiService.get(`processAudit/get-process-audit/${processAuditId}`)
      .pipe(map(response => new ProcessAuditModel(response)));
  }

  public saveProcessAudit(model: ProcessAuditModel): Observable<ProcessAuditModel> {
    return this.apiService.post("processAudit/save", model)
      .pipe(map(i => new ProcessAuditModel(i)));
  }

  public deleteProcessAudit(id: number): Observable<void> {
    return this.apiService.post(`processAudit/delete-process-audit/${id}`, {});
  }

  public submitForClosure(processAuditId: number): Observable<void> {
    return this.apiService.post(`processAudit/submit-for-closure/${processAuditId}`, {});
  }

  public rejectProcessAuditClosure(processAuditId: number, rejectionComment: string): Observable<void> {
    return this.apiService.post(`processAudit/reject-process-audit/${processAuditId}`, { rejectionComment });
  }

  public closeProcessAudit(processAuditId: number, closedComment: string, nextReviewDate: Date): Observable<void> {
    return this.apiService.post(`processAudit/close-process-audit/${processAuditId}`, { closedComment, nextReviewDate });
  }

  public saveProcessAuditTask(model: ProcessAuditTaskModel): Observable<ProcessAuditTaskModel> {
    return this.apiService.post("processAudit/save-process-audit-task", model)
      .pipe(map(i => new ProcessAuditTaskModel(i)));
  }

  public getProcessAuditStatusById(processAuditId: number): Observable<string> {
    return this.apiService.get(`processAudit/get-process-audit-status-by-process-audit-id/${processAuditId}`);
  }

  public getProcessAuditDashboard(): Observable<ProcessAuditDashboardModel> {
    return this.apiService.get(`processAudit/get-dashboard`)
      .pipe(map(response => new ProcessAuditDashboardModel(response)));
  }
}
