<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<div *ngIf="!isLoading">
  <mat-card appearance="outlined">
    <mat-card-header>
      <h1>{{ title }}</h1>
    </mat-card-header>
    <mat-card-content>
      <form #verifyForm="ngForm" (ngSubmit)="submit(verifyForm)">
        <div>
          <p>
            Create a new password for your account
          </p>

          <mat-form-field color="accent">
            <mat-label>Password</mat-label>
            <input type="password" name="password" matInput [(ngModel)]="model.password" #password="ngModel" required>
            <mat-error *ngIf="password.invalid">
              Password is required
            </mat-error>
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label>Confirm Password</mat-label>
            <input type="password" name="passwordConfirm" matInput [(ngModel)]="model.passwordConfirm" #passwordConfirm="ngModel" required>
            <mat-error *ngIf="passwordConfirm.invalid">
              Confirm Password is required
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <button type="submit" mat-flat-button color="accent" class="button accent-button" [disabled]="!verifyForm.valid">Save</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
