import { Component, Inject, OnInit } from '@angular/core';
import { DocumentGroupModel } from '../../../models/document-group-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from '../../../services/document.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { CategoryService } from '../../../services/category.service';
import { CategoryModel } from '../../../models/category-model';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-document-group-dialog',
  templateUrl: './document-group-dialog.component.html',
  styleUrls: ['./document-group-dialog.component.scss']
})
export class DocumentGroupDialogComponent implements OnInit {
  public model: DocumentGroupModel;
  public dialogTitle: string = "New Document Group";
  public isBusy: boolean = false;
  public isLoading: boolean = true;
  public isNew: boolean = true;
  public categories: Array<CategoryModel> = [];

  constructor(@Inject(MAT_DIALOG_DATA) private documentGroup: DocumentGroupModel,
    private dialogRef: MatDialogRef<DocumentGroupDialogComponent>,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private authService: AuthService,
    private categoryService: CategoryService,
    private validationService: ValidationService) {
    this.model = structuredClone(documentGroup);

    if (this.model.documentGroupId && this.model.documentGroupId != 0) {
      this.dialogTitle = this.model.title;
      this.isNew = false
    }
  }

  public ngOnInit(): void {
    this.categoryService.getCategories().subscribe({
      next: (response: Array<CategoryModel>) => {
        this.categories = response;
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.documentService.saveDocumentGroup(this.model).subscribe({
        next: (response: DocumentGroupModel) => {
          this.model = response;
          this.toastr.success("Document Group saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/documents/${this.model.documentGroupId}`);
      this.toastr.info("Share link copied to clipboard.")
  }

  public close() {
    this.dialogRef.close();
  }
}
