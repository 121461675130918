<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-12">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()" mat-flat-button color="accent" class="button accent-button" (click)="createCategory()">Add Category</button>
          </div>

          <table #categoryTable mat-table [dataSource]="categoryTableData" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let category">
                <button mat-flat-button
                        (click)="editCategory(category)"
                        class="table-button">
                  {{category.name}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let category" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editCategory(category)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteCategory(category)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="categoryColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: categoryColumns"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="categoryColumns.length" class="no-data-row">
                No categories found
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
