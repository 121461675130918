<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select User Role</h2>
  </div>

    <mat-dialog-content>
      <app-autocomplete [label]="'Select User'"
                        [placeholder]="'Select a User'"
                        [(value)]="selectionId"
                        [options]="users"
                        [isDisabled]="isLoading">
      </app-autocomplete>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-raised-button class="button" type="button" (click)="close()">Cancel</button>
    </mat-dialog-actions>
</section>
