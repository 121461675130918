import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from "@angular/material/table";
import { StandardRegisterModel } from '../../../models/standard-register-model';
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { StandardService } from "../../../services/standard.service";
import { MatTableUtility } from "../../../utility/mat-table-utility";
import { FileDocumentUtility } from "../../../utility/file-document-utility";
import { NgIf } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MatCard, MatCardContent } from "@angular/material/card";
import { MatButton } from "@angular/material/button";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";

@Component({
    selector: 'app-standard-register',
    templateUrl: './standard-register.component.html',
    styleUrls: ['./standard-register.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatButton, MatFormField, MatInput, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})

export class StandardRegisterComponent implements OnInit {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public standardRegisterTableData = new MatTableDataSource<StandardRegisterModel>([]);
  public standardRegisterColumns: string[] = ['standard', 'standardSection', 'owner'];

  private standardSort: MatSort;

  @ViewChild('standardRegisterTable', { read: MatSort, static: false }) set standardRegisterSortValue(value: MatSort) {
    if (value) {
      this.standardSort = value;
      this.standardRegisterTableData.sort = this.standardSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.standardRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private standardService: StandardService) {
  }

  public ngOnInit(): void {
    this.standardRegisterTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.standardService.getStandardRegister().subscribe({
      next: (response: Array<StandardRegisterModel>) => {
        this.standardRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.standardService.exportRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
