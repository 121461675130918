import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { CompanyModel } from "../models/company-model";
import { CompanyWrapperModel } from "../models/company-wrapper-model";
import { FileDocumentMetadataModel } from "../models/file-document-metadata-model";
import { CompanyBaseModel } from "../models/company-base-model";

@Injectable({
  providedIn: 'root'
})

export class CompanyService {
  constructor(private apiService: ApiService) {
  }

  public getCompanies(): Observable<Array<CompanyModel>> {
    return this.apiService.get("company/get-all")
      .pipe(map((response: Array<CompanyModel>) => response.map(i => new CompanyModel(i))));
  }

  public getCompany(id: number): Observable<CompanyWrapperModel> {
    return this.apiService.get(`company/${id}`)
      .pipe(map(i => new CompanyWrapperModel(i)));
  }

  public saveCompany(model: CompanyModel): Observable<CompanyWrapperModel> {
    return this.apiService.post("company", model)
      .pipe(map(i => new CompanyWrapperModel(i)));
  }

  public toggleSingleSignOn(id: number): Observable<CompanyWrapperModel> {
    return this.apiService.post(`company/toggle-sso/${id}`, {})
      .pipe(map(i => new CompanyWrapperModel(i)));
  }

  public deleteCompany(id: number): Observable<void> {
    return this.apiService.post(`company/delete/${id}`, {});
  }

  public getCompanyHome(id: number): Observable<CompanyBaseModel> {
    return this.apiService.get(`company/home/${id}`)
      .pipe(map(i => new CompanyBaseModel(i)));
  }

  public saveCompanyHome(model: CompanyBaseModel): Observable<CompanyBaseModel> {
    return this.apiService.post("company/home", model)
      .pipe(map(i => new CompanyBaseModel(i)));
  }

  public getCompanyLogo(id: number): Observable<any> {
    return this.apiService.postExportRaw(`company/get-logo/${id}`, {});
  }

  public uploadCompanyLogo(companyId: number, data: FormData): Observable<FileDocumentMetadataModel | null> {
    return this.apiService.post(`company/upload-company-logo/${companyId}`, data).
      pipe(map(i => new FileDocumentMetadataModel(i)));
  }

  public deleteCompanyLogo(companyId: number): Observable<void> {
    return this.apiService.post(`company/delete-company-logo/${companyId}`, {});
  }

  public exportUserReport(): Observable<any> {
    return this.apiService.postExportRaw(`company/user-export`, {});
  }
}
