export class ProcessTaskResourceModel {
  public processTaskResourceId: number;
  public processTaskId: number;
  public resourceId: number;
  public quantity: string | null;

  public resourceGroupDisplayName: string;
  public resourceDisplayName: string;

  public get resourceDescription() {
    return this.quantity
      ? this.resourceGroupDisplayName + ' - ' + this.resourceDisplayName + ' : ' + this.quantity
      : this.resourceGroupDisplayName + ' - ' + this.resourceDisplayName
  }

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
