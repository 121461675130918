import { FileDocumentMetadataModel } from "./file-document-metadata-model";

export class DocumentRegisterModel {
  public documentGroupTitle: string;
  public documentIdentifier: string;
  public title: string | null;
  public version: string | null;
  public retention: string | null;
  public reviewDueDate: Date | null;
  public reviewFrequency: string | null;
  public owner: string | null;
  public approver: string | null;
  public description: string | null;
  public link: string | null;
  public fileDocument: FileDocumentMetadataModel | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
