import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyModel } from '../../../models/company-model';
import { CompanyService } from '../../../services/company.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatTableUtility } from '../../../utility/mat-table-utility';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  public isLoading: boolean;

  public companyTableData = new MatTableDataSource<CompanyModel>([]);
  public columns: string[] = ['title', 'controls'];

  private companies: Array<CompanyModel> = [];
  private companySort: MatSort;

  @ViewChild('companyTable', { read: MatSort, static: false }) set companySortValue(value: MatSort) {
    if (value) {
      this.companySort = value;
      this.companyTableData.sort = this.companySort;
    }
  };

  constructor(public authService: AuthService,
    private router: Router,
    private companyService: CompanyService,
    private toastr: ToastrService) {
  }

  public ngOnInit(): void {
    this.isLoading = true;

    this.companyTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.companyService.getCompanies().subscribe({
      next: (response: Array<CompanyModel>) => {
        this.companies = response;
        this.refreshTableData();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    })
  }

  public addCompany() {
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/admin/company/0`);
  }

  public deleteCompany(company: CompanyModel) {
    if (!confirm(`Are you sure you want to delete "${company.displayName}"`)) {
      return;
    }

    this.companyService.deleteCompany(company.companyId).subscribe({
      next: () => {
        this.companies.splice(this.companies.indexOf(company), 1);
        this.refreshTableData();
        this.toastr.success(`Company ${company.displayName} deleted`);
      }
    });
  }

  private refreshTableData(): void {
    this.companyTableData.data = this.companies;
  }
}
