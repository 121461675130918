<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <mat-card appearance="outlined" class="list-card">
    <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
          <button mat-flat-button color="accent" class="button accent-button" (click)="export()" [disabled]="isBusy">Export</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field appearance="fill" color="accent" subscriptSizing="dynamic">
            <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table #processRegisterTable mat-table [dataSource]="processRegisterTableData" matSort matSortActive="processGroupTitle" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="processGroup">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="processGroupTitle">Process Group</th>
              <td mat-cell *matCellDef="let processData">{{ processData.processGroupTitle }}</td>
            </ng-container>

            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reference">Reference</th>
              <td mat-cell *matCellDef="let processData">{{processData.reference}}</td>
            </ng-container>

            <ng-container matColumnDef="processTitle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="processTitle">Process Title</th>
              <td mat-cell *matCellDef="let processData">
                <a href="/{{authService.currentUser!.companyName}}/processes/{{processData.processGroupId}}/{{processData.processId}}">
                  {{processData.processTitle}} </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="revision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="revision">Revision</th>
              <td mat-cell *matCellDef="let processData">{{processData.revision}}</td>
            </ng-container>

            <ng-container matColumnDef="revisionDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="revisionDate">Revision Date</th>
              <td mat-cell *matCellDef="let processData">{{processData.revisionDate | date: 'dd/MM/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="draft">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="draft">Draft</th>
              <td mat-cell *matCellDef="let processData">
                <mat-checkbox [checked]="processData.draft" disabled>
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="owner">Owner</th>
              <td mat-cell *matCellDef="let processData">{{processData.owner}}</td>
            </ng-container>

            <ng-container matColumnDef="approver">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="approver">Approver</th>
              <td mat-cell *matCellDef="let processData">{{processData.approver}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processRegisterColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let processData; columns: processRegisterColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="processRegisterColumns.length" class="no-data-row">
                No processes found
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
