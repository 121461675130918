<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Revisions</h2>
  </div>

  <mat-dialog-content>
    <button *ngIf="canEdit()"
            mat-flat-button
            color="accent"
            class="button accent-button"
            (click)="createProcessRevision()">
      Add Revision
    </button>
    <table #processRevisionsTable mat-table [dataSource]="processRevisionsTableData">
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>Number</th>
        <td mat-cell *matCellDef="let revision">
          {{revision.number}}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Revised Date</th>
        <td mat-cell *matCellDef="let revision">
          {{revision.revisedDate | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let revision">
          {{revision.reason}}
        </td>
      </ng-container>

      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let revision" class="mat-cell-end">
          <button mat-icon-button
                  color="accent"
                  (click)="editProcessRevision(revision)"
                  matTooltip="Edit">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button
                  color="accent"
                  (click)="deleteProcessRevision(revision)"
                  matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="processRevisionsColumns"></tr>
      <tr mat-row *matRowDef="let revision; columns: processRevisionsColumns;"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Close</button>
  </mat-dialog-actions>
</section>
