import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ProcessTaskModel } from '../../../../models/process-task-model';
import { ProcessTaskService } from '../../../../services/process-task.service';
import { RoleModel } from '../../../../models/role-model';
import { RoleService } from '../../../../services/role.service';
import { ProcessTaskDocumentModel } from '../../../../models/process-task-document-model';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { AuthService } from '../../../../services/auth.service';
import { ProcessTaskDocumentDialogComponent } from '../process-task-document-dialog/process-task-document-dialog.component';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableUtility } from '../../../../utility/mat-table-utility';
import { ProcessTaskProcessModel } from '../../../../models/process-task-process-model';
import { ProcessTaskProcessSelectDialogComponent } from '../process-task-process-select-dialog/process-task-process-select-dialog.component';
import { StandardSectionSelectDialogComponent } from '../../../../shared/standard-section-select-dialog/standard-section-select-dialog.component';
import { ProcessTaskStandardSectionModel } from '../../../../models/process-task-standard-section-model';
import { ProcessTaskCustomModel } from '../../../../models/process-task-custom-model';
import { ProcessTaskCustomDialogComponent } from '../process-task-custom-dialog/process-task-custom-dialog.component';
import { ProcessTaskResourceModel } from '../../../../models/process-task-resource-model';
import { ProcessTaskResourceDialogComponent } from '../process-task-resource-dialog/process-task-resource-dialog.component';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../shared/loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { AutocompleteComponent } from '../../../../shared/autocomplete/autocomplete.component';
import { MultiAutocompleteComponent } from '../../../../shared/multi-autocomplete/multi-autocomplete.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-process-task-dialog',
    templateUrl: './process-task-dialog.component.html',
    styleUrls: ['./process-task-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, MatTabGroup, MatTab, MatFormField, MatLabel, MatInput, FormsModule, MatCheckbox, AutocompleteComponent, MultiAutocompleteComponent, MatButton, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIconButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatDialogActions]
})
export class ProcessTaskDialogComponent implements OnInit {
  public model: ProcessTaskModel;
  public dialogTitle: string = "New Task";
  public roles: Array<RoleModel> = [];
  public processTaskDocumentsTableData = new MatTableDataSource<ProcessTaskDocumentModel>([]);
  public processTaskDocumentsColumns: string[] = ['documentName', 'typeDescription', 'controls']
  public processTaskProcessesTableData = new MatTableDataSource<ProcessTaskProcessModel>([]);
  public processTaskProcessesColumns: string[] = ['displayName', 'controls'];
  public processTaskStandardSectionsTableData = new MatTableDataSource<ProcessTaskStandardSectionModel>([]);
  public processTaskStandardSectionsColumns: string[] = ['standardDisplayName', 'displayName', 'controls'];
  public processTaskCustomsTableData = new MatTableDataSource<ProcessTaskCustomModel>([]);
  public processTaskCustomsColumns: string[] = ['displayName', 'value', 'controls'];
  public processTaskResourcesTableData = new MatTableDataSource<ProcessTaskResourceModel>([]);
  public processTaskResourcesColumns: string[] = ['displayName', 'quantity', 'controls'];
  public isLoading: boolean = true;
  public isBusy: boolean = false;

  private processTaskDocumentSort: MatSort;
  private processTaskProcessSort: MatSort;
  private processTaskStandardSectionSort: MatSort;
  private processTaskCustomSort: MatSort;
  private processTaskResourceSort: MatSort;

  @ViewChild('processTaskDocumentTable', { read: MatSort, static: false }) set processTaskDocumentSortValue(value: MatSort) {
    if (value) {
      this.processTaskDocumentSort = value;
      this.processTaskDocumentsTableData.sort = this.processTaskDocumentSort;
    }
  };

  @ViewChild('processTaskProcessesTable', { read: MatSort, static: false }) set processTaskProcessSortValue(value: MatSort) {
    if (value) {
      this.processTaskProcessSort = value;
      this.processTaskProcessesTableData.sort = this.processTaskProcessSort;
    }
  };

  @ViewChild('processTaskStandardSectionsTable', { read: MatSort, static: false }) set processTaskStandardSectionSortValue(value: MatSort) {
    if (value) {
      this.processTaskStandardSectionSort = value;
      this.processTaskStandardSectionsTableData.sort = this.processTaskStandardSectionSort;
    }
  };

  @ViewChild('processTaskCustomTable', { read: MatSort, static: false }) set processTaskCustomSortValue(value: MatSort) {
    if (value) {
      this.processTaskCustomSort = value;
      this.processTaskCustomsTableData.sort = this.processTaskCustomSort;
    }
  };

  @ViewChild('processTaskResourceTable', { read: MatSort, static: false }) set processTaskResourceSortValue(value: MatSort) {
    if (value) {
      this.processTaskResourceSort = value;
      this.processTaskResourcesTableData.sort = this.processTaskResourceSort;
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public processTask: ProcessTaskModel,
    private dialogRef: MatDialogRef<ProcessTaskDialogComponent>,
    private processTaskService: ProcessTaskService,
    private toastr: ToastrService,
    private roleService: RoleService,
    public authService: AuthService,
    private dialog: MatDialog) {
    this.model = structuredClone(processTask);

    if (!this.isNew) {
      this.dialogTitle = this.model.title;
    }
  }

  public ngOnInit(): void {
    this.processTaskDocumentsTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.processTaskProcessesTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.processTaskStandardSectionsTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.processTaskCustomsTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.processTaskResourcesTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.roleService.getRoles().subscribe({
      next: (response: Array<RoleModel>) => {
        this.roles = response;
        this.refreshProcessTaskDocumentsTableData();
        this.refreshProcessTaskProcessesTableData();
        this.refreshProcessTaskStandardSectionsTableData();
        this.refreshProcessTaskCustomsTableData();
        this.refreshProcessTaskResourcesTableData();
        this.isLoading = false;
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.processTaskId == null ||
      this.model.processTaskId == undefined ||
      this.model.processTaskId == 0;
  }

  public get documentsTabTitle(): string {
    return `Documents (${this.model.processTaskDocuments.length})`;
  }

  public get subProcessesTabTitle(): string {
    return `Sub-Processes (${this.model.processTaskProcesses.length})`;
  }

  public get standardsTabTitle(): string {
    return `Standards (${this.model.processTaskStandardSections.length})`;
  }

  public get customsTabTitle(): string {
    return `Customs (${this.model.processTaskCustoms.length})`;
  }

  public get resourcesTabTitle(): string {
    return `Resources (${this.model.processTaskResources.length})`;
  }

  public save() {
    this.isBusy = true;
    if (this.isValid()) {
      this.processTaskService.saveProcessTask(this.model).subscribe({
        next: (response: ProcessTaskModel) => {
          this.model = response;
          this.isBusy = false;
          this.toastr.success("Task saved");
          this.dialogRef.close(this.model);
        }, error: () => {
          this.isBusy = false;
        }
      });
    } else {
      this.isBusy = false;
    }
  }

  private isValid(): boolean {
    let roleAssignmentCount: Array<{ roleId: number, count: number }> = [];

    if (this.model.responsibleRoleId) {
      this.checkRoleAssignment(this.model.responsibleRoleId, roleAssignmentCount);
    }

    this.model.accountableRoles.forEach(i => {
      this.checkRoleAssignment(i.roleId, roleAssignmentCount);
    });

    this.model.consultedRoles.forEach(i => {
      this.checkRoleAssignment(i.roleId, roleAssignmentCount);
    });

    this.model.informedRoles.forEach(i => {
      this.checkRoleAssignment(i.roleId, roleAssignmentCount);
    });

    roleAssignmentCount = roleAssignmentCount.filter(i => i.count > 1);

    if (roleAssignmentCount.length > 0) {
      let message: string = "";

      roleAssignmentCount.forEach(role => {
        message += `${this.roles.find(i => i.roleId == role.roleId)?.title} can only be assigned once</br>`;
      })

      this.toastr.error(message, "Unable to save");
      return false;
    }

    return true;
  }

  private checkRoleAssignment(roleId: number, roleAssignmentCount: Array<{ roleId: number, count: number }>) {
    let roleEntry = roleAssignmentCount.find(i => i.roleId == roleId);

    if (roleEntry == null) {
      roleAssignmentCount.push({ roleId: roleId, count: 0 });

      roleEntry = roleAssignmentCount.find(i => i.roleId == roleId);
    }

    roleEntry!.count++;
  }

  public isHeaderChanged(): void {
    setTimeout(() => { // Workaround so UI reflects model changes. See: https://github.com/angular/angular/issues/3406
      if (this.model.isHeader && (this.model.responsibleRoleId != null ||
          this.model.accountableRoles.length > 0 ||
          this.model.consultedRoles.length > 0 ||
          this.model.informedRoles.length > 0)) {
        if (confirm("Changing the task to a header task will remove the assigned roles. Continue?")) {
          this.model.responsibleRoleId = null;
          this.model.accountableRoles = [];
          this.model.consultedRoles = [];
          this.model.informedRoles = [];
        } else {
          this.model.isHeader = false;
          return;
        }
      }

      if (this.model.isHeader) {
        this.model.isMilestone = false;
        this.model.milestoneReason = null;
      }
    });
  }

  public isMilestoneChanged(): void {
    this.model.milestoneReason = null;
  }

  public addProcessTaskDocument(): void {
    let processTaskDocument = new ProcessTaskDocumentModel();
    processTaskDocument.processTaskId = this.model.processTaskId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...processTaskDocument,
      },
      width: "60%",
      height: "60%"
    };

    const dialogRef = this.dialog.open(ProcessTaskDocumentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessTaskDocumentModel) => {
        if (result) {
          this.processTask.processTaskDocuments.push(result);
          this.model.processTaskDocuments.push(result);
          this.refreshProcessTaskDocumentsTableData();
        }
      }
    });
  }

  public editProcessTaskDocument(processTaskDocument: ProcessTaskDocumentModel): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...processTaskDocument,
      },
      width: "60%",
      height: "60%"
    };

    const dialogRef = this.dialog.open(ProcessTaskDocumentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessTaskDocumentModel) => {
        if (result) {
          const index = this.model.processTaskDocuments.findIndex(i => i.processTaskDocumentId == result.processTaskDocumentId);
          if (index != -1) {
            this.processTask.processTaskDocuments[index] = result;
            this.model.processTaskDocuments[index] = result;
            this.refreshProcessTaskDocumentsTableData();
          }
        }
      }
    });
  }

  public deleteProcessTaskDocument(processTaskDocument: ProcessTaskDocumentModel): void {
    if (!confirm(`Are you sure you want to delete Document ${processTaskDocument.documentName} from this task?`)) {
      return;
    }

    this.processTaskService.deleteProcessTaskDocument(processTaskDocument.processTaskDocumentId).subscribe({
      next: () => {
        this.processTask.processTaskDocuments.splice(this.processTask.processTaskDocuments.indexOf(processTaskDocument), 1);
        this.model.processTaskDocuments.splice(this.model.processTaskDocuments.indexOf(processTaskDocument), 1);
        this.refreshProcessTaskDocumentsTableData();
        this.toastr.success(`Document deleted`);
      }
    });
  }

  public addProcessTaskProcess() {
    const selectedProcessIds = this.model.processTaskProcesses.map(i => i.processId);
    selectedProcessIds.push(this.model.processId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedProcessIds: selectedProcessIds,
      },
      width: "60%",
    };

    const dialogRef = this.dialog.open(ProcessTaskProcessSelectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessTaskProcessModel | null) => {
        if (result) {
          result.processTaskId = this.model.processTaskId;
          this.processTaskService.saveProcessTaskProcess(result).subscribe({
            next: (response: ProcessTaskProcessModel) => {
              this.processTask.processTaskProcesses.push(response);
              this.model.processTaskProcesses.push(response);
              this.refreshProcessTaskProcessesTableData();
              this.toastr.success("Sub-Process added");
            }
          });
        }
      }
    });
  }

  public deleteProcessTaskProcess(processTaskProcess: ProcessTaskProcessModel) {
    this.processTaskService.deleteProcessTaskProcess(processTaskProcess.processTaskProcessId).subscribe({
      next: () => {
        this.processTask.processTaskProcesses.splice(this.processTask.processTaskProcesses.indexOf(processTaskProcess), 1);
        this.model.processTaskProcesses.splice(this.model.processTaskProcesses.indexOf(processTaskProcess), 1);
        this.refreshProcessTaskProcessesTableData();
        this.toastr.success(`Sub-Process deleted`);
      }
    });
  }

  public addProcessTaskStandardSection() {
    const selectedStandardSectionIds = this.model.processTaskStandardSections.map(i => i.standardSectionId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedStandardSectionIds: selectedStandardSectionIds,
      },
      width: "60%",
    }

    const dialogRef = this.dialog.open(StandardSectionSelectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: number | null) => {
        if (result) {
          this.processTaskService.saveProcessTaskStandardSection(this.model.processTaskId, result).subscribe({
            next: (response: ProcessTaskStandardSectionModel) => {
              this.processTask.processTaskStandardSections.push(response);
              this.model.processTaskStandardSections.push(response);
              this.refreshProcessTaskStandardSectionsTableData();
              this.toastr.success("Standard added");
            }
          });
        }
      }
    });
  }

  public deleteProcessTaskStandardSection(processTaskStandardSection: ProcessTaskStandardSectionModel) {
    this.processTaskService.deleteProcessTaskStandardSection(processTaskStandardSection.processTaskStandardSectionId).subscribe({
      next: () => {
        this.processTask.processTaskStandardSections.splice(this.processTask.processTaskStandardSections.indexOf(processTaskStandardSection), 1);
        this.model.processTaskStandardSections.splice(this.model.processTaskStandardSections.indexOf(processTaskStandardSection), 1);
        this.refreshProcessTaskStandardSectionsTableData();
        this.toastr.success(`Standard deleted`);
      }
    });
  }

  public addProcessTaskCustom() {
    const selectedCustomIds = this.model.processTaskCustoms.map(i => i.customId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedCustomIds: selectedCustomIds,
      },
      width: "60%",
    };

    const dialogRef = this.dialog.open(ProcessTaskCustomDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessTaskCustomModel | null) => {
        if (result) {
          result.processTaskId = this.model.processTaskId;
          this.processTaskService.saveProcessTaskCustom(result).subscribe({
            next: (response: ProcessTaskCustomModel) => {
              this.processTask.processTaskCustoms.push(response);
              this.model.processTaskCustoms.push(response);
              this.refreshProcessTaskCustomsTableData();
              this.toastr.success("Custom added");
            }
          });
        }
      }
    });
  }

  public deleteProcessTaskCustom(processTaskCustom: ProcessTaskCustomModel) {
    this.processTaskService.deleteProcessTaskCustom(processTaskCustom.processTaskCustomId).subscribe({
      next: () => {
        this.processTask.processTaskCustoms.splice(this.processTask.processTaskCustoms.indexOf(processTaskCustom), 1);
        this.model.processTaskCustoms.splice(this.model.processTaskCustoms.indexOf(processTaskCustom), 1);
        this.refreshProcessTaskCustomsTableData();
        this.toastr.success(`Custom deleted`);
      }
    });
  }

  public addProcessTaskResource() {
    const selectedResourceIds = this.model.processTaskResources.map(i => i.resourceId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedResourceIds: selectedResourceIds,
      },
      width: "60%",
    };

    const dialogRef = this.dialog.open(ProcessTaskResourceDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessTaskResourceModel | null) => {
        if (result) {
          result.processTaskId = this.model.processTaskId;
          this.processTaskService.saveProcessTaskResource(result).subscribe({
            next: (response: ProcessTaskResourceModel) => {
              this.processTask.processTaskResources.push(response);
              this.model.processTaskResources.push(response);
              this.refreshProcessTaskResourcesTableData();
              this.toastr.success("Resource added");
            }
          });
        }
      }
    });
  }

  public deleteProcessTaskResource(processTaskResource: ProcessTaskResourceModel) {
    this.processTaskService.deleteProcessTaskResource(processTaskResource.processTaskResourceId).subscribe({
      next: () => {
        this.processTask.processTaskResources.splice(this.processTask.processTaskResources.indexOf(processTaskResource), 1);
        this.model.processTaskResources.splice(this.model.processTaskResources.indexOf(processTaskResource), 1);
        this.refreshProcessTaskResourcesTableData();
        this.toastr.success(`Resource deleted`);
      }
    });
  }

  private refreshProcessTaskDocumentsTableData(): void {
    this.processTaskDocumentsTableData.data = this.model.processTaskDocuments;
  }

  private refreshProcessTaskProcessesTableData(): void {
    this.processTaskProcessesTableData.data = this.model.processTaskProcesses;
  }

  private refreshProcessTaskStandardSectionsTableData(): void {
    this.processTaskStandardSectionsTableData.data = this.model.processTaskStandardSections;
  }

  private refreshProcessTaskCustomsTableData(): void {
    this.processTaskCustomsTableData.data = this.model.processTaskCustoms;
  }

  private refreshProcessTaskResourcesTableData(): void {
    this.processTaskResourcesTableData.data = this.model.processTaskResources;
  }

  public close() {
    this.dialogRef.close();
  }
}
