export class ProcessTaskCustomModel {
  public processTaskCustomId: number;
  public processTaskId: number;
  public customId: number;
  public value: string | null;

  public customGroupDisplayName: string;
  public customDisplayName: string;

  public get customDescription() {
    return this.value
      ? this.customGroupDisplayName + ' - ' + this.customDisplayName + ' : ' + this.value
      : this.customGroupDisplayName + ' - ' + this.customDisplayName
  }

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
