<mat-card appearance="outlined">
  <mat-card-header>
    <h1>Change Password</h1>
  </mat-card-header>
  <mat-card-content>
    <form #changePasswordForm="ngForm" (ngSubmit)="changePassword(changePasswordForm)">
      <div>
        <mat-form-field color="accent">
          <mat-label>Current Password</mat-label>
          <input type="password" name="currentPassword" matInput [(ngModel)]="model.currentPassword" #currentPassword="ngModel" required>
          <mat-error *ngIf="currentPassword.invalid">
            Current Password is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field color="accent">
          <mat-label>New Password</mat-label>
          <input type="password" name="newPassword" matInput [(ngModel)]="model.newPassword" #newPassword="ngModel" required>
          <mat-error *ngIf="newPassword.invalid">
            New Password is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field color="accent">
          <mat-label>Confirm Password</mat-label>
          <input type="password" name="newPasswordConfirm" matInput [(ngModel)]="model.newPasswordConfirm" #newPasswordConfirm="ngModel" required>
          <mat-error *ngIf="newPasswordConfirm.invalid">
            Confirm Password is required
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <button type="submit" mat-flat-button color="accent" class="button accent-button" [disabled]="!changePasswordForm.valid">Change Password</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
