import { AutocompleteOptionModel } from "./autocomplete-option-model";
export class ProcessAssociationModel extends AutocompleteOptionModel {
  public processId: number;
  public processGroupId: number;
  public processTitle: string;
  public processReference: string;
  public association: string;

  public get associationReferenceAndTitle() {
    return this.processReference ? this.processReference + ' - ' + this.processTitle : this.processTitle
  }

  constructor(data?: any) {
    super();

    if (data) {
      Object.assign(this, data);
    }
  }
}
