import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StandardSectionModel } from '../../models/standard-section-model';
import { StandardService } from '../../services/standard.service';

@Component({
  selector: 'app-standard-section-select-dialog',
  templateUrl: './standard-section-select-dialog.component.html',
  styleUrls: ['./standard-section-select-dialog.component.scss']
})
export class StandardSectionSelectDialogComponent {
  public options: Array<StandardSectionModel>;
  public selectedStandardSectionIds: Array<number> = [];
  public isLoading: boolean = true;

  private _selectionId: number | null;

  public get selectionId(): number | null {
    return this._selectionId;
  }

  public set selectionId(value: number | null) {
    if (this._selectionId != value) {
      this._selectionId = value;
      this.dialogRef.close(this._selectionId);
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<StandardSectionSelectDialogComponent>,
    private standardService: StandardService) {
    this.selectedStandardSectionIds = data.selectedStandardSectionIds;
  }

  public ngOnInit() {
    this.standardService.getStandardSections().subscribe({
      next: (response: Array<StandardSectionModel>) => {
        this.options = response.filter(i => !this.selectedStandardSectionIds.includes(i.standardSectionId));
        this.isLoading = false;
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
