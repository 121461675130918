import { RoleModel } from "../models/role-model";

export class RoleTitleUtility {
  public static getRoleTitle(roles: Array<RoleModel>, roleId: number | null): string | null {
    if (roleId) {
      const role = roles.find(i => i.roleId == roleId);
      if (role) {
        return role.title;
      }
    }

    return null;
  }
}
