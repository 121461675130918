import { Component } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import { ConfigurationModel } from '../../../models/configuration-model';
import { ToastrService } from 'ngx-toastr';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatFormField, MatLabel, MatInput, FormsModule, MatButton]
})
export class ConfigurationComponent {
  public isLoading: boolean = true;
  public model: ConfigurationModel;

  constructor(public configurationService: ConfigurationService,
    private toastr: ToastrService) {
  }

  public ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe({
      next: (response: ConfigurationModel) => {
        this.model = response;
        this.isLoading = false;
      }
    });
  }

  public save() {
    this.configurationService.saveConfiguration(this.model).subscribe({
      next: (response: ConfigurationModel) => {
        this.model = response;
          this.toastr.success("Saved");
      }
    });
  }
}
