import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { ConfigurationModel } from "../models/configuration-model";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(private apiService: ApiService) {
  }

  public getConfiguration(): Observable<ConfigurationModel> {
    return this.apiService.get("configuration/get")
      .pipe(map(i => new ConfigurationModel(i)));
  }

  public saveConfiguration(model: ConfigurationModel): Observable<ConfigurationModel> {
    return this.apiService.post("configuration/save", model)
      .pipe(map(i => new ConfigurationModel(i)));
  }
}
