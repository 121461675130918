import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { ProcessRevisionRegisterModel } from '../../../models/process-revision-register-model';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { ProcessService } from '../../../services/process.service';
import { MatTableUtility } from '../../../utility/mat-table-utility';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { DatePipe, NgIf } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-process-revision-register',
    templateUrl: './process-revision-register.component.html',
    styleUrls: ['./process-revision-register.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatButton, MatFormField, MatInput, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatCheckbox, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, DatePipe]
})

export class ProcessRevisionRegisterComponent implements OnInit {
  public isLoading: boolean = false;
  public isBusy: boolean = false;
  public processRevisionRegisterTableData = new MatTableDataSource<ProcessRevisionRegisterModel>([]);
  public processRevisionRegisterColumns: string[] = ['reference', 'processTitle', 'revision', 'revisionDate', 'draft', 'reason'];

  private pipe: DatePipe;
  private processRevisionSort: MatSort;

  @ViewChild('processRevisionRegisterTable', { read: MatSort, static: false }) set processRevisionRegisterSortValue(value: MatSort) {
    if (value) {
      this.processRevisionSort = value;
      this.processRevisionRegisterTableData.sort = this.processRevisionSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.processRevisionRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(public authService: AuthService,
    private processService: ProcessService) {
    this.pipe = new DatePipe('en');
    const defaultPredicate = this.processRevisionRegisterTableData.filterPredicate;
    this.processRevisionRegisterTableData.filterPredicate = (data, filter) => {
      const formattedDate = this.pipe.transform(data.revisionDate, 'dd/MM/yyyy');
      return (formattedDate != null && formattedDate.indexOf(filter) >= 0) || defaultPredicate(data, filter);
    }
  }

  public ngOnInit(): void {
    this.processRevisionRegisterTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.processService.getRevisionRegister().subscribe({
      next: (response: Array<ProcessRevisionRegisterModel>) => {
        this.processRevisionRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.processService.exportRevisionRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
