import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { ConfigurationService } from './services/configuration.service';
import { FileDocumentUtility } from './utility/file-document-utility';
import { CompanyService } from './services/company.service';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf } from '@angular/common';
import { MatAnchor } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MatToolbar, RouterLink, NgIf, MatAnchor, RouterLinkActive, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, MatDivider, RouterOutlet]
})
export class AppComponent {
  public title = 'Author';
  public currentRoute: string;
  public blobUrl: string | null = null;
  private currentLogoCompanyId: number | null;

  constructor(
    public configurationService: ConfigurationService,
    public authService: AuthService,
    private companyService: CompanyService,
    private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd &&
        this.authService.isUserAuthenticated() &&
        this.authService.currentUser &&
        this.currentLogoCompanyId != this.authService.currentUser.companyId) {
            this.companyService.getCompanyLogo(this.authService.currentUser.companyId).subscribe({
              next: (response) => {
                this.currentLogoCompanyId = this.authService.currentUser?.companyId ?? null;
                this.blobUrl = FileDocumentUtility.getBlobUrl(response);
              }
            });
      }
    });
  }

  public get isUserUsingSingleSignOn(): boolean {
    return this.authService.currentUser!.companies.some(i => i.isSingleSignOnEnabled);
  }

  public get isTest(): boolean {
    return environment.instance === "test";
  }

  public signOut(): void {
    this.authService.clearAuthentication();
    this.router.navigate(["/sign-in"]);
  }
}
