import { RoleModel } from "./role-model";

export class RoleGroupModel {
  public roleGroupId: number;
  public companyId: number;
  public title: string;
  public description: string;
  public createdUtc: Date;
  public modifiedUtc: Date;

  public roles: Array<RoleModel> = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
