import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, tap } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (error: any) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 400) {
                if (typeof error.error === 'string') {
                  this.toastr.error(error.error);
                } else if (error.error.title) {
                  this.toastr.error(error.error.title);
                }
              } else if (error.status === 401) {
                this.toastr.error("You have been logged out, please login before continuing");
              } else if (error.status === 403) {
                this.toastr.error("Access restricted");
              } else if (error.status === 0) {
                this.toastr.error("Unable to connect to the system");
              } else {
                this.toastr.error("Unexpected system error");
              }
            }
          }
      })
    );
  }
}
