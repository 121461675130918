import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ResourceModel } from "../../../models/resource-model";
import { RoleModel } from "../../../models/role-model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ResourceService } from "../../../services/resource.service";
import { RoleService } from "../../../services/role.service";
import { AuthService } from "../../../services/auth.service";
import { ToastrService } from 'ngx-toastr';
import { RoleTitleUtility } from "../../../utility/role-title-utility";
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from "@angular/material/table";
import { ProcessModel } from '../../../models/process-model';
import { MatSort } from "@angular/material/sort";
import { ProcessService } from "../../../services/process.service";
import { Router } from "@angular/router";
import { Observable, forkJoin } from "rxjs";

@Component({
  selector: 'app-resource-dialog',
  templateUrl: './resource-dialog.component.html',
  styleUrls: ['./resource-dialog.component.scss']
})

export class ResourceDialogComponent implements OnInit {
  public model: ResourceModel;
  public dialogTitle: string = "New Resource";
  public isBusy: boolean = false;
  public isLoading: boolean = true;
  public roles: Array<RoleModel> = [];
  public processTableData = new MatTableDataSource<ProcessModel>([]);
  public processesColumns: string[] = ['title'];

  private processSort: MatSort;

  @ViewChild('resourceProcessTable', { read: MatSort, static: false }) set processSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processTableData.sort = this.processSort;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) private resource: ResourceModel,
    private dialogRef: MatDialogRef<ResourceDialogComponent>,
    private resourceService: ResourceService,
    private roleService: RoleService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService,
    private processService: ProcessService,
    private router: Router) {
    this.model = resource;

    if (!this.isNew) {
      this.dialogTitle = this.model.title;
    }
  }

  public ngOnInit(): void {
    let sources: Array<Observable<any>> = [
      this.roleService.getRoles()
    ];

    if (!this.isNew) {
      sources.push(this.processService.getResourceProcesses(this.model.resourceId));
    }

    forkJoin(sources).subscribe({
      next: (response) => {
        this.roles = response[0] as RoleModel[];
        if (!this.isNew) {
          this.processTableData.data = response[1] as ProcessModel[];
        }
        this.isLoading = false;
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.resourceId == null ||
      this.model.resourceId == undefined ||
      this.model.resourceId == 0;
  }

  public get processesTabTitle(): string {
    return `Associated Processes (${this.processTableData.data.length})`;
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.resourceService.saveResource(this.model).subscribe({
        next: (response: ResourceModel) => {
          this.model = response;
          this.toastr.success("Resource saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public openProcess(process: ProcessModel) {
    this.close();
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${process.processGroupId}/${process.processId}`);
  }

  public getRoleTitle(roleId: number | null): string | null {
    return RoleTitleUtility.getRoleTitle(this.roles, roleId);
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/resources/${this.model.resourceGroupId}/${this.model.resourceId}`);
      this.toastr.info("Share link copied to clipboard.")
  }

  public close() {
    this.dialogRef.close();
  }
}
