import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthApiService } from '../services/auth-api.service';
import { AuthenticatedResponseModel } from '../models/authenticated-response-model';
import { lastValueFrom } from 'rxjs';
import { TokenModel } from '../models/token-model';

export const authenticatedGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const authApi = inject(AuthApiService);

  if (authService.isUserAuthenticated()) {
    return true;
  }

  if (authService.refreshToken && authService.token) {
    var model = new TokenModel();
    model.accessToken = authService.token;
    model.refreshToken = authService.refreshToken;
    try {
      const response: AuthenticatedResponseModel = await lastValueFrom(authApi.refresh(model));
      if (response && response.token && response.refreshToken) {
        authService.setToken(response.token);
        authService.setRefreshToken(response.refreshToken);
        return true;
      } else {
        authService.handleExpiredSession();
        return false;
      }
    }
    catch {
      authService.handleExpiredSession();
      return false;
    }
  } else {
    authService.handleExpiredSession();
    return false;
  }
}
