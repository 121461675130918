import { Observable, map } from "rxjs";
import { Injectable } from "@angular/core";
import { ProcessTaskModel } from "../models/process-task-model";
import { ApiService } from "./api.service";
import { ProcessTaskDocumentModel } from "../models/process-task-document-model";
import { ProcessTaskProcessModel } from "../models/process-task-process-model";
import { ProcessTaskStandardSectionModel } from "../models/process-task-standard-section-model";
import { ProcessTaskCustomModel } from "../models/process-task-custom-model";
import { ProcessTaskResourceModel } from "../models/process-task-resource-model";

@Injectable({
  providedIn: 'root'
})
export class ProcessTaskService {
  constructor(private apiService: ApiService) {
  }

  public saveProcessTask(model: ProcessTaskModel): Observable<ProcessTaskModel> {
    return this.apiService.post("processTask", model)
      .pipe(map(i => new ProcessTaskModel(i)));
  }

  public deleteProcessTask(id: number): Observable<void> {
    return this.apiService.post(`processTask/delete/${id}`, {});
  }

  public updateProcessTasksIndexPositions(models: Array<ProcessTaskModel>): Observable<Array<ProcessTaskModel>> {
    return this.apiService.post('processTask/update-positions', models)
      .pipe(map((response: Array<ProcessTaskModel>) => response.map(i => new ProcessTaskModel(i))));
  }

  public branchToProcessTask(model: ProcessTaskModel): Observable<ProcessTaskModel> {
    return this.apiService.post("processTask/branch-to-task", model)
      .pipe(map(i => new ProcessTaskModel(i)));
  }

  public saveProcessTaskDocument(model: ProcessTaskDocumentModel): Observable<ProcessTaskDocumentModel> {
    return this.apiService.post(`processTask/save-task-document`, model)
      .pipe(map(i => new ProcessTaskDocumentModel(i)));
  }

  public deleteProcessTaskDocument(processTaskDocumentId: number): Observable<void> {
    return this.apiService.post(`processTask/delete-task-document/${processTaskDocumentId}`, {});
  }

  public saveProcessTaskProcess(model: ProcessTaskProcessModel): Observable<ProcessTaskProcessModel> {
    return this.apiService.post(`processTask/save-task-process`, model)
      .pipe(map(i => new ProcessTaskProcessModel(i)));
  }

  public deleteProcessTaskProcess(processTaskProcessId: number): Observable<void> {
    return this.apiService.post(`processTask/delete-task-process/${processTaskProcessId}`, {});
  }

  public saveProcessTaskStandardSection(processTaskId: number, standardSectionId: number): Observable<ProcessTaskStandardSectionModel> {
    return this.apiService.post(`processTask/save-task-standard-section/${processTaskId}/${standardSectionId}`, {})
      .pipe(map(i => new ProcessTaskStandardSectionModel(i)));
  }

  public deleteProcessTaskStandardSection(processTaskStandardSectionId: number): Observable<void> {
    return this.apiService.post(`processTask/delete-task-standard-section/${processTaskStandardSectionId}`, {});
  }

  public saveProcessTaskCustom(model: ProcessTaskCustomModel): Observable<ProcessTaskCustomModel> {
    return this.apiService.post(`processTask/save-task-custom`, model)
      .pipe(map(i => new ProcessTaskCustomModel(i)));
  }

  public deleteProcessTaskCustom(processTaskCustomId: number): Observable<void> {
    return this.apiService.post(`processTask/delete-task-custom/${processTaskCustomId}`, {});
  }

  public saveProcessTaskResource(model: ProcessTaskResourceModel): Observable<ProcessTaskResourceModel> {
    return this.apiService.post(`processTask/save-task-resource`, model)
      .pipe(map(i => new ProcessTaskResourceModel(i)));
  }

  public deleteProcessTaskResource(processTaskResourceId: number): Observable<void> {
    return this.apiService.post(`processTask/delete-task-resource/${processTaskResourceId}`, {});
  }
}
