export class CompanyBaseModel {
  public companyId: number;
  public name: string;
  public displayName: string;
  public focalPoint: string | null;
  public message: string | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
 }
