import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AutocompleteOptionModel } from '../../models/autocomplete-option-model';

@Component({
  selector: 'app-multi-autocomplete',
  templateUrl: './multi-autocomplete.component.html',
  styleUrls: ['./multi-autocomplete.component.scss']
})
export class MultiAutocompleteComponent {
  public filteredOptions: AutocompleteOptionModel[] = [];
  public searchText: string = '';
  public visible = true;
  public selectable = false;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() isDisabled: boolean = false;
  @Input() options: AutocompleteOptionModel[] = [];
  @Input() placeholder: string = "";
  @Input() label: string = "";
  @Input() selectedOptions: AutocompleteOptionModel[] = [];
  @Output() selectionChanged = new EventEmitter<AutocompleteOptionModel[]>;

  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.options = this.options.sort((a, b) =>
      (a.displayValue.toLowerCase() > b.displayValue.toLowerCase()) ? 1 : (b.displayValue.toLowerCase() > a.displayValue.toLowerCase()) ? -1 : 0);

    this.filteredOptions = this.filter('');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDisabled']) {
      this.filteredOptions = this.filter('');
    }
  }

  public remove(option: AutocompleteOptionModel): void {
    const index = this.selectedOptions.findIndex(i => i.id == option.id);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.selectionChanged.emit(this.selectedOptions);
    }

    if (this.selectedOptions.length == 0) {
      this.optionInput.nativeElement.focus();
      this.optionInput.nativeElement.value = '';
    }
  }

  public optionSelected(event: MatAutocompleteSelectedEvent, auto: MatAutocomplete): void {
    let index = this.selectedOptions.findIndex(i => i.displayValue == event.option.viewValue);
    if (index >= 0) {
      this.remove(this.selectedOptions[index]);
      return;
    } else {
      let option = this.options.find(i => i.displayValue == event.option.viewValue);
      this.selectedOptions.push(option!);
    }

    this.selectionChanged.emit(this.selectedOptions);
    this.optionInput.nativeElement.value = '';
    auto.options.filter(i => i.selected).forEach(i => { i.deselect() });
    this.filter('');
  }

  public applyFilter(value: string) {
    this.filteredOptions = this.filter(value);
  }

  private filter(value: string): AutocompleteOptionModel[] {
    if (this.options.some(i => i.id == null)) {
      this.options.splice(this.options.findIndex(i => i.id == null), 1);
    }

    if (!value || value == '') {
      return this.options;
    }

    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.displayValue.toLowerCase().includes(filterValue));
  }
}
