import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { FileDocumentMetadataModel } from "../models/file-document-metadata-model";
import { Observable, map } from "rxjs";
import { FileDocumentAttachableModel } from "../models/file-document-attachable-model";

@Injectable({
  providedIn: 'root'
})
export class FileDocumentService {
  constructor(private apiService: ApiService) {
  }

  public downloadFileDocument(document: FileDocumentMetadataModel): Observable<any> {
    return this.apiService.postExportRaw(`fileDocument/download-document`, document);
  }

  public getFileDocuments(actionId: number): Observable<Array<FileDocumentMetadataModel>> {
    return this.apiService.get(`fileDocument/get-documents/${actionId}`)
      .pipe(map((response: Array<FileDocumentMetadataModel>) => response.map(i => new FileDocumentMetadataModel(i))));
  }

  public uploadFileDocuments(model: FileDocumentAttachableModel, data: FormData): Observable<void> {
    data.append("model", JSON.stringify(model));

    return this.apiService.post(`fileDocument/upload-documents`, data);
  }

  public deleteFileDocument(model: FileDocumentAttachableModel, fileDocumentId: number): Observable<void> {
    var formData = new FormData();

    formData.append("model", JSON.stringify(model));
    return this.apiService.post(`fileDocument/delete-document/${fileDocumentId}`, formData);
  }
}
