export class ProcessTaskDocumentTypeConstant {
  public static readonly Archive: string = "A";
  public static readonly Create: string = "C";
  public static readonly RefersTo: string = "R";
  public static readonly Update: string = "U";

  public static readonly ValuesWithDescriptions = [
    { value: this.Archive, description: "Archive" },
    { value: this.Create, description: "Create" },
    { value: this.RefersTo, description: "Refers To" },
    { value: this.Update, description: "Update" },
  ];
}
