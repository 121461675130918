export class ProcessRevisionModel {
  public processRevisionId: number | null;
  public processId: number | null;
  public revisedDate: Date | null;
  public number: string | null;
  public reason: string | null;
  public createdUtc: Date;
  public modifiedUtc: Date;

  constructor(data?: any) {
    if (data) {

      if (data.revisedDate) {
        data.revisedDate = new Date(data.revisedDate);
      }

      Object.assign(this, data);
    }
  }
}
