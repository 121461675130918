import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProcessService } from '../../../../services/process.service';
import { ProcessModel } from '../../../../models/process-model';
import { ProcessTaskModel } from '../../../../models/process-task-model';
import { ProcessTaskProcessModel } from '../../../../models/process-task-process-model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-process-task-process-select-dialog',
  templateUrl: './process-task-process-select-dialog.component.html',
  styleUrls: ['./process-task-process-select-dialog.component.scss']
})
export class ProcessTaskProcessSelectDialogComponent {
  public processOptions: Array<ProcessModel> = [];
  public processTaskOptions: Array<ProcessTaskModel> = [];
  public selectedProcessIds: Array<number> = [];
  public isLoading: boolean = true;
  public processTaskSelectionId: number | null;

  private _processSelectionId: number | null;

  public get processSelectionId(): number | null {
    return this._processSelectionId;
  }

  public set processSelectionId(value: number | null) {
    if (this._processSelectionId != value) {
      this._processSelectionId = value;
      this.setProcessTaskOptions();
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProcessTaskProcessSelectDialogComponent>,
    private processService: ProcessService,
    private toastr: ToastrService) {
    this.selectedProcessIds = data.selectedProcessIds;
  }

  public ngOnInit() {
    this.processService.getProcessesWithTasks().subscribe({
      next: (response: Array<ProcessModel>) => {
        this.processOptions = response.filter(i => !this.selectedProcessIds.includes(i.processId));
        this.isLoading = false;
      }
    });
  }

  public save() {
    if (!this.processSelectionId) {
      this.toastr.error("Process must be selected");
      return;
    }

    let model = new ProcessTaskProcessModel();
    model.processId = this.processSelectionId!;
    model.subProcessTaskId = this.processTaskSelectionId;

    this.dialogRef.close(model);
  }

  public close() {
    this.dialogRef.close(null);
  }

  private setProcessTaskOptions() {
    this.processTaskSelectionId = null;
    let selectedProcess = this.processOptions.filter(i => i.processId == this.processSelectionId);

    if (selectedProcess.length != 1) {
      this.processTaskOptions = [];
    } else {
      this.processTaskOptions = selectedProcess[0].processTasks;
    }
  }
}
