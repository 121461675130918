<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ isNew ? 'Add Task Document' : 'Edit Task Document' }}</h2>
  </div>

  <mat-dialog-content>
    <div>
      <app-autocomplete [label]="'Document'"
                        [placeholder]="'Select a Document'"
                        [(value)]="model.documentId"
                        [isDisabled]="isBusy"
                        [options]="documents"
                        [canBeNull]="false">
      </app-autocomplete>
    </div>

    <div>
      <mat-form-field color="accent">
        <mat-label>Action</mat-label>
        <mat-select [(ngModel)]="model.type" [disabled]="isBusy">
          <mat-option *ngFor="let type of types" [value]="type.value">
            {{ type.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="cancel()" [disabled]="isBusy">Cancel</button>
    <button mat-raised-button color="accent" class="button accent-button" (click)="save()" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
