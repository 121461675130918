import { UserModel } from "./user-model";

export class AuthenticatedResponseModel {
  public token: string;
  public refreshToken: string;
  public user: UserModel;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
