import { Component, Inject } from "@angular/core";
import { ProcessAuditTaskNonConformanceActionModel } from "../../../../models/process-audit-task-non-conformance-action-model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ProcessAuditTaskNonConformanceService } from "../../../../services/process-audit-task-non-conformance.service";
import { AuthService } from "../../../../services/auth.service";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatFormField, MatLabel, MatError } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";

@Component({
    selector: 'app-process-audit-task-non-conformance-action-reject-dialog',
    templateUrl: './process-audit-task-non-conformance-action-reject-dialog.component.html',
    styleUrls: ['./process-audit-task-non-conformance-action-reject-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, NgIf, MatError, MatDialogActions, MatButton]
})
export class ProcessAuditTaskNonConformanceActionRejectDialogComponent {
  public action: ProcessAuditTaskNonConformanceActionModel;

  constructor(@Inject(MAT_DIALOG_DATA) public model: ProcessAuditTaskNonConformanceActionModel,
    private dialogRef: MatDialogRef<ProcessAuditTaskNonConformanceActionRejectDialogComponent>,
    private toastr: ToastrService,
    private processAuditTaskNonConformanceService: ProcessAuditTaskNonConformanceService,
    public authService: AuthService) {
    this.action = structuredClone(model);
  }

  public rejectAction() {
    if (!this.action.rejectionComment) {
      this.toastr.error("Rejection Comment is required.")
      return;
    } else if (!confirm(`Are you sure you want to reject this Action?`)) {
      return;
    }

    this.processAuditTaskNonConformanceService.rejectNonConformanceActionForClosure(this.action.processAuditTaskNonConformanceActionId, this.action.rejectionComment).subscribe({
      next: () => {
        this.toastr.success("Action rejected for closure");
        this.dialogRef.close(this.action.rejectionComment)
      }
    })
  }

  public close() {
    this.dialogRef.close();
  }
}
