import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CustomModel } from '../../../../models/custom-model';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ProcessModel } from '../../../../models/process-model';
import { AuthService } from '../../../../services/auth.service';
import { CustomService } from '../../../../services/custom.service';
import { ToastrService } from 'ngx-toastr';
import { CustomSelectDialogComponent } from '../../../../shared/custom-select-dialog/custom-select-dialog.component';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableUtility } from '../../../../utility/mat-table-utility';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../shared/loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'app-process-custom-list-dialog',
    templateUrl: './process-custom-list-dialog.component.html',
    styleUrls: ['./process-custom-list-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, MatButton, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatDialogActions]
})
export class ProcessCustomListDialogComponent implements OnInit {
  public isLoading: boolean = true;
  public customs: Array<CustomModel> = [];
  public processCustomTableData = new MatTableDataSource<CustomModel>([]);
  public processCustomColumns: string[] = ['customGroupTitle', 'title'];

  private customSort: MatSort;

  @ViewChild('processCustomTable', { read: MatSort, static: false }) set processCustomSortValue(value: MatSort) {
    if (value) {
      this.customSort = value;
      this.processCustomTableData.sort = this.customSort;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public process: ProcessModel,
    private dialogRef: MatDialogRef<ProcessCustomListDialogComponent>,
    private authService: AuthService,
    private customService: CustomService,
    private toastr: ToastrService,
    private dialog: MatDialog) {
    }

  public ngOnInit(): void {
    this.processCustomTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    if (this.canEdit()) {
      this.processCustomColumns.push("controls");
    }

    this.customService.getCustomsForProcess(this.process.processId).subscribe({
      next: (response: Array<CustomModel>) => {
        this.customs = response;
        this.refreshProcessCustomTableData();
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  private refreshProcessCustomTableData(): void {
    this.processCustomTableData.data = this.customs;
  }

  public createProcessCustom() {
    if (!this.canEdit()) {
      return;
    }

    const selectedCustomIds = this.customs.map(i => i.customId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedCustomIds: selectedCustomIds,
      },
      width:"60%",
    }

    const dialogRef = this.dialog.open(CustomSelectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: number | null) => {
        if (result) {
          this.customService.saveProcessCustom(this.process.processId, result).subscribe({
            next: (response: CustomModel) => {
              this.customs.push(response);
              this.refreshProcessCustomTableData();
              this.toastr.success("Custom added");
            }
          });
        }
      }
    });
  }

  public deleteProcessCustom(custom: CustomModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Custom "${custom.title}"?`)) {
      return;
    }

    this.customService.deleteProcessCustom(this.process.processId, custom.customId!).subscribe({
      next: () => {
        this.customs.splice(this.customs.indexOf(custom), 1);
        this.refreshProcessCustomTableData();
        this.toastr.success("Process Custom deleted");
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
