import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { ExceptionLogModel } from '../../models/exception-log-model';
import { ExceptionLogService } from '../../services/exception-log.service';
import { MatPaginator } from '@angular/material/paginator';
import { NgIf, DatePipe } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-exception-log',
    templateUrl: './exception-log.component.html',
    styleUrls: ['./exception-log.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        LoadingSpinnerComponent,
        MatCard,
        MatCardContent,
        MatFormField,
        MatInput,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        MatCellDef,
        MatCell,
        MatCheckbox,
        FormsModule,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatNoDataRow,
        MatPaginator,
        DatePipe,
    ],
})
export class ExceptionLogComponent {
  public isLoading: boolean = true;
  public exceptionLogs: Array<ExceptionLogModel>;

  public exceptionLogTableData = new MatTableDataSource<ExceptionLogModel>([])
  public exceptionLogColumns: string[] = ['loggedUtc', 'endPoint', 'path', 'message', 'hasBeenReviewed'];

  private exceptionLogSort: MatSort;
  public expandedExceptionLog: ExceptionLogModel | null;

  @ViewChild('exceptionLogTable', { read: MatSort, static: false }) set exceptionLogSortValue(value: MatSort) {
    if (value) {
      this.exceptionLogSort = value;
      this.exceptionLogTableData.sort = this.exceptionLogSort;
    }
  };

  @ViewChild(MatPaginator, { static: false }) set paginator(value: MatPaginator) {
    if (this.exceptionLogTableData) {
      this.exceptionLogTableData.paginator = value;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.exceptionLogTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private exceptionLogService: ExceptionLogService) {
  }

  public ngOnInit(): void {
    this.exceptionLogTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.exceptionLogService.getAllExceptionLogs().subscribe({
      next: (response: Array<ExceptionLogModel>) => {
        this.exceptionLogs = response;
        this.refreshExceptionLogTableData();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  private refreshExceptionLogTableData(): void {
    this.exceptionLogTableData.data = this.exceptionLogs;
  }

  public hasBeenReviewedChanged(exceptionLog: ExceptionLogModel): void {
    this.exceptionLogService.toggleHasBeenReviewed(exceptionLog.exceptionLogId).subscribe({
      next: () => {
      },
      error: () => {
        const index = this.exceptionLogs.findIndex(i => i.exceptionLogId == exceptionLog.exceptionLogId);
        this.exceptionLogs[index].hasBeenReviewed = !this.exceptionLogs[index].hasBeenReviewed;
        this.refreshExceptionLogTableData();
      }
    });
  }
}
