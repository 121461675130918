import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthApiService } from '../../../services/auth-api.service';
import { MultiFactorAuthenticationSubmitModel } from '../../../models/multi-factor-authentication-submit-model';

@Component({
  selector: 'app-multi-factor-authentication-dialog',
  templateUrl: './multi-factor-authentication-dialog.component.html',
  styleUrls: ['./multi-factor-authentication-dialog.component.scss']
})
export class MultiFactorAuthenticationDialogComponent {
  public code: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MultiFactorAuthenticationDialogComponent>,
    private authApiService: AuthApiService) {
  }

  public submit() {
    if (!this.code || this.code == "") {
      return;
    }

    let model = new MultiFactorAuthenticationSubmitModel;
    model.emailAddress = this.data.emailAddress;
    model.code = this.code;

    this.authApiService.submitMultiFactorAuthenticationCode(model).subscribe({
      next: () => {
        this.dialogRef.close(true);
      }
    });
  }

  public close() {
    this.dialogRef.close(false);
  }
}
