<div class="col-sm-10 offset-sm-1">
  <mat-card appearance="outlined" class="company-select-card">
    <mat-card-header>
      <mat-card-title class="company-select-header">This user account is associated with more than one company</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field color="accent">
        <mat-label>Company</mat-label>
        <mat-select (selectionChange)="onSelectionChanged()" [(value)]="companyId">
          <mat-option *ngFor="let company of sortedCompanies" [value]="company.companyId">{{company.displayName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</div>
