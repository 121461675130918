import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomModel } from '../../models/custom-model';
import { CustomService } from '../../services/custom.service';


@Component({
  selector: 'app-custom-select-dialog',
  templateUrl: './custom-select-dialog.component.html',
  styleUrls: ['./custom-select-dialog.component.scss']
})
export class CustomSelectDialogComponent {
  public options: Array<CustomModel>;
  public selectedCustomIds: Array<number> = [];
  public isLoading: boolean = true;

  private _selectionId: number | null;

  public get selectionId(): number | null {
    return this._selectionId;
  }

  public set selectionId(value: number | null) {
    if (this._selectionId != value) {
      this._selectionId = value;
      this.dialogRef.close(this._selectionId);
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomSelectDialogComponent>,
    private customService: CustomService) {
    this.selectedCustomIds = data.selectedCustomIds;
  }

  public ngOnInit() {
    this.customService.getCustoms().subscribe({
      next: (response: Array<CustomModel>) => {
        this.options = response.filter(i => !this.selectedCustomIds.includes(i.customId));
        this.isLoading = false;
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
