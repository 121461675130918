<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()" mat-flat-button color="accent" class="button accent-button" (click)="createResourceGroup()">Add Resource Group</button>
            <button mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/resource-register" color="accent" class="button accent-button">Resource Register</button>
          </div>

          <table #resourceGroupTable mat-table [dataSource]="resourceGroupTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let resourceGroup">
                <button mat-flat-button
                        [ngClass]="{ 'row-highlight': selectedResourceGroup?.resourceGroupId == resourceGroup.resourceGroupId }"
                        (click)="selectResourceGroup(resourceGroup)"
                        class="button table-button">
                  {{resourceGroup.title}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let resourceGroup" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editResourceGroup(resourceGroup)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteResourceGroup(resourceGroup)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="resourceGroupColumns"></tr>
            <tr mat-row
                [ngClass]="{ 'row-highlight' : selectedResourceGroup?.resourceGroupId == resourceGroup.resourceGroupId }"
                *matRowDef="let resourceGroup; columns: resourceGroupColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()"
                    mat-flat-button
                    color="accent"
                    class="button accent-button"
                    [disabled]="selectedResourceGroup == null"
                    (click)="createResource()">Add Resource</button>
          </div>

          <table #resourceTable mat-table [dataSource]="resourceTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let resource">
                <button mat-flat-button
                        (click)="editResource(resource)"
                        class="button table-button">
                  {{ resource.title }}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let resource" class=" mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editResource(resource)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteResource(resource)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="resourceColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: resourceColumns"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="resourceColumns.length" class="no-data-row">
                {{ selectedResourceGroup == null ? 'No group selected' : 'No resources found for the selected group' }}
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
