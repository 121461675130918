<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-12">
      <h2>Details</h2>
      <form id="companyForm" #companyForm="ngForm" (ngSubmit)="save(companyForm)">
        <mat-form-field color="accent">
          <mat-label>Name</mat-label>
          <input matInput
                 placeholder="Enter name..."
                 name="name"
                 [(ngModel)]="company.name"
                 #name="ngModel"
                 [disabled]="isBusy || !authService.currentUser!.isAdministrator"
                 required>
          <mat-error *ngIf="name.invalid">
            Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent">
          <mat-label>Display Name</mat-label>
          <input matInput
                 placeholder="Enter name..."
                 name="displayName"
                 [(ngModel)]="company.displayName"
                 #name="ngModel"
                 [disabled]="isBusy"
                 required>
          <mat-error *ngIf="name.invalid">
            Display Name is required
          </mat-error>
        </mat-form-field>


        <mat-form-field color="accent">
          <mat-label>Focal Point</mat-label>
          <input matInput
                 placeholder="Enter focal point..."
                 name="focalPoint"
                 [(ngModel)]="company.focalPoint"
                 [disabled]="isBusy">
        </mat-form-field>

        <mat-form-field color="accent">
          <mat-label>Message</mat-label>
          <textarea matInput
                    placeholder="Enter message..."
                    name="message"
                    [(ngModel)]="company.message"
                    [disabled]="isBusy">
        </textarea>
        </mat-form-field>

        <mat-form-field color="accent">
          <mat-label>RACI 'A' Value</mat-label>
          <input matInput
                 placeholder="Enter value..."
                 name="accountableText"
                 [(ngModel)]="company.accountableText"
                 #accountableText="ngModel"
                 [disabled]="isBusy"
                 required>
          <mat-error *ngIf="accountableText.invalid">
            RACI 'A' Value is required
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" *ngIf="authService.currentUser!.isAdministrator">
          <mat-label>User Limit</mat-label>
          <input matInput
                 type="number"
                 min="0"
                 placeholder="Enter User Limit..."
                 name="userLimit"
                 [(ngModel)]="company.userLimit"
                 [disabled]="isBusy">
        </mat-form-field>

        <mat-form-field color="accent">
          <mat-label>Custom Navbar Item Name</mat-label>
          <input matInput
                 placeholder="Enter name..."
                 name="customNavDisplayName"
                 [(ngModel)]="company.customNavItemDisplayName"
                 [disabled]="isBusy">
        </mat-form-field>

        <mat-form-field color="accent">
          <mat-label>Custom Navbar Link</mat-label>
          <input matInput
                 placeholder="Enter link..."
                 name="customNavUrl"
                 [(ngModel)]="company.customNavItemUrl"
                 [disabled]="isBusy">
        </mat-form-field>
      </form>

      <div>
        <input type="color"
               [(ngModel)]="company.swimlaneColor"
               [disabled]="isBusy"
               class="swimlaneColor"
               name="swimlaneColor">
        <label for="swimlaneColor" class="swimLaneLabel">RACI Swimlane Colour</label>
      </div>

      <div>
        <mat-checkbox [(ngModel)]="company.isMultiFactorAuthenticationEnabled" [disabled]="isBusy || !authService.currentUser!.isAdministrator">Multi Factor Authentication Enabled?</mat-checkbox>
      </div>

      <div>
        <button *ngIf="authService.currentUser!.isAdministrator" mat-flat-button color="accent" class="button accent-button" (click)="toggleSingleSignOn()">{{ company.isSingleSignOnEnabled ? 'Disable SSO' : 'Enable SSO'}}</button>
        <mat-checkbox *ngIf="!authService.currentUser!.isAdministrator" [(ngModel)]="company.isSingleSignOnEnabled" [disabled]="!authService.currentUser!.isAdministrator">SSO Enabled?</mat-checkbox>
      </div>

      <div>
        <mat-checkbox [(ngModel)]="company.isNotificationsEnabled" [disabled]="isBusy">Daily Notifications</mat-checkbox>
      </div>

      <div>
        <mat-checkbox [(ngModel)]="company.shouldHidePrismUsers" [disabled]="isBusy">
          Hide Prism Users <mat-icon inline="true" color="accent" matTooltip="Hide Prism users from lists and dropdowns where applicable">info</mat-icon>
        </mat-checkbox>
      </div>

      <div>
        <input type="file" #file (change)="uploadFile(file.files)" class="file-input" accept=".jpg, .png, .jpeg" onclick="this.value=null;">
        <div>
          <span *ngIf="logo && logo.fileName"><a href="javascript:void(0);" (click)="downloadLogo()">{{ logo.fileName }}</a> </span>
          <span *ngIf="!logo || !logo.fileName">No logo uploaded yet.</span>
          <button mat-mini-fab color="accent" (click)="file.click()" class="accent-button document-button" [disabled]="isBusy || !isEditingCurrentCompany">
            <mat-icon>attach_file</mat-icon>
          </button>
          <button *ngIf="logo" mat-mini-fab color="accent" (click)="deleteLogo()" class="accent-button document-button" [disabled]="isBusy || !isEditingCurrentCompany">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

      <div>
        <mat-checkbox [(ngModel)]="company.isLogoShownOnNavbar" [disabled]="isBusy">
          Show Company Logo On Navbar
        </mat-checkbox>
      </div>

      <button mat-flat-button type="submit" color="accent" class="button accent-button" form="companyForm" [disabled]="isBusy">Save</button>
    </div>
  </div>

  <h2 class="col-sm-3">
    Users 
    <mat-label *ngIf="company.userLimit !== null" class="user-limit">
      - {{ nonPrismUsers.length }} / {{ company.userLimit }}
    </mat-label>
  </h2>

  <div class="list-button-row">
    <button mat-flat-button
            color="accent"
            class="button accent-button"
            *ngIf="company.companyId && (this.authService.currentUser?.isAdministrator || this.company.userLimit != null)"
            (click)="addUser()"
            [disabled]="isBusy">Add User
    </button>

    <button mat-flat-button
            color="accent"
            class="button accent-button"
            *ngIf="company.companyId && this.authService.currentUser!.isCompanyAdministrator"
            (click)="exportUser()"
            [disabled]="isBusy">Export User Report
    </button>

    <input type="file" #usersFile (change)="importUsers(usersFile.files)" class="file-input" accept=".xlsx">
    <button mat-flat-button
            color="accent"
            class="button accent-button"
            *ngIf="company.companyId && authService.currentUser!.isAdministrator"
            (click)="usersFile.click()"
            [disabled]="isBusy">Import Users
    </button>

    <button mat-flat-button
            color="accent"
            class="button accent-button"
            *ngIf="company.companyId && authService.currentUser!.isAdministrator"
            (click)="downloadTemplate()"
            [disabled]="isBusy">User Import Template
    </button>
  </div>
  <table #userTable mat-table [dataSource]="tableData" matSort matSortActive="displayName" matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="displayName">Name</th>
      <td mat-cell *matCellDef="let user">
        <a mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/admin/company/{{company.companyId}}/user/{{user.userId}}" class="table-button">{{user.displayName}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="isVerified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="isVerified">Verified</th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox [(ngModel)]="user.isVerified" disabled="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="isEnabled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="isEnabled">Enabled</th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox [(ngModel)]="user.isEnabled" disabled="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="isCompanyAdministrator">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="isCompanyAdministrator">Admin</th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox [(ngModel)]="user.isCompanyAdministrator" disabled="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="isEditor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="isEditor">Editor</th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox [(ngModel)]="user.isEditor" disabled="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="isApprover">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="isApprover">Approver</th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox [(ngModel)]="user.isApprover" disabled="true"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="controls">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user" class="mat-cell-end">
        <button mat-icon-button color="accent" *ngIf="!user.isVerified" (click)="resendVerificationEmail(user)" matTooltip="Resend Verification" [disabled]="isBusy">
          <mat-icon>mail</mat-icon>
        </button>
        <button mat-icon-button color="accent" *ngIf="user.isLockedOut" (click)="unlockUserAccount(user)" matTooltip="Unlock Account" [disabled]="isBusy">
          <mat-icon>lock_open_right</mat-icon>
        </button>
        <button mat-icon-button color="accent" (click)="deleteUser(user)" matTooltip="Delete" [disabled]="isBusy">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns; sticky: true;"></tr>
    <tr mat-row *matRowDef="let user; columns: columns"></tr>
    <tr *matNoDataRow>
      <td [attr.colspan]="columns.length" class="no-data-row">
        No users found
      </td>
    </tr>
  </table>
</div>
