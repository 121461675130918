export class DocumentAssociationModel{
  public documentId: number;
  public documentGroupId: number;
  public documentTitle: string | null;
  public documentIdentifier: string;
  public association: string;

  public get documentName() {
    return this.documentTitle ? this.documentIdentifier + ' - ' + this.documentTitle : this.documentIdentifier
  }

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
