<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>Close Audit</h2>
  </div>

  <mat-dialog-content>
    <div class="readonly-section">
      <label class="readonly-label">
        Current Review Date
      </label>
      <div class="readonly-content">
        @if (processAudit.processReviewDate) {
          {{ processAudit.processReviewDate | date: 'dd/MM/yyyy' }}
        } @else {
          None
        }
      </div>
    </div>

    <mat-form-field color="accent">
      <mat-label>Next Review Date</mat-label>
      <input matInput [matDatepicker]="nextReviewPicker" [(ngModel)]="processAudit.nextReviewDate" name="nextReviewDate" [disabled]="isBusy" required #nextReviewDate="ngModel" />
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="nextReviewPicker"></mat-datepicker-toggle>
      <mat-datepicker #nextReviewPicker></mat-datepicker>
      <mat-error *ngIf="nextReviewDate.invalid">
        Next Review Date is required
      </mat-error>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Closed Comment</mat-label>
      <textarea matInput
                rows="5"
                placeholder="Enter Closed Comment..."
                name="closedComment"
                [(ngModel)]="processAudit.closedComment"
                #closedComment="ngModel"
                required></textarea>
      <mat-error *ngIf="closedComment.invalid">
        Closed Comment is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Cancel</button>
    <button *ngIf="this.authService.canCurrentUserEdit"
            mat-raised-button
            class="button green-button"
            (click)="closeAudit()">
      Close Audit
    </button>
  </mat-dialog-actions>
</section>
