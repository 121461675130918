import { AttachableModelType } from "../enums/attachable-model-type";
import { FileDocumentAttachableModel } from "./file-document-attachable-model";
import { UserModel } from "./user-model";

export class ProcessAuditTaskNonConformanceActionModel extends FileDocumentAttachableModel {
  public processAuditTaskNonConformanceActionId: number;
  public processAuditTaskNonConformanceId: number;
  public description: string;
  public assignedToUser: UserModel;
  public dueDate: Date;
  public status: string;
  public rejectionComment: string;
  public closedByUser: UserModel | null;
  public closureApproverUser: UserModel | null;
  public closedUtc: Date;

  public processAuditTaskNonConformanceTypeDescription: string = "";

  constructor(data?: any) {
    super();
    if (data) {
      if (data.processAuditTaskNonConformanceActionId) {
        this.attachedToId = data.processAuditTaskNonConformanceActionId;
        this.attachedToType = AttachableModelType.ProcessAuditTaskNonConformanceAction;
      }

      if (data.assignedToUser) {
        data.assignedToUser = new UserModel(data.assignedToUser);
      }

      if (data.closedByUser) {
        data.closedByUser = new UserModel(data.closedByUser);
      }

      if (data.closureApproverUser) {
        data.closureApproverUser = new UserModel(data.closureApproverUser);
      }

      Object.assign(this, data);
    }
  }
}
