export class ConfigurationModel {
  public configurationId: number;
  public attachableFileTypes: string;
  public attachableFileMaxSizeMB: number;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
