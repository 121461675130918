import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { UserModel } from "../models/user-model";
import { ChangePasswordModel } from "../models/change-password-model";
import { UserVerificationModel } from "../models/user-verification-model";
import { AuthenticatedResponseModel } from "../models/authenticated-response-model";
import { LoginModel } from "../models/login-model";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private apiService: ApiService) {
  }

  public getUser(id: number): Observable<UserModel> {
    return this.apiService.get(`user/${id}`)
      .pipe(map(i => new UserModel(i)));
  }

  public getUsers(): Observable<Array<UserModel>> {
    return this.apiService.get("user/get-all")
      .pipe(map((response: Array<UserModel>) => response.map(i => new UserModel(i))));
  }

  public saveUser(model: UserModel): Observable<UserModel> {
    return this.apiService.post("user", model)
      .pipe(map(i => new UserModel(i)));
  }

  public importUsers(companyId: number, data: FormData): Observable<Array<UserModel>> {
    return this.apiService.post(`user/import/${companyId}`, data)
      .pipe(map((response: Array<UserModel>) => response.map(i => new UserModel(i))));
  }

  public deleteUser(userId: number): Observable<void> {
    return this.apiService.post(`user/delete/${userId}`, {});
  }

  public unlockUserAccount(userId: number): Observable<void> {
    return this.apiService.post(`user/unlock/${userId}`, {});
  }

  public refreshUser(userId: number, companyId: string): Observable<AuthenticatedResponseModel> {
    return this.apiService.get(`user/${userId}/${companyId}/refresh`)
      .pipe(map(i => new AuthenticatedResponseModel(i)));
  }

  public changePassword(model: ChangePasswordModel): Observable<void> {
    return this.apiService.post(`user/change-password`, model);
  }

  public getVerification(token: string | null): Observable<UserVerificationModel> {
    return this.apiService.get("user/verify", { token: token })
      .pipe(map(i => new UserVerificationModel(i)));
  }

  public postVerification(model: UserVerificationModel): Observable<void> {
    return this.apiService.post("user/verify", model);
  }

  public resendVerificationEmail(model: UserModel): Observable<void> {
    return this.apiService.post("user/resend", model);
  }

  public requestPasswordReset(model: LoginModel): Observable<void> {
    return this.apiService.post("user/request-reset", model);
  }

  public getReset(token: string | null): Observable<UserVerificationModel> {
    return this.apiService.get("user/reset", { token: token })
      .pipe(map(i => new UserVerificationModel(i)));
  }
}
