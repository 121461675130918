<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<div class="row" *ngIf="!isLoading">
  <div class="col-sm-6">
    <mat-card appearance="outlined" class="list-card">
      <mat-card-content>
        <div class="image-container">
          <img *ngIf="!blobUrl" class="responsive-img" src="assets/author-logo.png" alt="brand">
          <img *ngIf="blobUrl" class="responsive-img" src="{{blobUrl}}" />
        </div>

        <mat-card *ngIf="!isEditing" class="company-details-container">
          <mat-card-header class="home-header">
            <mat-card-title style="width: 100%; margin-bottom:0.5em">
              Company: {{ companyDetails.displayName }}
              <button *ngIf="canEdit()" class="company-details-edit-button" mat-icon-button (click)="toggleIsEditing()" matTooltip="Edit">
                <mat-icon class="company-details-edit-button">edit</mat-icon>
              </button>
            </mat-card-title>
            <mat-card-subtitle class="home-subtitle">Focal Point: {{ companyDetails.focalPoint }}</mat-card-subtitle>
          </mat-card-header>
          <mat-divider class="home-divider"></mat-divider>
          <mat-card-content>
            <div class="company-message">
              {{ companyDetails.message }}
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="isEditing" class="company-details-container">
          <mat-card-content>
            <div>
              <button class="company-details-edit-button" mat-icon-button (click)="save()" matTooltip="Save">
                <mat-icon class="company-details-edit-button">save</mat-icon>
              </button>
              <button class="company-details-edit-button" mat-icon-button (click)="undo()" matTooltip="Undo">
                <mat-icon class="company-details-edit-button">undo</mat-icon>
              </button>
            </div>
            <mat-form-field color="accent">
              <mat-label>Company Name</mat-label>
              <input matInput [(ngModel)]="companyDetails.displayName" [disabled]="true" />
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Focal Point</mat-label>
              <input matInput [(ngModel)]="companyDetails.focalPoint" />
            </mat-form-field>
            <mat-form-field color="accent">
              <mat-label>Description</mat-label>
              <textarea matInput [(ngModel)]="companyDetails.message" cdkTextareaAutosize></textarea>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-sm-6">
    <mat-card appearance="outlined" class="list-card">
      <mat-card-content>
        <mat-button-toggle-group value="{{processTreeId}}" class="home-tree-button-group" [hideSingleSelectionIndicator]="true">
          <mat-button-toggle value="{{processTreeId}}" (click)="setActiveTree(processTreeId)" class="home-tree-button-group-button">Processes</mat-button-toggle>
          <mat-button-toggle value="{{roleTreeId}}" (click)="setActiveTree(roleTreeId)" class="home-tree-button-group-button">Roles</mat-button-toggle>
          <mat-button-toggle value="{{documentTreeId}}" (click)="setActiveTree(documentTreeId)" class="home-tree-button-group-button">Documents</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl" class="drawer-tree">
          <ul>
            <mat-tree-node *matTreeNodeDef="let node">
              <li class="mat-tree-node">
                <button mat-button
                        *ngIf="node.parentId && node.id && activeTreeId"
                        class="button mat-drawer-tree-button"
                        (click)="openEntity(node)">
                  {{node.title}}
                </button>
                <button mat-button
                        *ngIf="canEdit() && node.parentId && !node.id && activeTreeId == processTreeId"
                        class="button mat-drawer-tree-button"
                        (click)="newProcess(node)">
                  <mat-icon color="accent">add</mat-icon>
                  Add Process
                </button>
                <div class="mat-tree-node">
                  <button mat-button *ngIf="!node.parentId" class="button unclickable-button">
                    <mat-icon></mat-icon>
                    {{ node.title }}
                  </button>
                </div>
              </li>
            </mat-tree-node>

            <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChild">
              <li>
                <div class="mat-tree-node">
                  <button mat-button matTreeNodeToggle class="button mat-drawer-tree-button">
                    <mat-icon>
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                    {{node.title}}
                  </button>
                </div>
                <ul [class.drawer-tree-invisible]="!treeControl.isExpanded(node)">
                  <ng-container matTreeNodeOutlet></ng-container>
                </ul>
              </li>
            </mat-nested-tree-node>
          </ul>
        </mat-tree>
      </mat-card-content>
    </mat-card>
  </div>
</div>
