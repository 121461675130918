import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { CustomRegisterModel } from '../../../models/custom-register-model';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableUtility } from '../../../utility/mat-table-utility';
import { CustomService } from '../../../services/custom.service';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';


@Component({
    selector: 'app-custom-register',
    templateUrl: './custom-register.component.html',
    styleUrls: ['./custom-register.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatButton, MatFormField, MatInput, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})
export class CustomRegisterComponent implements OnInit {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public customRegisterTableData = new MatTableDataSource<CustomRegisterModel>([]);
  public customRegisterColumns: string[] = ['customGroup', 'customName', 'value', 'unitOfMeasure', 'description'];

  private customSort: MatSort;

  @ViewChild('customRegisterTable', { read: MatSort, static: false }) set customRegisterSortValue(value: MatSort) {
    if (value) {
      this.customSort = value;
      this.customRegisterTableData.sort = this.customSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.customRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private customService: CustomService) {
  }

  public ngOnInit(): void {
    this.customRegisterTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.customService.getCustomRegister().subscribe({
      next: (response: Array<CustomRegisterModel>) => {
        this.customRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.customService.exportRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
