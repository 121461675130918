import { ProcessAuditTaskModel } from "./process-audit-task-model";
import { UserModel } from "./user-model";

export class ProcessAuditModel {
  public processAuditId: number;
  public processId: number;
  public processName: string;
  public auditedByUser: UserModel;
  public date: Date;
  public status: string;
  public rejectionComment: string;
  public closedByUser: UserModel | null;
  public closureApproverUser: UserModel | null;
  public closedUtc: Date;
  public closedComment: string;
  public processDescription: string | null;
  public processClauses: string | null;

  public processAuditTasks: ProcessAuditTaskModel[];

  public processReviewDate: Date;
  public nextReviewDate: Date;

  constructor(data?: any) {
    if (data) {
      if (data.auditedByUser) {
        data.auditedByUser = new UserModel(data.auditedByUser);
      }

      if (data.closedByUser) {
        data.closedByUser = new UserModel(data.closedByUser);
      }

      if (data.closureApproverUser) {
        data.closureApproverUser = new UserModel(data.closureApproverUser);
      }

      Object.assign(this, data);
    }
  }
}
