export class MatTableUtility {
  public static caseInsensitiveSortingDataAccessor = (data: any, sortHeaderId: string) => {
    if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  };

  public static splitCamelCase(text: string): string {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
