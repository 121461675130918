import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { CategoryModel } from "../models/category-model";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private apiService: ApiService) {
  }

  public getCategories(): Observable<Array<CategoryModel>> {
    return this.apiService.get("category/get-all")
      .pipe(map((response: Array<CategoryModel>) => response.map(i => new CategoryModel(i))));
  }

  public saveCategory(model: CategoryModel): Observable<CategoryModel> {
    return this.apiService.post("category", model)
      .pipe(map(i => new CategoryModel(i)));
  }

  public deleteCategory(id: number): Observable<void> {
    return this.apiService.post(`category/delete/${id}`, {});
  }
}
