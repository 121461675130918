export class ProcessTaskProcessModel {
  public processTaskProcessId: number;
  public processTaskId: number;
  public processId: number
  public subProcessTaskId: number | null;

  public displayName: string | null;
  public processProcessGroupId: number | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
