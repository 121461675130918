import { Component, OnInit, ViewChild, inject } from "@angular/core";
import { CategoryModel } from "../../models/category-model";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { CategoryService } from "../../services/category.service";
import { ToastrService } from "ngx-toastr";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";
import { MatTableUtility } from "../../utility/mat-table-utility";
import { CategoryDialogComponent } from "./category-dialog/category-dialog.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  private route = inject(ActivatedRoute);

  public categories: Array<CategoryModel>;
  public isLoading: boolean;

  public categoryTableData = new MatTableDataSource<CategoryModel>([])
  public categoryColumns: string[] = ['name', 'controls'];

  private categorySort: MatSort;

  @ViewChild('categoryTable', { read: MatSort, static: false }) set categorySortValue(value: MatSort) {
    if (value) {
      this.categorySort = value;
      this.categoryTableData.sort = this.categorySort;
    }
  };

  constructor(private categoryService: CategoryService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private authService: AuthService) {
  }

  public ngOnInit(): void {
    let categoryIdParam = Number(this.route.snapshot.paramMap.get("categoryId"));

    this.isLoading = true;

    this.categoryTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.categoryService.getCategories().subscribe({
      next: (response: Array<CategoryModel>) => {
        this.categories = response;
        this.refreshCategoryTableData();

        if (categoryIdParam != 0) {
          const category = this.categories.find(i => i.categoryId == categoryIdParam);
          if (category) {
            this.editCategory(category);
          }
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public createCategory() {
    if (!this.canEdit()) {
      return;
    }

    const category = new CategoryModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...category
      }
    };

    const dialogRef = this.dialog.open(CategoryDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CategoryModel) => {
        if (result) {
          this.categories.push(result);
          this.refreshCategoryTableData();
        }
      }
    });
  }

  public editCategory(category: CategoryModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...category,
      },
      autoFocus: this.canEdit()
    };

    const dialogRef = this.dialog.open(CategoryDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CategoryModel) => {
        if (result) {
          const index = this.categories.findIndex(i => i.categoryId == result.categoryId);
          this.categories[index] = result;
          this.refreshCategoryTableData();
        }
      }
    });
  }

  public deleteCategory(category: CategoryModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Category "${category.name}"?`)) {
      return;
    }

    this.categoryService.deleteCategory(category.categoryId).subscribe({
      next: () => {
        this.categories.splice(this.categories.indexOf(category), 1);
        this.refreshCategoryTableData();
        this.toastr.success(`Category "${category.name}" deleted`);
      }
    });
  }

  private refreshCategoryTableData(): void {
    this.categoryTableData.data = this.categories;
  }
}
