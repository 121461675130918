<div class="col-sm-10 offset-sm-1">
  <mat-card appearance="outlined" class="login-card">
    <mat-card-header>
      <div class="login-header">
        <img src="assets/author-logo.png" alt="author-logo" width="333" height="180">
      </div>
    </mat-card-header>

    <mat-card-content>
      <form #submitEmailForm="ngForm" (ngSubmit)="submitEmail(submitEmailForm)" *ngIf="activeState == submitEmailState">
        <div class="col-sm-12 button-row">
          <div class="col-sm-12">
            <mat-form-field color="accent">
              <mat-label>Email Address</mat-label>
              <input type="email" name="emailAddress" matInput [(ngModel)]="credentials.emailAddress" #emailAddress="ngModel" required [disabled]="isBusy">
              <mat-error *ngIf="emailAddress.invalid">
                Email is required
              </mat-error>
            </mat-form-field>
          </div>

          <button type="submit" mat-flat-button color="accent" class="button accent-button" [disabled]="!submitEmailForm.valid || isBusy">Continue</button>
        </div>
      </form>

      <form #loginForm="ngForm" (ngSubmit)="tryLogin(loginForm)" *ngIf="activeState == loginState">
        <div class="col-sm-12">
          <mat-form-field color="accent">
            <mat-label>Email Address</mat-label>
            <input type="email" name="emailAddress" matInput [(ngModel)]="credentials.emailAddress" #emailAddress="ngModel" required [disabled]="true">
            <mat-error *ngIf="emailAddress.invalid">
              Email is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field color="accent">
            <mat-label>Password</mat-label>
            <input type="password" name="password" matInput [(ngModel)]="credentials.password" #password="ngModel" required [disabled]="isBusy">
            <mat-error *ngIf="password.invalid">
              Password is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12 button-row">
          <button type="submit" mat-flat-button color="accent" class="button accent-button" [disabled]="!loginForm.valid || isBusy">Sign In</button>
          <button mat-stroked-button class="button" (click)="reset()" [disabled]="isBusy">Request Password Reset</button>
          <button mat-stroked-button class="button" (click)="changeState(submitEmailState)" [disabled]="isBusy">Cancel</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
