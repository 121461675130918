import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { RoleGroupModel } from '../../models/role-group-model';
import { RoleService } from '../../services/role.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoleModel } from '../../models/role-model';
import { RoleDialogComponent } from './role-dialog/role-dialog.component';
import { RoleGroupDialogComponent } from './role-group-dialog/role-group-dialog.component';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
  private route = inject(ActivatedRoute);

  public roleGroups: Array<RoleGroupModel>;
  public isLoading: boolean;
  public roleGroupTableData = new MatTableDataSource<RoleGroupModel>([]);
  public roleGroupColumns: string[] = ['title', 'controls'];
  public selectedRoleGroup: RoleGroupModel | null = null;

  public roleTableData = new MatTableDataSource<RoleModel>([]);
  public roleColumns: string[] = ['title', 'controls'];

  private roleGroupSort: MatSort;
  private roleSort: MatSort;

  @ViewChild('roleGroupTable', { read: MatSort, static: false }) set roleGroupSortValue(value: MatSort) {
    if (value) {
      this.roleGroupSort = value;
      this.roleGroupTableData.sort = this.roleGroupSort;
    }
  };

  @ViewChild('roleTable', { read: MatSort, static: false }) set roleSortValue(value: MatSort) {
    if (value) {
      this.roleSort = value;
      this.roleTableData.sort = this.roleSort;
    }
  };

  constructor(private roleService: RoleService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public authService: AuthService) {
  }

  public ngOnInit(): void {
    let groupIdParam = Number(this.route.snapshot.paramMap.get("groupId"));
    let roleIdParam = Number(this.route.snapshot.paramMap.get("roleId"));

    this.isLoading = true;

    this.roleGroupTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;
    this.roleTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.roleService.getRoleGroups().subscribe({
      next: (response: Array<RoleGroupModel>) => {
        this.roleGroups = response;
        this.refreshRoleGroupTableData();

        if (groupIdParam != 0 && roleIdParam != 0) {
          const roleGroup = this.roleGroups.find(group => group.roleGroupId === groupIdParam);
          const role = roleGroup?.roles.find(role => role.roleId == roleIdParam);
          if (roleGroup && role) {
            this.selectedRoleGroup = roleGroup;
            this.refreshRoleTableData();
            this.editRole(role);
          }
        } else if (groupIdParam != 0 && roleIdParam == 0) {
          const roleGroup = this.roleGroups.find(group => group.roleGroupId === groupIdParam);
          if (roleGroup) {
            this.selectedRoleGroup = roleGroup;
            this.refreshRoleTableData();
            this.editRoleGroup(roleGroup);
          }
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    })
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public selectRoleGroup(roleGroup: RoleGroupModel) {
    if (this.selectedRoleGroup != roleGroup) {
      this.selectedRoleGroup = roleGroup;
      this.refreshRoleTableData();
    }
  }

  public createRoleGroup() {
    if (!this.canEdit()) {
      return;
    }

    const roleGroup = new RoleGroupModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...roleGroup,
      }
    };

    const dialogRef = this.dialog.open(RoleGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: RoleGroupModel) => {
        if (result) {
          this.roleGroups.push(result);
          this.selectedRoleGroup = result;
          this.refreshRoleGroupTableData();
          this.refreshRoleTableData();
        }
      }
    });
  }

  public editRoleGroup(roleGroup: RoleGroupModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...roleGroup,
      },
      autoFocus: this.canEdit()
    };

    const dialogRef = this.dialog.open(RoleGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: RoleGroupModel) => {
        if (result) {
          const index = this.roleGroups.findIndex(i => i.roleGroupId == result.roleGroupId);
          this.roleGroups[index] = result;
          this.refreshRoleGroupTableData();
        }
      }
    });
  }

  public deleteRoleGroup(roleGroup: RoleGroupModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Role Group "${roleGroup.title}"?`)) {
      return;
    }

    this.roleService.deleteRoleGroup(roleGroup.roleGroupId).subscribe({
      next: () => {
        this.roleGroups.splice(this.roleGroups.indexOf(roleGroup), 1);
        this.refreshRoleGroupTableData();
        this.toastr.success(`Role Group "${roleGroup.title}" deleted`);
      }
    });
  }

  public createRole() {
    if (!this.canEdit()) {
      return;
    }

    if (!this.selectedRoleGroup) {
      return;
    }

    const role = new RoleModel();
    role.roleGroupId = this.selectedRoleGroup.roleGroupId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...role,
      }
    };

    const dialogRef = this.dialog.open(RoleDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: RoleModel) => {
        if (result) {
          this.selectedRoleGroup!.roles.push(result);
          this.refreshRoleTableData();
        }
      }
    });
  }

  public editRole(role: RoleModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...role,
      }
    };

    const dialogRef = this.dialog.open(RoleDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: RoleModel) => {
        if (result) {
          const roleGroup = this.roleGroups.find(i => i.roleGroupId == result.roleGroupId);
          if (roleGroup) {
            const index = roleGroup.roles.findIndex(i => i.roleId == result.roleId);
            roleGroup.roles[index] = result;
            this.refreshRoleTableData();
          }
        }
      }
    });
  }

  public deleteRole(role: RoleModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Role "${role.title}"?`)) {
      return;
    }

    this.roleService.deleteRole(role.roleId).subscribe({
      next: () => {
        const roleGroup = this.roleGroups.find(i => i.roleGroupId == role.roleGroupId);
        if (roleGroup) {
          roleGroup.roles.splice(roleGroup.roles.indexOf(role), 1);
          this.refreshRoleTableData();
          this.toastr.success(`Role "${role.title}" deleted`);
        }
      }
    });
  }

  private refreshRoleGroupTableData(): void {
    this.roleGroupTableData.data = this.roleGroups;
  }

  private refreshRoleTableData(): void {
    if (this.selectedRoleGroup) {
      this.roleTableData.data = this.selectedRoleGroup.roles;
    } else {
      this.roleTableData.data = [];
    }
  }
}
