import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ProcessService } from '../../../../services/process.service';
import { ProcessGroupModel } from '../../../../models/process-group-model';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../shared/loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AutocompleteComponent } from '../../../../shared/autocomplete/autocomplete.component';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-process-copy-dialog',
    templateUrl: './process-copy-dialog.component.html',
    styleUrls: ['./process-copy-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton]
})
export class ProcessCopyDialogComponent {
  public options: Array<ProcessGroupModel>;
  public selectedProcessGroupIds: Array<number> = [];
  public isLoading: boolean = true;
  private _selectionId: number | null;

  public get selectionId(): number | null {
    return this._selectionId;
  }

  public set selectionId(value: number | null) {
    if (this._selectionId != value) {
      this._selectionId = value;
      this.dialogRef.close(this._selectionId);
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private processService: ProcessService,
    private dialogRef: MatDialogRef<ProcessCopyDialogComponent>) {
    this.selectedProcessGroupIds = data.selectedProcessGroupIds;
  }

  public ngOnInit() {
    this.processService.getProcessGroups().subscribe({
      next: (response: Array<ProcessGroupModel>) => {
        this.options = response.filter(i => !this.selectedProcessGroupIds.includes(i.processGroupId));
        this.isLoading = false;
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
