<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <mat-card appearance="outlined" class="list-card">
    <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
          <button mat-flat-button color="accent" class="button accent-button" (click)="export()" [disabled]="isBusy">Export</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field appearance="fill" color="accent" subscriptSizing="dynamic">
            <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table #processRevisionRegisterTable mat-table [dataSource]="processRevisionRegisterTableData" matSort
                 matSortActive="reference" matSortDirection="asc" matSortDisableClear>

            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reference">Reference</th>
              <td mat-cell *matCellDef=" let processRevisionData">{{processRevisionData.reference}}</td>
            </ng-container>

            <ng-container matColumnDef="processTitle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="processTitle">Process Title</th>
              <td mat-cell *matCellDef="let processRevisionData">
                <a href="/{{authService.currentUser!.companyName}}/processes/{{processRevisionData.processGroupId}}/{{processRevisionData.processId}}">
                  {{processRevisionData.processTitle}}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="revision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="revision">Revision</th>
              <td mat-cell *matCellDef="let processRevisionData">{{processRevisionData.revision}}</td>
            </ng-container>

            <ng-container matColumnDef="revisionDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="revisionDate">Revision Date</th>
              <td mat-cell *matCellDef="let processRevisionData">{{processRevisionData.revisionDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="draft">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="draft">Draft</th>
              <td mat-cell *matCellDef="let processRevisionData">
                <mat-checkbox [checked]="processRevisionData.draft" disabled>
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="reason">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reason">Reason</th>
              <td mat-cell *matCellDef="let processRevisionData">{{processRevisionData.reason}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processRevisionRegisterColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef="let processRevisionData; columns: processRevisionRegisterColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="processRevisionRegisterColumns.length" class="no-data-row">
                No revisions found
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
