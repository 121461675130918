import { Observable, map } from "rxjs";
import { DocumentGroupModel } from "../models/document-group-model";
import { Injectable } from "@angular/core";
import { DocumentModel } from "../models/document-model";
import { ApiService } from "./api.service";
import { DocumentRegisterModel } from "../models/document-register-model";
import { FileDocumentMetadataModel } from "../models/file-document-metadata-model";
import { TreeNodeModel } from "../models/tree-node-model";
import { DocumentAssociationModel } from "../models/document-association-model";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private apiService: ApiService) {
  }

  public getDocumentGroups(): Observable<Array<DocumentGroupModel>> {
    return this.apiService.get("document/group/get-all")
      .pipe(map((response: Array<DocumentGroupModel>) => response.map(i => new DocumentGroupModel(i))));
  }

  public saveDocumentGroup(model: DocumentGroupModel): Observable<DocumentGroupModel> {
    return this.apiService.post("document/group", model)
      .pipe(map(i => new DocumentGroupModel(i)));
  }

  public deleteDocumentGroup(id: number): Observable<void> {
    return this.apiService.post(`document/group/delete/${id}`, {});
  }

  public getDocuments(): Observable<Array<DocumentModel>> {
    return this.apiService.get(`document/get-all`)
      .pipe(map((response: Array<DocumentModel>) => response.map(i => new DocumentModel(i))));
  }

  public saveDocument(model: DocumentModel): Observable<DocumentModel> {
    return this.apiService.post("document", model)
      .pipe(map(i => new DocumentModel(i)));
  }

  public deleteDocument(id: number): Observable<void> {
    return this.apiService.post(`document/delete/${id}`, {});
  }

  public getProcessTaskDocumentSelections(processTaskId: number, documentId: number | null): Observable<Array<DocumentModel>> {
    return this.apiService.get(`document/process-task-selection`, { processTaskId: processTaskId, documentId: documentId ?? 0 })
      .pipe(map((response: Array<DocumentModel>) => response.map(i => new DocumentModel(i))));
  }

  public getCategoryDocuments(categoryId: number): Observable<Array<DocumentModel>> {
    return this.apiService.get(`document/get-category-documents/${categoryId}`)
      .pipe(map((response: Array<DocumentModel>) => response.map(i => new DocumentModel(i))));
  }

  public getStandardSectionDocuments(standardSectionId: number): Observable<Array<DocumentModel>> {
    return this.apiService.get(`document/standard-section/${standardSectionId}`)
      .pipe(map((response: Array<DocumentModel>) => response.map(i => new DocumentModel(i))));
  }

  public saveStandardSectionDocument(standardSectionId: number, documentId: number): Observable<DocumentModel> {
    return this.apiService.post(`document/standard-section/save/${standardSectionId}/${documentId}`, {})
      .pipe(map(i => new DocumentModel(i)));
  }

  public deleteStandardSectionDocument(standardSectionId: number, documentId: number) {
    return this.apiService.post(`document/standard-section/delete/${standardSectionId}/${documentId}`, {});
  }

  public getDocumentRegister(): Observable<Array<DocumentRegisterModel>> {
    return this.apiService.get("document/register")
      .pipe(map((response: Array<DocumentRegisterModel>) => response.map(i => new DocumentRegisterModel(i))));
  }

  public getRoleDocuments(roleId: number): Observable<Array<DocumentAssociationModel>> {
    return this.apiService.get(`document/get-role-documents/${roleId}`)
      .pipe(map((response: Array<DocumentAssociationModel>) => response.map(i => new DocumentAssociationModel(i))));
  }

  public uploadDocumentFile(documentId: number, data: FormData): Observable<FileDocumentMetadataModel | null> {
    return this.apiService.post(`document/upload-file/${documentId}`, data).
      pipe(map(i => new FileDocumentMetadataModel(i)));
  }

  public exportRegister(): Observable<any> {
    return this.apiService.postExportRaw(`document/register-export`, {});
  }

  public getDocumentsTree(): Observable<Array<TreeNodeModel>> {
    return this.apiService.get("document/get-documents-tree")
      .pipe(map((response: Array<TreeNodeModel>) => response.map(i => new TreeNodeModel(i))));
  }

  public getDocumentById(documentId: number): Observable<DocumentModel> {
    return this.apiService.get(`document/get/${documentId}`)
      .pipe(map(response => new DocumentModel(response)));
  }

}
