import { ProcessTaskModel } from "./process-task-model";
import { AutocompleteOptionModel } from "./autocomplete-option-model";
import { CategoryModel } from "./category-model";

export class ProcessModel extends AutocompleteOptionModel {
  public processId: number;
  public processGroupId: number;
  public title: string;
  public description: string;
  public reference: string;
  public ownerRoleId: number | null;
  public approverRoleId: number | null;
  public createdUtc: Date;
  public modifiedUtc: Date;
  public isDraft: boolean = false;
  public reviewDate: Date | null;

  public processTasks: Array<ProcessTaskModel>;
  public categories: Array<CategoryModel> = [];

  public get referenceAndTitle() {
    return this.reference ? this.reference + ' - ' + this.title : this.title;
  }

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.processId;
      this.displayValue = data.reference ? data.reference + ' - ' + data.title : data.title;

      if (data.processTasks) {
        data.processTasks = data.processTasks.map((i: any) => new ProcessTaskModel(i));
      }

      if (data.categories) {
        data.categories = data.categories.map((i: any) => new CategoryModel(i));
      }

      if (data.reviewDate) {
        data.reviewDate = new Date(data.reviewDate);
      }

      Object.assign(this, data);
    }
  }
}
