export class ResourceRegisterModel {
  public resourceGroupTitle: string;
  public resourceName: string;
  public quantity: string | null;
  public unitOfMeasure: string | null;
  public description: string | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
