import { FileDocumentMetadataModel } from "./file-document-metadata-model";

export class ProcessTaskDocumentModel {
  public processTaskDocumentId: number;
  public processTaskId: number;
  public documentId: number | null;
  public type: string;

  public documentName: string | null;
  public typeDescription: string | null;
  public documentLink: string | null;
  public fileDocument: FileDocumentMetadataModel | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
