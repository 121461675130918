import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProcessRevisionRegisterModel } from '../../../models/process-revision-register-model';
import { MatSort } from '@angular/material/sort';
import { ProcessService } from '../../../services/process.service';
import { MatTableUtility } from '../../../utility/mat-table-utility';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-process-revision-register',
  templateUrl: './process-revision-register.component.html',
  styleUrls: ['./process-revision-register.component.scss']
})

export class ProcessRevisionRegisterComponent implements OnInit {
  public isLoading: boolean = false;
  public isBusy: boolean = false;
  public processRevisionRegisterTableData = new MatTableDataSource<ProcessRevisionRegisterModel>([]);
  public processRevisionRegisterColumns: string[] = ['reference', 'processTitle', 'revision', 'revisionDate', 'draft', 'reason'];

  private pipe: DatePipe;
  private processRevisionSort: MatSort;

  @ViewChild('processRevisionRegisterTable', { read: MatSort, static: false }) set processRevisionRegisterSortValue(value: MatSort) {
    if (value) {
      this.processRevisionSort = value;
      this.processRevisionRegisterTableData.sort = this.processRevisionSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.processRevisionRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(public authService: AuthService,
    private processService: ProcessService) {
    this.pipe = new DatePipe('en');
    const defaultPredicate = this.processRevisionRegisterTableData.filterPredicate;
    this.processRevisionRegisterTableData.filterPredicate = (data, filter) => {
      const formattedDate = this.pipe.transform(data.revisionDate, 'dd/MM/yyyy');
      return (formattedDate != null && formattedDate.indexOf(filter) >= 0) || defaultPredicate(data, filter);
    }
  }

  public ngOnInit(): void {
    this.processRevisionRegisterTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.processService.getRevisionRegister().subscribe({
      next: (response: Array<ProcessRevisionRegisterModel>) => {
        this.processRevisionRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.processService.exportRevisionRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
