<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-12">
      <mat-card apperance="outlined" class="list-card">
        <mat-card-content>
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field apperance="fill" color="accent" subscriptSizing="dynamic">
                <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <table #auditLogTable mat-table [dataSource]="auditLogTableData" matSort matSortActive="changeUtc" matSortDirection="desc" matSortDisableClear multiTemplateDataRows>
                <ng-container matColumnDef="changeUtc">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed</th>
                  <td mat-cell *matCellDef="let auditLog">
                    {{auditLog.changeUtc | date: 'dd/MM/yyyy, H:mm'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="userDisplayName">User</th>
                  <td mat-cell *matCellDef="let auditLog">
                    {{auditLog.userDisplayName}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                  <td mat-cell *matCellDef="let auditLog">
                    {{auditLog.type}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="tableName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Table</th>
                  <td mat-cell *matCellDef="let auditLog">
                    {{splitCamelCase(auditLog.tableName)}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="rowColumn">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="primaryKey">Row</th>
                  <td mat-cell *matCellDef="let auditLog">
                    {{auditLog.primaryKey}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="before">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="oldValues">Before</th>
                  <td mat-cell *matCellDef="let auditLog">
                    {{auditLog.oldValues}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="after">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="newValues">After</th>
                  <td mat-cell *matCellDef="let auditLog">
                    {{auditLog.newValues}}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="auditLogColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: auditLogColumns"></tr>
                <tr *matNoDataRow>
                  <td [attr.colspan]="auditLogColumns.length" class="no-data-row">
                    No data found
                  </td>
                </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]"
                             showFirstLastButtons
                             color="accent">
              </mat-paginator>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  
</div>
