import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RoleGroupModel } from '../../../models/role-group-model';
import { RoleService } from '../../../services/role.service';
import { AuthService } from '../../../services/auth.service';
import { NgForm, FormsModule } from '@angular/forms';
import { ValidationService } from '../../../services/validation-service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-role-group-dialog',
    templateUrl: './role-group-dialog.component.html',
    styleUrls: ['./role-group-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, NgIf, MatFormField, MatLabel, MatInput, MatError, MatDialogActions, MatButton]
})
export class RoleGroupDialogComponent {
  public model: RoleGroupModel;
  public dialogTitle: string = "New Role Group";
  public isBusy: boolean = false;
  public isNew: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) private roleGroup: RoleGroupModel,
    private dialogRef: MatDialogRef<RoleGroupDialogComponent>,
    private roleService: RoleService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService) {
    this.model = roleGroup;

    if (this.model.roleGroupId && this.model.roleGroupId != 0) {
      this.dialogTitle = this.model.title;
      this.isNew = false
    }
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.roleService.saveRoleGroup(this.model).subscribe({
        next: (response: RoleGroupModel) => {
          this.model = response;
          this.toastr.success("Role Group saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/roles/${this.model.roleGroupId}`);
      this.toastr.info("Share link copied to clipboard")
  }

  public close() {
    this.dialogRef.close();
  }
}

