import { StandardSectionModel } from "./standard-section-model";

export class StandardModel {
  public standardId: number;
  public companyId: number;
  public title: string;
  public description: string | null;
  public createdUtc: Date;
  public modifiedUtc: Date;

  public standardSections: Array<StandardSectionModel> = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
