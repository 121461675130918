import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ProcessTaskModel } from '../../../../models/process-task-model';
import { ProcessTaskService } from '../../../../services/process-task.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf, NgFor } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-process-task-branch-dialog',
    templateUrl: './process-task-branch-dialog.component.html',
    styleUrls: ['./process-task-branch-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, NgIf, MatFormField, MatLabel, MatSelect, FormsModule, MatOption, NgFor, MatInput, MatDialogActions, MatButton]
})

export class ProcessTaskBranchDialogComponent {
  public processTasks: Array<ProcessTaskModel> = [];
  public processTaskToSave: ProcessTaskModel;
  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProcessTaskBranchDialogComponent>,
    private processTaskService: ProcessTaskService,
    private toastr: ToastrService,
  ) {
    this.processTasks = data.processTasks;
    this.processTaskToSave = data.selectedProcessTask;
  }

  public save() {
    this.isBusy = true;
    this.processTaskService.branchToProcessTask(this.processTaskToSave).subscribe({
      next: (response: ProcessTaskModel) => {
        this.processTaskToSave = response;
        this.isBusy = false;
        this.toastr.success("Task saved");
        this.dialogRef.close(this.processTaskToSave);
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public branchToProcessTaskSelectionChanged() {
    if (this.processTaskToSave.branchToProcessTaskId == null) {
      this.processTaskToSave.branchToReason = null;
    }
  }
}
