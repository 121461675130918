import { Component, OnInit } from '@angular/core';
import { WikiService } from '../../services/wiki.service';
import { TreeNodeModel } from '../../models/tree-node-model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTree, MatTreeNodeDef, MatTreeNode, MatNestedTreeNode, MatTreeNodeToggle, MatTreeNodeOutlet } from '@angular/material/tree';
import { AuthService } from '../../services/auth.service';
import { WikiGroupModel } from '../../models/wiki-group-model';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { WikiPageModel } from '../../models/wiki-page-model';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { MatDrawerContainer, MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
    selector: 'app-wiki',
    templateUrl: './wiki.component.html',
    styleUrl: './wiki.component.scss',
    standalone: true,
    imports: [MatCard, MatCardContent, NgIf, LoadingSpinnerComponent, MatDrawerContainer, MatDrawer, MatTree, MatTreeNodeDef, MatTreeNode, MatButton, MatIcon, MatNestedTreeNode, MatTreeNodeToggle, MatTreeNodeOutlet, MatDrawerContent, MatCheckbox, FormsModule, MatFormField, MatLabel, MatInput, QuillEditorComponent]
})
export class WikiComponent implements OnInit {
  public isLoading: boolean = true;
  public isEditMode: boolean = false;

  public currentPage: WikiPageModel | null;

  public wikiTreeControl: NestedTreeControl<TreeNodeModel>
  public wikiTreeDataSource = new MatTreeNestedDataSource<TreeNodeModel>();
  public currentNode: TreeNodeModel | undefined;

  constructor(private wikiService: WikiService,
    private authService: AuthService,
    private toastr: ToastrService) {
  }

  public ngOnInit(): void {
    this.wikiService.getWikiTree().subscribe({
      next: (response) => {
        this.initialiseTreeControl(response as TreeNodeModel[]);
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.currentUser!.isAdministrator;
  }

  private initialiseTreeControl(nodes: Array<TreeNodeModel>): void {
    this.wikiTreeControl = new NestedTreeControl<TreeNodeModel>(node => node.children);
    this.wikiTreeDataSource.data = nodes;

    if (this.currentPage) {
      const currentParentNode = this.wikiTreeDataSource.data.find(i => i.id == this.currentPage!.wikiGroupId);
      if (currentParentNode) {
        this.wikiTreeControl.expandDescendants(currentParentNode);
        if (currentParentNode.children && currentParentNode.children.length > 0) {
          this.currentNode = currentParentNode.children[0];
        }
      }
    }
  }

  public isActiveNode(node: TreeNodeModel): boolean {
    return !!this.currentNode && this.currentNode.id == node.id && this.currentNode.parentId == node.parentId;
  }

  public nodeHasChild(_: number, node: TreeNodeModel): boolean {
    return !!node.children && node.children.length > 0;
  }

  public navigateToPage(node: TreeNodeModel) {
    if (!node.id) {
      return;
    }

    this.wikiService.getPageById(node.id).subscribe({
      next: (response: WikiPageModel) => {
        this.currentPage = response;
        const currentParentNode = this.wikiTreeDataSource.data.find(i => i.id == this.currentPage!.wikiGroupId);
        if (currentParentNode) {
          this.wikiTreeControl.expandDescendants(currentParentNode);
          if (currentParentNode.children) {
            this.currentNode = currentParentNode.children.find(i => i.id == node.id);
          }
        }
      }
    });
  }

  public addGroup(): void {
    const title = prompt("Enter a title");

    if (title) {
      this.wikiService.addWikiGroup(title).subscribe({
        next: (response) => {
          const newNode = new TreeNodeModel(response);
          this.wikiTreeDataSource.data.push(newNode);
          this.wikiTreeDataSource.data = [...this.wikiTreeDataSource.data];
          this.wikiTreeControl.expand(newNode);
        },
      });
    } else {
      this.toastr.error("Title is required")
    }
  }

  public addPage(node: TreeNodeModel): void {
    if (!node.parentId) {
      return;
    }

    this.currentPage = new WikiPageModel();
    this.currentPage.wikiGroupId = node.parentId;
  }

  public save(): void {
    if (!this.currentPage) {
      return;
    }

    this.wikiService.saveWikiPage(this.currentPage).subscribe({
      next: (response: WikiPageModel) => {
        this.currentPage = response;
        this.toastr.success("Changes saved");
      }
    });
  }
}
