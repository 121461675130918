import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../../models/user-model';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  private route = inject(ActivatedRoute);
  public user: UserModel;
  public isLoading: boolean = true;;
  public isBusy = false;
  public companyId: number;

  constructor(public authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    private validationService: ValidationService) {
  }

  public ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    const companyId = Number(this.route.snapshot.paramMap.get('companyId'));

    if (id) {
      this.userService.getUser(id).subscribe({
        next: (response: UserModel) => {
          this.user = response;
          this.isLoading = false;
        }
      });
    } else {
      this.user = new UserModel();
      this.user.isEnabled = true;
      this.isLoading = false;
    }

    this.companyId = companyId;
  }

  public get isNew(): boolean {
    return this.user.userId == undefined || this.user.userId == null || this.user.userId == 0;
  }

  public save(form: NgForm) {
    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;
      this.user.companyId = this.companyId;
      this.userService.saveUser(this.user).subscribe({
        next: (response: UserModel) => {
          this.user = response;
          this.isBusy = false;
          this.toastr.success("User saved");
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public resendVerificationEmail() {
    this.userService.resendVerificationEmail(this.user).subscribe({
      next: () => {
        this.toastr.success("Verification email sent")
      }
    })
  }
}
