import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes, TitleStrategy, UrlMatchResult, UrlMatcher, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { authenticatedGuard } from './guards/authenticated.guard';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { DocumentListComponent } from './views/document/document-list.component';
import { RoleListComponent } from './views/roles/role-list.component';
import { ProcessComponent } from './views/processes/process/process.component';
import { ProcessListComponent } from './views/processes/process-list.component';
import { CompaniesComponent } from './views/admin/companies/companies.component';
import { CompanyComponent } from './views/admin/company/company.component';
import { UserComponent } from './views/admin/user/user.component';
import { AdminComponent } from './views/admin/admin.component';
import { CompanySelectComponent } from './views/company-select/company-select.component';
import { companyAdminGuard } from './guards/company-admin.guard';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { VerifyComponent } from './views/verify/verify.component';
import { unauthenticatedGuard } from './guards/unauthenticated.guard';
import { HomeComponent } from './views/home/home.component';
import { TemplatePageTitleStrategy } from './utility/template-page-title-strategy';
import { StandardListComponent } from './views/standard/standard-list.component';
import { CustomListComponent } from '../app/views/custom-lists/custom-list.component';
import { CategoryListComponent } from './views/categories/category-list.component';
import { DocumentRegisterComponent } from './views/document/document-register/document-register.component';
import { ProcessRegisterComponent } from './views/processes/process-register/process-register.component';
import { RoleRegisterComponent } from './views/roles/role-register/role-register.component';
import { ProcessRevisionRegisterComponent } from './views/processes/process-revision-register/process-revision-register.component';
import { CustomRegisterComponent } from './views/custom-lists/custom-register/custom-register.component';
import { ConfigurationComponent } from './views/admin/configuration/configuration.component';
import { ResourceListComponent } from './views/resources/resource-list.component';
import { ResourceRegisterComponent } from './views/resources/resource-register/resource-register.component';
import { StandardRegisterComponent } from './views/standard/standard-register/standard-register.component';
import { companyGuard } from './guards/company.guard';
import { AuditLogComponent } from './views/audit-log/audit-log.component';
import { ExceptionLogComponent } from './views/exception-log/exception-log.component';
import { adminGuard } from './guards/admin.guard';
import { WikiComponent } from './views/wiki/wiki.component';

const tokenUrlMatcher: UrlMatcher = (
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult => {
  const firstSegment = segments[0];
  if (firstSegment.path === "verify" || firstSegment.path === "reset") {
    const tokenSegments = segments.slice(1);
    const tokenPaths = tokenSegments.map(segment => segment.path);
    const mergedToken = tokenPaths.join('/');
    const tokenSegment: UrlSegment = new UrlSegment(mergedToken, { token: mergedToken });
    return ({ consumed: segments, posParams: { token: tokenSegment } });
  }
  return null as any;
};

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sign-in'
  },
  {
    path: 'sign-in',
    title: 'Sign In',
    component: SignInComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: 'wiki',
    title: 'Wiki',
    component: WikiComponent,
    canActivate: [authenticatedGuard],
  },
  {
    matcher: tokenUrlMatcher,
    title: 'Reset Password',
    component: VerifyComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    matcher: tokenUrlMatcher,
    title: 'Verify',
    component: VerifyComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: 'company-select',
    title: 'Company Select',
    component: CompanySelectComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'change-password',
    title: 'Change Password',
    component: ChangePasswordComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'exception-log',
    title: 'Exception Log',
    component: ExceptionLogComponent,
    canActivate: [authenticatedGuard, adminGuard],
  },
  {
    path: ':company',
    canActivate: [authenticatedGuard, companyGuard],
    children: [
      {
        path: '',
        title: 'Home',
        component: HomeComponent,
      },
      {
        path: 'documents',
        title: 'Documents',
        children: [
          { path: '', component: DocumentListComponent },
          { path: ':groupId', component: DocumentListComponent },
          { path: ':groupId/:documentId', component: DocumentListComponent}
        ]
      },
      {
        path: 'documents-register',
        title: 'Document Register',
        component: DocumentRegisterComponent,
      },
      {
        path: 'roles',
        title: 'Roles',
        children: [
          { path: '', component: RoleListComponent },
          { path: ':groupId', component: RoleListComponent },
          { path: ':groupId/:roleId', component: RoleListComponent }
        ]
      },
      {
        path: 'roles-register',
        title: 'Role Register',
        component: RoleRegisterComponent,
      },
      {
        path: 'processes',
        title: 'Processes',
        children: [
          { path: '', component: ProcessListComponent },
          { path: ':groupId', component: ProcessListComponent },
          { path: ':groupId/:id', component: ProcessComponent },
        ]
      },
      {
        path: 'process-register',
        title: 'Process Register',
        component: ProcessRegisterComponent,
      },
      {
        path: 'revision-register',
        title: 'Revision Register',
        component: ProcessRevisionRegisterComponent,
      },
      {
        path: 'standards',
        title: 'Standards',
        children: [
          { path: '', component: StandardListComponent },
          { path: ':groupId', component: StandardListComponent },
          { path: ':groupId/:standardId', component: StandardListComponent },
        ]
      },
      {
        path: 'standard-register',
        title: 'Standard Register',
        component: StandardRegisterComponent,
      },
      {
        path: 'customs',
        title: 'Customs',
        children: [
          { path: '', component: CustomListComponent },
          { path: ':groupId', component: CustomListComponent },
          { path: ':groupId/:customId', component: CustomListComponent },
        ]
      },
      {
        path: 'custom-register',
        title: 'Custom Register',
        component: CustomRegisterComponent,
      },
      {
        path: 'resources',
        title: 'Resources',
        children: [
          { path: '', component: ResourceListComponent },
          { path: ':groupId', component: ResourceListComponent },
          { path: ':groupId/:resourceId', component: ResourceListComponent },
        ]
      },
      {
        path: 'resource-register',
        title: 'Resource Register',
        component: ResourceRegisterComponent,
      },
      {
        path: 'categories',
        title: 'Categories',
        children: [
          { path: '', component: CategoryListComponent },
          { path: ':categoryId', component: CategoryListComponent },
        ]
      },
      {
        path: 'audit',
        title: 'Audit Log',
        component: AuditLogComponent,
        canActivate: [authenticatedGuard, companyAdminGuard],
      },
      {
        path: 'admin',
        title: 'Admin',
        component: AdminComponent,
        canActivate: [companyAdminGuard],
        children: [
          {
            path: 'configuration',
            title: 'Configuration',
            canActivate: [adminGuard],
            component: ConfigurationComponent,
          },
          {
            path: 'companies',
            title: 'All Companies',
            canActivate: [adminGuard],
            component: CompaniesComponent,
          },
          {
            path: 'company/:id',
            title: 'Company',
            component: CompanyComponent,
          },
          {
            path: 'company/:companyId/user/:id',
            title: 'User',
            component: UserComponent,
          },
        ],
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{
    provide: TitleStrategy,
    useClass: TemplatePageTitleStrategy,
  }]
})
export class AppRoutingModule {}
