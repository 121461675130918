import { FileDocumentModel } from "../models/file-document-model";

export class FileDocumentUtility {
  public static openFileDocument(response: any): void {
    let model = this.getFileDocumentModel(response);

    if (model) {
      const blobUrl = URL.createObjectURL(model.data);
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = blobUrl;

      /* We need to remove all " characters from the string to workaround issues were the file extension would be malformed:
      https://stackoverflow.com/questions/55341277/chrome-on-windows-adding-trailing-underscores-to-downloaded-files */
      let filename = model.filename.replaceAll('"', '');

      a.download = filename;
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 100);
    }
  }

  public static getBlobUrl(response: any): string | null {
    let model = this.getFileDocumentModel(response);

    if (model) {
      return URL.createObjectURL(model.data);
    }

    return null;
  }

  private static getFileDocumentModel(response: any): FileDocumentModel | null {
    let model;
    let contentDisposition = response.headers.get("content-disposition");
    let contentType = response.headers.get("content-type");

    if (!contentDisposition || !contentType) {
      return null;
    }

    let filenameMatch = contentDisposition.match(/filename=("?)(.+)\1/i);
    if (filenameMatch.length <= 2) {
      return null;
    }

    // TODO This will not work if the file name contains a ; character.
    var fileName = contentDisposition.split('filename=')[1].split(';')[0];

    model = new FileDocumentModel(fileName, new Blob([response.body], { type: contentType }));

    return model;
  }
}
