<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <mat-card appearance="outlined" class="list-card">
    <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
          <button mat-flat-button color="accent" class="button accent-button" (click)="export()" [disabled]="isBusy">Export</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-form-field appearance="fill" color="accent" subscriptSizing="dynamic">
            <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table #customRegisterTable mat-table [dataSource]="customRegisterTableData" matSort matSortActive="customGroupTitle" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="customGroup">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="customGroupTitle">Custom Group</th>
              <td mat-cell *matCellDef="let customData">{{customData.customGroupTitle}}</td>
            </ng-container>

            <ng-container matColumnDef="customName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="customName">Custom</th>
              <td mat-cell *matCellDef="let customData">{{customData.customName}}</td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="value">Value</th>
              <td mat-cell *matCellDef="let customData">{{customData.value}}</td>
            </ng-container>

            <ng-container matColumnDef="unitOfMeasure">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="unitOfMeasure">Unit of Measure</th>
              <td mat-cell *matCellDef="let customData">{{customData.unitOfMeasure}}</td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description</th>
              <td mat-cell *matCellDef="let customData">{{customData.description}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="customRegisterColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef="let customData; columns: customRegisterColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="customRegisterColumns.length" class="no-data-row">
                No customs found
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

