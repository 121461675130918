<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select Document</h2>
  </div>

  <mat-dialog-content>
    <app-autocomplete [label]="'Document'"
                      [placeholder]="'Select a Document'"
                      [(value)]="selectionId"
                      [options]="options"
                      [canBeNull]="false">
    </app-autocomplete>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()">Cancel</button>
  </mat-dialog-actions>
</section>
