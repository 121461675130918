import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomModel } from '../../../../models/custom-model';
import { CustomService } from '../../../../services/custom.service';
import { ProcessTaskCustomModel } from '../../../../models/process-task-custom-model';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../shared/loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AutocompleteComponent } from '../../../../shared/autocomplete/autocomplete.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-process-task-custom-dialog',
    templateUrl: './process-task-custom-dialog.component.html',
    styleUrls: ['./process-task-custom-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, AutocompleteComponent, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, MatButton]
})
export class ProcessTaskCustomDialogComponent {
  public customOptions: Array<CustomModel> = [];
  public selectedCustomIds: Array<number> = [];
  public isLoading: boolean = true;
  public value: string | null;
  public customSelectionId: number | null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProcessTaskCustomDialogComponent>,
    private customService: CustomService,
    private toastr: ToastrService) {
    this.selectedCustomIds = data.selectedCustomIds;
  }

  public ngOnInit() {
    this.customService.getCustoms().subscribe({
      next: (response: Array<CustomModel>) => {
        this.customOptions = response.filter(i => !this.selectedCustomIds.includes(i.customId));
        this.isLoading = false;
      }
    });
  }

  public save() {
    if (!this.customSelectionId) {
      this.toastr.error("Custom must be selected");
      return;
    }

    let model = new ProcessTaskCustomModel();
    model.customId = this.customSelectionId!;
    model.value = this.value;

    this.dialogRef.close(model);
  }

  public close() {
    this.dialogRef.close(null);
  }
}
