<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>Branch To Task</h2>
  </div>

  <mat-dialog-content>
    <div>
      <mat-form-field color="accent" *ngIf="processTasks.length > 0">
        <mat-label>Branch To Task</mat-label>
        <mat-select [(ngModel)]="processTaskToSave.branchToProcessTaskId" (ngModelChange)="branchToProcessTaskSelectionChanged()" [disabled]="isBusy">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let processTask of processTasks" [value]="processTask.processTaskId">{{ processTask.title }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Reason</mat-label>
        <textarea matInput
                  placeholder="Enter reason..."
                  name="branchToReason"
                  [disabled]="isBusy"
                  [(ngModel)]="processTaskToSave.branchToReason"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()" [disabled]="isBusy">Close</button>
    <button mat-raised-button color="accent" class="button accent-button" (click)="save()" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
