import { Component, OnInit, ViewChild } from "@angular/core";
import { AuditLogModel } from "../../models/audit-log-model";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { AuditLogService } from "../../services/audit-log-service";
import { MatTableUtility } from "../../utility/mat-table-utility";
import { Router } from "@angular/router";

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})

export class AuditLogComponent {
  public isLoading: boolean = true;
  public auditLogs: Array<AuditLogModel>;

  public auditLogTableData = new MatTableDataSource<AuditLogModel>([])
  public auditLogColumns: string[] = ['changeUtc', 'user', 'type', 'tableName', 'rowColumn', 'before', 'after']

  private auditLogSort: MatSort;

  @ViewChild('auditLogTable', { read: MatSort, static: false }) set auditLogSortValue(value: MatSort) {
    if (value) {
      this.auditLogSort = value;
      this.auditLogTableData.sort = value;
    }
  }

  @ViewChild(MatPaginator, { static: false }) set paginator(value: MatPaginator) {
    if (this.auditLogTableData) {
      this.auditLogTableData.paginator = value;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.auditLogTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private auditLogService: AuditLogService, private router: Router) {
  }

  public ngOnInit(): void {
    this.router.navigate(["/"]); // TODO temp 'disable' of page
    return;
    this.auditLogTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.auditLogService.getAllAuditLogs().subscribe({
      next: (response: Array<AuditLogModel>) => {
        this.auditLogs = response;
        this.refreshAuditLogTableData();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  private refreshAuditLogTableData(): void {
    this.auditLogTableData.data = this.auditLogs;
  }

  public splitCamelCase(text: string): string {
    return MatTableUtility.splitCamelCase(text);
  }
}
