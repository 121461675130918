import { Component, Inject } from "@angular/core";
import { ResourceModel } from "../../models/resource-model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ResourceService } from "../../services/resource.service";

@Component({
  selector: 'app-resource-select-dialog',
  templateUrl: './resource-select-dialog.component.html',
  styleUrls: ['./resource-select-dialog.component.scss']
})

export class ResourceSelectDialogComponent {
  public options: Array<ResourceModel>;
  public selectedResourceIds: Array<number> = [];
  public isLoading: boolean = true;

  private _selectionId: number | null;

  public get selectionId(): number | null {
    return this._selectionId;
  }

  public set selectionId(value: number | null) {
    if (this._selectionId != value) {
      this._selectionId = value;
      this.dialogRef.close(this._selectionId);
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ResourceSelectDialogComponent>,
    private resourceService: ResourceService) {
    this.selectedResourceIds = data.selectedResourceIds;
  }

  public ngOnInit() {
    this.resourceService.getResources().subscribe({
      next: (response: Array<ResourceModel>) => {
        this.options = response.filter(i => !this.selectedResourceIds.includes(i.resourceId));
        this.isLoading = false;
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
