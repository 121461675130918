import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { ExceptionLogModel } from "../models/exception-log-model";

@Injectable({
  providedIn: 'root'
})
export class ExceptionLogService {
  constructor(private apiService: ApiService) {
  }

  public getAllExceptionLogs(): Observable<Array<ExceptionLogModel>> {
    return this.apiService.get("exceptionLog/get-all")
      .pipe(map((response: Array<ExceptionLogModel>) => response.map(i => new ExceptionLogModel(i))));
  }

  public toggleHasBeenReviewed(id: number): Observable<void> {
    return this.apiService.post(`exceptionLog/toggle/${id}`, {});
  }
}
