import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProcessService } from '../../../../services/process.service';
import { ProcessRevisionModel } from '../../../../models/process-revision-model';
import { MatTableDataSource } from '@angular/material/table';
import { ProcessModel } from '../../../../models/process-model';
import { AuthService } from '../../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ProcessRevisionDialogComponent } from '../process-revision-dialog/process-revision-dialog.component';

@Component({
  selector: 'app-process-revision-list-dialog',
  templateUrl: './process-revision-list-dialog.component.html',
  styleUrls: ['./process-revision-list-dialog.component.scss']
})
export class ProcessRevisionListDialogComponent implements OnInit {
  public isLoading: boolean = true;
  public revisions: Array<ProcessRevisionModel> = [];
  public processRevisionsTableData = new MatTableDataSource<ProcessRevisionModel>([]);
  public processRevisionsColumns: string[] = ['number', 'date', 'reason'];

  constructor(@Inject(MAT_DIALOG_DATA) public process: ProcessModel,
    private dialogRef: MatDialogRef<ProcessRevisionListDialogComponent>,
    private processService: ProcessService,
    private authService: AuthService,
    private toastr: ToastrService,
    private dialog: MatDialog) {
  }

  public ngOnInit(): void {
    if (this.canEdit()) {
      this.processRevisionsColumns.push("controls");
    }

    this.processService.getProcessRevisionsForProcess(this.process.processId).subscribe({
      next: (response: Array<ProcessRevisionModel>) => {
        this.revisions = response;
        this.refreshProcessRevisionsTableData();
        this.isLoading = false;
      },
    });
  }

  public createProcessRevision(): void {
    if (!this.canEdit()) {
      return;
    }

    const processRevision = new ProcessRevisionModel();
    processRevision.processId = this.process.processId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...processRevision,
      },
      width: "60%",
    }

    const dialogRef = this.dialog.open(ProcessRevisionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessRevisionModel) => {
        if (result) {
          this.revisions.push(result);
          this.refreshProcessRevisionsTableData();
        }
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  private refreshProcessRevisionsTableData(): void {
    this.processRevisionsTableData.data = this.revisions.sort((a, b) => b.revisedDate!.getTime() - a.revisedDate!.getTime());
  }

  public editProcessRevision(revision: ProcessRevisionModel): void {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...revision,
      },
      width: "60%",
    };

    const dialogRef = this.dialog.open(ProcessRevisionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessRevisionModel) => {
        if (result) {
          const index = this.revisions.findIndex(i => i.processRevisionId == result.processRevisionId);
          this.revisions[index] = result;
          this.refreshProcessRevisionsTableData();
        }
      }
    });
  }

  public deleteProcessRevision(revision: ProcessRevisionModel): void {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Process Revision "${revision.number}"?`)) {
      return;
    }

    this.processService.deleteProcessRevision(revision.processRevisionId!).subscribe({
      next: () => {
        this.revisions.splice(this.revisions.indexOf(revision), 1);
        this.refreshProcessRevisionsTableData();
        this.toastr.success("Revision deleted");
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
