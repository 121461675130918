import { Component, Inject } from "@angular/core";
import { UserModel } from '../../../models/user-model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { UserSelectorComponent } from "../../../shared/user-selector/user-selector.component";
import { MatButton } from "@angular/material/button";

@Component({
    selector: 'app-user-role-dialog',
    templateUrl: './user-role-dialog.component.html',
    styleUrls: ['./user-role-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, UserSelectorComponent, MatDialogActions, MatButton]
})
export class UserRoleDialogComponent {
  public users: Array<UserModel> = [];
  public selectedUserIds: Array<number> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserRoleDialogComponent>) {
    this.selectedUserIds = data.selectedUserIds;
  }

  public onUserSelected(user: UserModel | null) {
    if (user && user.userId) {
      this.dialogRef.close(user.userId);
    }
  }

  public close() {
    this.dialogRef.close(null);
  }
}
