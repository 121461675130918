import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { RoleModel } from '../../../models/role-model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CustomService } from '../../../services/custom.service';
import { RoleService } from '../../../services/role.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { RoleTitleUtility } from '../../../utility/role-title-utility';
import { CustomModel } from '../../../models/custom-model';
import { ValidationService } from '../../../services/validation-service';
import { NgForm, FormsModule } from '@angular/forms';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ProcessModel } from '../../../models/process-model';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { Observable, forkJoin } from 'rxjs';
import { ProcessService } from '../../../services/process.service';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AutocompleteComponent } from '../../../shared/autocomplete/autocomplete.component';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatTabGroup, MatTab, MatFormField, MatLabel, MatInput, MatError, AutocompleteComponent, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatDialogActions]
})
export class CustomDialogComponent implements OnInit {
  public model: CustomModel;
  public dialogTitle: string = "New Custom";
  public processesTableData = new MatTableDataSource<ProcessModel>([]);
  public processesColumns: string[] = ['title'];
  public isBusy: boolean = false;
  public isLoading: boolean = true;
  public roles: Array<RoleModel> = [];

  private processSort: MatSort;

  @ViewChild('customProcessTable', { read: MatSort, static: false }) set processSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processesTableData.sort = this.processSort;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) private custom: CustomModel,
    private dialogRef: MatDialogRef<CustomDialogComponent>,
    private customService: CustomService,
    private roleService: RoleService,
    private processService: ProcessService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService,
    private router: Router) {
    this.model = custom;

    if (!this.isNew) {
      this.dialogTitle = this.model.title;
    }
  }

  public ngOnInit(): void {
    let sources: Array<Observable<any>> = [
      this.roleService.getRoles()
    ];

    if (!this.isNew) {
      sources.push(this.processService.getCustomProcesses(this.model.customId));
    }

    forkJoin(sources).subscribe({
      next: (response) => {
        this.roles = response[0] as RoleModel[];
        if (!this.isNew) {
          this.processesTableData.data = response[1] as ProcessModel[];
        }
        this.isLoading = false;
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.customId == null ||
      this.model.customId == undefined ||
      this.model.customId == 0;
  }

  public get processesTabTitle(): string {
    return `Associated Processes (${this.processesTableData.data.length})`;
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.customService.saveCustom(this.model).subscribe({
        next: (response: CustomModel) => {
          this.model = response;
          this.toastr.success("Custom saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public openProcess(process: ProcessModel) {
    this.close();
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${process.processGroupId}/${process.processId}`);
  }

  public getRoleTitle(roleId: number | null): string | null {
    return RoleTitleUtility.getRoleTitle(this.roles, roleId);
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/customs/${this.model.customGroupId}/${this.model.customId}`)
      this.toastr.info("Share link copied to clipboard.")
  }

  public close() {
    this.dialogRef.close();
  }
}
