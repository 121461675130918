export class UserVerificationModel {
  public accountId: number;
  public accountTokenId: number;
  public password: string;
  public passwordConfirm: string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
