import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  constructor(public authService: AuthService,
    private router: Router) {
  }

  public ngOnInit(): void {
    if (!this.authService.currentUser?.isAdministrator) {
      this.router.navigate([`/${this.authService.currentUser?.companyName}/admin/company`,
        this.authService.currentUser?.companyId]);
    }
  }
}
