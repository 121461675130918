import { Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import { UserModel } from '../../models/user-model';
import { NgIf } from '@angular/common';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';

@Component({
    selector: 'app-user-selector',
    templateUrl: './user-selector.component.html',
    styleUrl: './user-selector.component.scss',
    standalone: true,
    imports: [NgIf, AutocompleteComponent]
})
export class UserSelectorComponent {
  @Input() canBeNull: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() placeholder: string = "";
  @Input() label: string = "";
  @Input() value: UserModel | null;
  @Input() selectedUserIds: Array<number> = [];
  @Output() valueChange = new EventEmitter<UserModel | null>;

  public isInitialised: boolean = false;
  public users: Array<UserModel> = [];

  private valueDiffer: KeyValueDiffer<string, any>;

  constructor(private differs: KeyValueDiffers,
    private userService: UserService) {
  }

  public ngOnInit(): void {
    if (!this.value) {
      this.value = new UserModel();
    }

    this.valueDiffer = this.differs.find(this.value).create();

    this.userService.getUsers().subscribe({
      next: (response: Array<UserModel>) => {
        this.users = response.filter(i => (i.userId === this.value?.userId) ||
          (!this.selectedUserIds.includes(i.userId!) && i.isEnabled)
        );
        this.isInitialised = true;
      }
    });
  }

  public ngDoCheck(): void {
    const changes = this.valueDiffer.diff(this.value!);
    if (changes) {
      let user = this.users.find(i => i.userId == this.value?.userId);

      if (user) {
        this.value = user;
        this.valueChange.emit(this.value);
      }
    }
  }
}
