export class ProcessAssociationModel {
  public processId: number;
  public processGroupId: number;
  public processTitle: string;
  public processReference: string;
  public association: string;

  public get associationReferenceAndTitle() {
    return this.processReference ? this.processReference + ' - ' + this.processTitle : this.processTitle;
  }

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
