<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <mat-tab-group color="accent">
      <mat-tab label="Details" [disabled]="isBusy">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <mat-form-field color="accent">
            <mat-label>Title</mat-label>
            <input matInput
                   placeholder="Enter title..."
                   name="title"
                   [(ngModel)]="model.title"
                   #title="ngModel"
                   [disabled]="isBusy">
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label>Description</mat-label>
            <textarea matInput
                      placeholder="Enter description..."
                      name="description"
                      [disabled]="isBusy"
                      [(ngModel)]="model.description"></textarea>
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label>Notes</mat-label>
            <textarea matInput
                      placeholder="Enter notes..."
                      name="notes"
                      [disabled]="isBusy"
                      [(ngModel)]="model.notes"></textarea>
          </mat-form-field>

          <div class="checkbox-form-field">
            <mat-checkbox [(ngModel)]="model.isHeader" (ngModelChange)="isHeaderChanged()" [disabled]="isBusy">Is Header?</mat-checkbox>
          </div>

          <app-autocomplete [label]="'Responsible'"
                            [placeholder]="'Select a Role'"
                            [(value)]="model.responsibleRoleId"
                            [options]="roles"
                            [isDisabled]="model.isHeader || isBusy">
          </app-autocomplete>

          <app-multi-autocomplete [label]="authService.currentUser!.companyAccountableText"
                                  [placeholder]="'Select a Role'"
                                  [options]="roles"
                                  [selectedOptions]="model.accountableRoles"
                                  [isDisabled]="model.isHeader || isBusy">
          </app-multi-autocomplete>

          <app-multi-autocomplete [label]="'Consulted'"
                                  [placeholder]="'Select a Role'"
                                  [options]="roles"
                                  [selectedOptions]="model.consultedRoles"
                                  [isDisabled]="model.isHeader || isBusy">
          </app-multi-autocomplete>

          <app-multi-autocomplete [label]="'Informed'"
                                  [placeholder]="'Select a Role'"
                                  [options]="roles"
                                  [selectedOptions]="model.informedRoles"
                                  [isDisabled]="model.isHeader || isBusy">
          </app-multi-autocomplete>

          <div class="checkbox-form-field">
            <mat-checkbox [(ngModel)]="model.isMilestone"
                          [disabled]="model.isHeader || isBusy"
                          (ngModelChange)="isMilestoneChanged()">
              Is Milestone?
            </mat-checkbox>
          </div>

          <mat-form-field color="accent">
            <mat-label>Milestone Reason</mat-label>
            <textarea matInput
                      [disabled]="!model.isMilestone || isBusy"
                      placeholder="Enter milestone reason..."
                      name="milestoneReason"
                      [(ngModel)]="model.milestoneReason"></textarea>
          </mat-form-field>
        </div>
      </mat-tab>

      <mat-tab label="{{documentsTabTitle}}" [disabled]="isNew || isBusy">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <button mat-raised-button color="accent" class="button accent-button" (click)="addProcessTaskDocument()">Add</button>

          <table #processTaskDocumentTable mat-table [dataSource]="processTaskDocumentsTableData" matSort matSortActive="documentName" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="documentName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let processTaskDocument">
                <button mat-flat-button
                        class="button table-button"
                        (click)="editProcessTaskDocument(processTaskDocument)">
                  {{processTaskDocument.documentName}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="typeDescription">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
              <td mat-cell *matCellDef="let processTaskDocument">
                {{processTaskDocument.typeDescription}}
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let processTaskDocument" class="mat-cell-end">
                <button mat-icon-button color="accent" (click)="deleteProcessTaskDocument(processTaskDocument)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processTaskDocumentsColumns"></tr>
            <tr mat-row
                *matRowDef="let processTaskDocument; columns: processTaskDocumentsColumns;"></tr>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="{{subProcessesTabTitle}}" [disabled]="isNew || isBusy">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <button mat-raised-button color="accent" class="button accent-button" (click)="addProcessTaskProcess()">Add</button>

          <table #processTaskProcessesTable mat-table [dataSource]="processTaskProcessesTableData" matSort matSortActive="displayName" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="displayName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let subProcess">
                {{subProcess.displayName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let subProcess" class="mat-cell-end">
                <button mat-icon-button color="accent" (click)="deleteProcessTaskProcess(subProcess)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processTaskProcessesColumns"></tr>
            <tr mat-row
                *matRowDef="let subProcess; columns: processTaskProcessesColumns;"></tr>
          </table>
        </div>
      </mat-tab>

      <mat-tab label="{{standardsTabTitle}}" [disabled]="isNew || isBusy">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <button mat-raised-button color="accent" class="button accent-button" (click)="addProcessTaskStandardSection()">Add</button>

          <table #processTaskStandardSectionsTable mat-table [dataSource]="processTaskStandardSectionsTableData" matSort matSortActive="standardDisplayName" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="standardDisplayName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="standardDisplayName">Standard Title</th>
              <td mat-cell *matCellDef="let standardSection">
                {{standardSection.standardDisplayName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="displayName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="standardSectionDisplayName">Section Title</th>
              <td mat-cell *matCellDef="let standardSection">
                {{standardSection.standardSectionDisplayName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let standardSection" class="mat-cell-end">
                <button mat-icon-button color="accent" (click)="deleteProcessTaskStandardSection(standardSection)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processTaskStandardSectionsColumns"></tr>
            <tr mat-row
                *matRowDef="let standardSection; columns: processTaskStandardSectionsColumns;"></tr>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="{{resourcesTabTitle}}" [disabled]="isNew || isBusy">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <button mat-raised-button color="accent" class="button accent-button" (click)="addProcessTaskResource()">Add</button>

          <table #processTaskResourcesTable mat-table [dataSource]="processTaskResourcesTableData" matSort matSortActive="resourceDisplayName" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="displayName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="resourceDisplayName">Title</th>
              <td mat-cell *matCellDef="let resource">
                {{resource.resourceDisplayName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="quantity">Quantity</th>
              <td mat-cell *matCellDef="let resource">
                {{resource.quantity}}
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let resource" class="mat-cell-end">
                <button mat-icon-button color="accent" (click)="deleteProcessTaskResource(resource)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processTaskResourcesColumns"></tr>
            <tr mat-row
                *matRowDef="let resource; columns: processTaskResourcesColumns"></tr>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="{{customsTabTitle}}" [disabled]="isNew || isBusy" *ngIf="authService.currentUser!.companyHasCustoms">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <button mat-raised-button color="accent" class="button accent-button" (click)="addProcessTaskCustom()">Add</button>

          <table #processTaskCustomsTable mat-table [dataSource]="processTaskCustomsTableData" matSort matSortActive="customDisplayName" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="displayName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="customDisplayName">Title</th>
              <td mat-cell *matCellDef="let custom">
                {{custom.customDisplayName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="value">Value</th>
              <td mat-cell *matCellDef="let custom">
                {{custom.value}}
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let custom" class="mat-cell-end">
                <button mat-icon-button color="accent" (click)="deleteProcessTaskCustom(custom)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processTaskCustomsColumns"></tr>
            <tr mat-row
                *matRowDef="let custom; columns: processTaskCustomsColumns;"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()" [disabled]="isBusy">Close</button>
    <button mat-raised-button color="accent" class="button accent-button" (click)="save()" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
