import { CustomModel } from "./custom-model";

export class CustomGroupModel {
  public customGroupId: number;
  public companyId: number;
  public title: string;
  public description: string | null;
  public createdUtc: Date;
  public modifiedUtc: Date;

  public customs: Array<CustomModel> = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
