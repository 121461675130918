import { Component, Inject } from "@angular/core";
import { ProcessAuditModel } from "../../../../models/process-audit-model";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { ProcessModel } from "../../../../models/process-model";
import { AuthService } from "../../../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ProcessAuditTaskModel } from "../../../../models/process-audit-task-model";
import { ProcessAuditService } from "../../../../services/process-audit.service";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";
import { RoleModel } from "../../../../models/role-model";
import { NgIf, DatePipe } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../../shared/loading-spinner/loading-spinner.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: 'app-process-audit-list-dialog',
    templateUrl: './process-audit-list-dialog.component.html',
    styleUrls: ['./process-audit-list-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, MatButton, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatDialogActions, DatePipe]
})

export class ProcessAuditListDialogComponent {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public processAudits: Array<ProcessAuditModel> = [];
  public processAuditTableData = new MatTableDataSource<ProcessAuditModel>([]);
  public processAuditColumns: string[] = ['processAuditDate', 'auditedBy', 'status', 'controls'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { process: ProcessModel, roles: RoleModel[] },
    private dialogRef: MatDialogRef<ProcessAuditListDialogComponent>,
    public authService: AuthService,
    private processAuditService: ProcessAuditService,
    private toastr: ToastrService,
    private router: Router) {
  }

  public ngOnInit(): void {
    this.processAuditService.getAuditsForProcess(this.data.process.processId).subscribe({
      next: (response: Array<ProcessAuditModel>) => {
        this.processAudits = response;
        this.refreshProcessAuditTableData();
        this.isLoading = false;
      }
    });
  }

  private refreshProcessAuditTableData() {
    this.processAuditTableData.data = this.processAudits;
  }

  public beginAudit(): void {
    this.isBusy = true;

    let processAudit = new ProcessAuditModel();
    processAudit.processId = this.data.process.processId;
    processAudit.auditedByUser = this.authService.currentUser!;
    processAudit.date = new Date(); // TODO
    processAudit.status = ProcessAuditStatusConstant.Open;
    processAudit.processName = this.data.process.displayValue;
    processAudit.processDescription = this.data.process.description;
    processAudit.processClauses = this.data.process.clauses;

    let auditTasks = this.data.process.processTasks.map(i => {
      const auditTask = new ProcessAuditTaskModel()
      auditTask.headerSequenceNumber = i.headerSequenceNumber;
      auditTask.subSequenceNumber = i.subSequenceNumber;
      auditTask.title = i.title;
      const role = this.data.roles.find(role => role.roleId === i.responsibleRoleId);
      auditTask.responsibleRole = role ? role.title : null;
      auditTask.observation = null;

      let descriptionString = document.getElementById(`${i.headerSequenceNumber}-${i.subSequenceNumber}`)?.innerHTML;
      auditTask.description = descriptionString ?? null;

      return auditTask;
    });

    processAudit.processAuditTasks = auditTasks;

    this.processAuditService.saveProcessAudit(processAudit).subscribe({
      next: (response: ProcessAuditModel) => {
        this.isBusy = false;
        this.toastr.success("Audit Saved");
        this.router.navigateByUrl(
          `/${this.authService.currentUser!.companyName}/processes/${this.data.process.processGroupId}/${this.data.process.processId}/${response.processAuditId}`);

        this.close();
      }, error: () => {
        this.isBusy = false;
      }
    });
  }

  public openAudit(audit: ProcessAuditModel) {
    this.router.navigateByUrl(
      `/${this.authService.currentUser!.companyName}/processes/${this.data.process.processGroupId}/${this.data.process.processId}/${audit.processAuditId}`);

    this.close();
  }

  public getStatusDescription(statusCode: string): string {
    const status = ProcessAuditStatusConstant.ValuesWithDescriptions.find(i => i.value === statusCode);
    return status ? status.description : statusCode;
  }

  public deleteAudit(processAudit: ProcessAuditModel) {
    if (!confirm(`Are you sure you want to delete this Audit?`)) {
      return;
    }

    this.processAuditService.deleteProcessAudit(processAudit.processAuditId).subscribe({
      next: () => {
        this.processAudits.splice(this.processAudits.indexOf(processAudit), 1);
        this.refreshProcessAuditTableData();
        this.toastr.success(`Process Audit deleted`);
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
