<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="col-sm-12">
    <h2 class="col-sm-3">Configuration</h2>

    <mat-form-field color="accent">
      <mat-label>Attachable File Types</mat-label>
      <input matInput
             name="attachableFileTypes"
             [(ngModel)]="model.attachableFileTypes">
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Attachable File Max Size In MB</mat-label>
      <input matInput
             type="number"
             name="attachableFileMaxSizeMB"
             [(ngModel)]="model.attachableFileMaxSizeMB">
    </mat-form-field>

    <button mat-flat-button color="accent" class="button accent-button" (click)="save()">Save</button>
  </div>
</div>

