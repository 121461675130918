<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Audits</h2>
  </div>

  <mat-dialog-content>
    <button *ngIf="authService.canCurrentUserEdit"
            mat-flat-button
            color="accent"
            class="button accent-button"
            (click)="beginAudit()">
      Begin Audit
    </button>
    <table #processAuditTable mat-table [dataSource]="processAuditTableData">
      <ng-container matColumnDef="processAuditDate">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let audit">
          <button mat-flat-button
                  (click)="openAudit(audit)"
                  class="button table-button">
            {{ audit.date | date: 'dd/MM/yyyy' }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="auditedBy">
        <th mat-header-cell *matHeaderCellDef>Audited By</th>
        <td mat-cell *matCellDef="let audit">
          {{ audit.auditedByUser.displayName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let audit">
          {{ getStatusDescription(audit.status) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let audit" class="mat-cell-end">
          <button *ngIf="authService.canCurrentUserEdit"
                  mat-icon-button
                  color="accent"
                  matTooltip="Delete"
                  (click)="deleteAudit(audit)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="processAuditColumns"></tr>
      <tr mat-row *matRowDef="let audit; columns: processAuditColumns;"></tr>
      <tr *matNoDataRow>
        <td [attr.colspan]="processAuditColumns.length" class="no-data-row">
          No data found
        </td>
      </tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Close</button>
  </mat-dialog-actions>
</section>
