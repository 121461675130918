<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

  <div *ngIf="!isLoading">
    <mat-card appearance="outlined" class="list-card">
      <mat-card-content>
        <div class="row">
          <div class="col-sm-12">
            <button mat-flat-button color="accent" class="button accent-button" (click)="export()" [disabled]="isBusy">Export</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field appearance="fill" color="accent" subscriptSizing="dynamic">
              <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table #resourceRegisterTable mat-table [dataSource]="resourceRegisterTableData" matSort matSortActive="resourceGroupTitle" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="resourceGroup">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="resourceGroupTitle">Resource Group</th>
                <td mat-cell *matCellDef="let resourceData">{{resourceData.resourceGroupTitle}}</td>
              </ng-container>

              <ng-container matColumnDef="resourceName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="resourceName">Resource</th>
                <td mat-cell *matCellDef="let resourceData">{{resourceData.resourceName}}</td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="quantity">Quantity</th>
                <td mat-cell *matCellDef="let resourceData">{{resourceData.quantity}}</td>
              </ng-container>

              <ng-container matColumnDef="unitOfMeasure">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="unitOfMeasure">Unit of Measure</th>
                <td mat-cell *matCellDef="let resourceData">{{resourceData.unitOfMeasure}}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description</th>
                <td mat-cell *matCellDef="let resourceData">{{resourceData.description}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="resourceRegisterColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let resourceData; columns: resourceRegisterColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="resourceRegisterColumns.length">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
