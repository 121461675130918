import { ProcessRevisionModel } from "./process-revision-model";

export class ProcessRegisterModel {
  public processGroupTitle: string;
  public reference: string;
  public processTitle: string;
  public latestRevision: ProcessRevisionModel | null;
  public draft: boolean = false;
  public owner: string | null;
  public approver: string | null;
  public processGroupId: string | null;
  public processId: string | null;

  public get revision() {
    if (this.latestRevision) {
      return this.latestRevision.number;
    }

    return null;
  }

  public get revisionDate(): Date | null {
    if (this.latestRevision && this.latestRevision.revisedDate) {
      return this.latestRevision.revisedDate;
    }

    return null;
  }

  constructor(data?: any) {
    if (data) {
      if (data.latestRevision) {
        data.latestRevision = new ProcessRevisionModel(data.latestRevision);
      }

      Object.assign(this, data);
    }
  }
}
