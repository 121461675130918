import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { TreeNodeModel } from "../models/tree-node-model";
import { WikiPageModel } from "../models/wiki-page-model";

@Injectable({
  providedIn: 'root'
})
export class WikiService {
  constructor(private apiService: ApiService) {
  }

  public getPageById(wikiPageId: number): Observable<WikiPageModel> {
    return this.apiService.get(`wiki/get-page/${wikiPageId}`)
      .pipe(map(i => new WikiPageModel(i)));
  }

  public getWikiTree(): Observable<Array<TreeNodeModel>> {
    return this.apiService.get("wiki/get-wiki-tree")
      .pipe(map((response: Array<TreeNodeModel>) => response.map(i => new TreeNodeModel(i))));
  }

  public addWikiGroup(title: string): Observable<TreeNodeModel> {
    return this.apiService.post("wiki/add-wiki-group", { title })
      .pipe(map(response => new TreeNodeModel(response)));
  }

  public saveWikiPage(model: WikiPageModel): Observable<WikiPageModel> {
    return this.apiService.post("wiki/save-page", model)
      .pipe(map(i => new WikiPageModel(i)));
  }
}
