import { Component } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import { ConfigurationModel } from '../../../models/configuration-model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent {
  public isLoading: boolean = true;
  public model: ConfigurationModel;

  constructor(public configurationService: ConfigurationService,
    private toastr: ToastrService) {
  }

  public ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe({
      next: (response: ConfigurationModel) => {
        this.model = response;
        this.isLoading = false;
      }
    });
  }

  public save() {
    this.configurationService.saveConfiguration(this.model).subscribe({
      next: (response: ConfigurationModel) => {
        this.model = response;
          this.toastr.success("Saved");
      }
    });
  }
}
