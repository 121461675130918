export class MultiFactorAuthenticationSubmitModel {
  public emailAddress: string | null = null;
  public code: string | null = null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
