import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { CustomModel } from "../models/custom-model";
import { CustomGroupModel } from "../models/custom-group-model";
import { CustomRegisterModel } from "../models/custom-register-model";

@Injectable({
  providedIn: 'root'
})
export class CustomService {
  constructor(private apiService: ApiService) {
  }

  public getCustomGroups(): Observable<Array<CustomGroupModel>> {
    return this.apiService.get("custom/group/get-all")
      .pipe(map((response: Array<CustomGroupModel>) => response.map(i => new CustomGroupModel(i))));
  }

  public getCustoms(): Observable<Array<CustomModel>> {
    return this.apiService.get("custom/get-all")
      .pipe(map((response: Array<CustomModel>) => response.map(i => new CustomModel(i))));
  }

  public saveCustom(model: CustomModel): Observable<CustomModel> {
    return this.apiService.post("custom", model)
      .pipe(map(i => new CustomModel(i)));
  }

  public deleteCustom(id: number): Observable<void> {
    return this.apiService.post(`custom/delete/${id}`, {});
  }

  public saveCustomGroup(model: CustomGroupModel): Observable<CustomGroupModel> {
    return this.apiService.post("custom/group", model)
      .pipe(map(i => new CustomGroupModel(i)));
  }

  public deleteCustomGroup(id: number): Observable<void> {
    return this.apiService.post(`custom/group/delete/${id}`, {});
  }

  public getCustomsForProcess(processId: number): Observable<Array<CustomModel>> {
    return this.apiService.get(`custom/process/${processId}`)
      .pipe(map((response: Array<CustomModel>) => response.map(i => new CustomModel(i))));
  }

  public saveProcessCustom(processId: number, customId: number): Observable<CustomModel> {
    return this.apiService.post(`custom/process/save/${processId}/${customId}`, {})
      .pipe(map(i => new CustomModel(i)));
  }

  public deleteProcessCustom(processId: number, customId: number): Observable<void> {
    return this.apiService.post(`custom/process/delete/${processId}/${customId}`, {});
  }

  public getCustomRegister(): Observable<Array<CustomRegisterModel>> {
    return this.apiService.get("custom/register")
      .pipe(map((response: Array<CustomRegisterModel>) => response.map(i => new CustomRegisterModel(i))));
  }

  public exportRegister(): Observable<any> {
    return this.apiService.postExportRaw(`custom/register-export`, {});
  }

  public getCustomById(customId: number): Observable<CustomModel> {
    return this.apiService.get(`custom/get/${customId}`)
      .pipe(map(response => new CustomModel(response)));
  }
}
