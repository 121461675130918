export class ProcessAuditStatusConstant {
  public static readonly Open: string = "O";
  public static readonly PendingClosure: string = "P";
  public static readonly Closed: string = "C";

  public static readonly ValuesWithDescriptions = [
    { value: this.Open, description: "Open" },
    { value: this.PendingClosure, description: "Pending Closure" },
    { value: this.Closed, description: "Closed" },
  ];
}
