import { Component, Inject } from '@angular/core';
import { DocumentModel } from '../../models/document-model';
import { DocumentService } from '../../services/document.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-document-select-dialog',
    templateUrl: './document-select-dialog.component.html',
    styleUrls: ['./document-select-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton]
})
export class DocumentSelectDialogComponent {
  public options: Array<DocumentModel>;
  public selectedDocumentIds: Array<number> = [];
  public isLoading: boolean = true;

  private _selectionId: number | null;

  public get selectionId(): number | null {
    return this._selectionId;
  }

  public set selectionId(value: number | null) {
    if (this._selectionId != value) {
      this._selectionId = value;
      this.dialogRef.close(this._selectionId);
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DocumentSelectDialogComponent>,
    private documentService: DocumentService) {
    this.selectedDocumentIds = data.selectedDocumentIds;
  }

  public ngOnInit() {
    this.documentService.getDocuments().subscribe({
      next: (response: Array<DocumentModel>) => {
        this.options = response.filter(i => !this.selectedDocumentIds.includes(i.documentId));
        this.isLoading = false;
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
