export class MatTableUtility {
  public static customSortingDataAccessor = (item: any, path: string) => {
    // Handle nested object property sorting
    const value = path.split('.').reduce((accumulator: any, key: string) => {
      return accumulator ? accumulator[key] : undefined;
    }, item);

    // Remove case sensitivity
    if (typeof value === 'string') {
      return value.toLocaleLowerCase();
    }

    return value;
  };

  public static splitCamelCase(text: string): string {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
