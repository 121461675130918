export class RoleRegisterModel {
  public roleGroupTitle: string;
  public title: string;
  public reportsTo: string | null;
  public description: string | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
