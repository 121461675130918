export class ProcessTaskStandardSectionModel {
  public processTaskStandardSectionId: number;
  public processTaskId: number;
  public standardSectionId: number

  public standardDisplayName: string;
  public standardSectionDisplayName: string | null;

  public get standardDescription() {
    return this.standardDisplayName + ' - ' + this.standardSectionDisplayName;
  }
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
