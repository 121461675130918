import { Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  constructor(private toastr: ToastrService) {
  }

  public isFormValid(form: NgForm): boolean {
    if (form.invalid) {
      for (let controlName in form.controls) {
        if (form.controls[controlName].invalid) {
          this.toastr.error("One or more validation errors occurred.");
          return false;
        }
      }
    }

    return true;
  }
}
