<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Resources</h2>
  </div>

  <mat-dialog-content>
    <button *ngIf="canEdit()"
            mat-flat-button
            color="accent"
            class="button accent-button"
            (click)="createProcessResource()">
      Add Resource
    </button>
    <table #processResourceTable mat-table [dataSource]="processResourceTableData" matSort matSortActive="resourceGroupTitle" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="resourceGroupTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="resourceGroupTitle">Resource Group</th>
        <td mat-cell *matCellDef="let resource">
          {{resource.resourceGroupTitle}}
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let resource">
          {{ resource.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let resource" class="mat-cell-end">
          <button mat-icon-button
                  color="accent"
                  (click)="deleteProcessResource(resource)"
                  matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="processResourceColumns"></tr>
      <tr mat-row *matRowDef="let resource; columns: processResourceColumns;"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Close</button>
  </mat-dialog-actions>
</section>
