import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, mergeMap } from "rxjs";
import { AuthService } from "../services/auth.service";
import { AuthApiService } from "../services/auth-api.service";
import { TokenModel } from "../models/token-model";
import { AuthenticatedResponseModel } from "../models/authenticated-response-model";

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private authApi: AuthApiService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.isUserAuthenticated() && this.authService.token && this.authService.refreshToken && !request.url.endsWith("api/auth/refresh")) {
      var model = new TokenModel();
      model.accessToken = this.authService.token;
      model.refreshToken = this.authService.refreshToken;

      return from(this.authApi.refresh(model)).pipe(
        mergeMap((response: AuthenticatedResponseModel) => {
          if (response && response.token && response.refreshToken) {
            this.authService.setToken(response.token);
            this.authService.setRefreshToken(response.refreshToken);
            const cloned = request.clone({
              headers: request.headers.set("Authorization", "Bearer " + this.authService.token ?? ""),
            });
            return next.handle(cloned);
          } else {
            return next.handle(request);
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
