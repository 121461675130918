import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { DocumentGroupModel } from '../../models/document-group-model';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentModel } from '../../models/document-model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DocumentDialogComponent } from './document-dialog/document-dialog.component';
import { DocumentGroupDialogComponent } from './document-group-dialog/document-group-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})

export class DocumentListComponent implements OnInit {
  private route = inject(ActivatedRoute);
  public isLoading: boolean;

  public documentGroups: Array<DocumentGroupModel> = [];
  public documentGroupTableData = new MatTableDataSource<DocumentGroupModel>([]);
  public documentGroupColumns: string[] = ['title', 'controls'];
  public selectedDocumentGroup: DocumentGroupModel | null = null;

  public documentTableData = new MatTableDataSource<DocumentModel>([]);
  public documentColumns: string[] = ['documentName', 'controls'];
  private documentGroupSort: MatSort;
  private documentSort: MatSort;

  @ViewChild('documentGroupTable', { read: MatSort, static: false }) set documentGroupSortValue(value: MatSort) {
    if (value) {
      this.documentGroupSort = value;
      this.documentGroupTableData.sort = this.documentGroupSort;
    }
  };

  @ViewChild('documentTable', { read: MatSort, static: false }) set documentSortValue(value: MatSort) {
    if (value) {
      this.documentSort = value;
      this.documentTableData.sort = this.documentSort;
    }
  };

  constructor(private documentService: DocumentService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public authService: AuthService) {
  }

  public ngOnInit(): void {
    let groupIdParam = Number(this.route.snapshot.paramMap.get("groupId"));
    let documentIdParam = Number(this.route.snapshot.paramMap.get("documentId"));

    this.isLoading = true;

    this.documentGroupTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;
    this.documentTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.documentService.getDocumentGroups().subscribe({
      next: (response: Array<DocumentGroupModel>) => {
        this.documentGroups = response;
        this.refreshDocumentGroupTableData();

        if (groupIdParam != 0 && documentIdParam != 0) {
          const documentGroup = this.documentGroups.find(group => group.documentGroupId === groupIdParam);
          const document = documentGroup?.documents.find(document => document.documentId == documentIdParam);
          if (documentGroup && document) {
            this.selectedDocumentGroup = documentGroup;
            this.refreshDocumentTableData();
            this.editDocument(document);
          }
        } else if (groupIdParam != 0 && documentIdParam == 0) {
          const documentGroup = this.documentGroups.find(group => group.documentGroupId === groupIdParam);
          if (documentGroup) {
            this.selectedDocumentGroup = documentGroup;
            this.refreshDocumentTableData();
            this.editDocumentGroup(documentGroup);
          }
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    })
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public selectDocumentGroup(documentGroup: DocumentGroupModel) {
    if (this.selectedDocumentGroup != documentGroup) {
      this.selectedDocumentGroup = documentGroup;
      this.refreshDocumentTableData();
    }
  }

  public createDocumentGroup() {
    if (!this.canEdit()) {
      return;
    }

    const documentGroup = new DocumentGroupModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...documentGroup,
      }
    };

    const dialogRef = this.dialog.open(DocumentGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: DocumentGroupModel) => {
        if (result) {
          this.documentGroups.push(result);
          this.selectedDocumentGroup = result;
          this.refreshDocumentGroupTableData();
          this.refreshDocumentTableData();
        }
      }
    });
  }

  public editDocumentGroup(documentGroup: DocumentGroupModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...documentGroup,
      }
    };

    const dialogRef = this.dialog.open(DocumentGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: DocumentGroupModel) => {
        if (result) {
          const index = this.documentGroups.findIndex(i => i.documentGroupId == result.documentGroupId);
          this.documentGroups[index] = result;
          this.refreshDocumentGroupTableData();
          if (this.selectedDocumentGroup?.documentGroupId == result.documentGroupId) {
            this.selectedDocumentGroup = result;
            this.refreshDocumentTableData();
          }
        }
      }
    });
  }

  public deleteDocumentGroup(documentGroup: DocumentGroupModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Document Group "${documentGroup.title}"?`)) {
      return;
    }

    this.documentService.deleteDocumentGroup(documentGroup.documentGroupId).subscribe({
      next: () => {
        this.documentGroups.splice(this.documentGroups.indexOf(documentGroup), 1);
        this.refreshDocumentGroupTableData();
        this.toastr.success(`Document Group "${documentGroup.title}" deleted`);
      }
    });
  }

  public createDocument() {
    if (!this.canEdit()) {
      return;
    }

    if (!this.selectedDocumentGroup) {
      return;
    }

    const document = new DocumentModel();
    document.documentGroupId = this.selectedDocumentGroup.documentGroupId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...document,
      }
    };

    const dialogRef = this.dialog.open(DocumentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: DocumentModel) => {
        if (result) {
          this.selectedDocumentGroup!.documents.push(result);
          this.refreshDocumentTableData();
        }
      }
    });
  }

  public editDocument(document: DocumentModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...document,
      }
    }

    const dialogRef = this.dialog.open(DocumentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: DocumentModel) => {
        if (result) {
          const documentGroup = this.documentGroups.find(i => i.documentGroupId == result.documentGroupId);
          if (documentGroup) {
            const index = documentGroup.documents.findIndex(i => i.documentId == result.documentId);
            documentGroup.documents[index] = result;
            this.refreshDocumentTableData();
          }
        }
      }
    });
  }

  public deleteDocument(document: DocumentModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Document "${document.documentName}"?`)) {
      return;
    }

    this.documentService.deleteDocument(document.documentId).subscribe({
      next: () => {
        const documentGroup = this.documentGroups.find(i => i.documentGroupId == document.documentGroupId);
        if (documentGroup) {
          documentGroup.documents.splice(documentGroup.documents.indexOf(document), 1);
          this.refreshDocumentTableData();
          this.toastr.success(`Document "${document.documentName}" deleted`);
        }
      }
    });
  }

  private refreshDocumentGroupTableData(): void {
    this.documentGroupTableData.data = this.documentGroups;
  }

  private refreshDocumentTableData(): void {
    if (this.selectedDocumentGroup) {
      this.documentTableData.data = this.selectedDocumentGroup.documents;
    } else {
      this.documentGroupTableData.data = [];
    }
  }
}
