import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatTabNav, MatTabLink, MatTabNavPanel } from '@angular/material/tabs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: true,
    imports: [MatCard, MatCardContent, MatTabNav, NgIf, MatTabLink, RouterLinkActive, RouterLink, MatTabNavPanel, RouterOutlet]
})
export class AdminComponent {
  constructor(public authService: AuthService,
    private router: Router) {
  }

  public ngOnInit(): void {
    if (!this.authService.currentUser?.isAdministrator) {
      this.router.navigate([`/${this.authService.currentUser?.companyName}/admin/company`,
        this.authService.currentUser?.companyId]);
    }
  }
}
