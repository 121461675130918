import { AutocompleteOptionModel } from "./autocomplete-option-model";

export class ResourceModel extends AutocompleteOptionModel {
  public resourceGroupId: number;
  public resourceId: number;
  public title: string;
  public unitOfMeasure: string | null;
  public quantity: string | null;
  public description: string | null;
  public ownerRoleId: number | null;
  public createdUtc: Date;
  public modifiedUtc: Date;
  public resourceGroupTitle: string;

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.resourceId;
      this.displayValue = data.title;
      Object.assign(this, data);
    }
  }
}
