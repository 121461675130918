export class ProcessExportModel {
  public processId: number;
  public timeZone: string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
