<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{dialogTitle()}}</h2>
  </div>

  <mat-dialog-content>
    <mat-tab-group color="accent">
      <mat-tab label="Details" [disabled]="isBusy">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <div class="readonly-section">
            <label class="readonly-label">
              Title
            </label>
            <div class="readonly-content">
              {{processAuditTask.title}}
            </div>
          </div>

          <div class="readonly-section">
            <label class="readonly-label">
              Responsible User
            </label>
            <div class="readonly-content">
              {{ processAuditTask.responsibleRole }}
            </div>
          </div>

          <div class="readonly-section">
            <label class="readonly-label">
              Description
            </label>
            <div class="readonly-content" [innerHtml]="processAuditTask.description"></div>
          </div>

          <mat-form-field color="accent" *ngIf="authService.canCurrentUserEdit && isProcessAuditOpen()">
            <mat-label>Evidence</mat-label>
            <textarea matInput
                      placeholder="Enter Evidence..."
                      name="evidence"
                      [(ngModel)]="processAuditTask.evidence"
                      [disabled]="isBusy"></textarea>
          </mat-form-field>

          <div class="readonly-section" *ngIf="!authService.canCurrentUserEdit || !isProcessAuditOpen()">
            <label class="readonly-label">
              Evidence
            </label>
            <div class="readonly-content">
              {{ processAuditTask.evidence }}
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Observations ({{ observationCount }})">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <div class="col-sm-12">
            <button *ngIf="authService.canCurrentUserEdit && isProcessAuditOpen()" mat-raised-button color="accent" class="button accent-button" (click)="addNonConformance(processAuditTask, false)">Add Observation</button>
            <table #processAuditTaskObservationTable mat-table [dataSource]="processAuditTaskObservationTableData">
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let observation">
                  <button mat-button class="button table-button" (click)="editProcessAuditTaskNonConformance(observation)">{{observation.title}}</button>
                </td>
              </ng-container>

              <ng-container matColumnDef="reportedBy">
                <th mat-header-cell *matHeaderCellDef>Reported By</th>
                <td mat-cell *matCellDef="let observation">{{observation.reportedByUser.displayName}}</td>
              </ng-container>

              <ng-container matColumnDef="reportedDate">
                <th mat-header-cell *matHeaderCellDef>Reported Date</th>
                <td mat-cell *matCellDef="let observation">{{observation.reportedDate | date: 'dd/MM/yyyy'}}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let observation">{{observation.description}}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let observation">{{ getStatusDescription(observation.status) }}</td>
              </ng-container>

              <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let observation">
                  <button *ngIf="authService.canCurrentUserEdit && isProcessAuditOpen()"
                          mat-icon-button
                          color="accent"
                          matTooltip="Delete"
                          (click)="deleteProcessAuditTaskNonConformance(observation)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processAuditTaskObservationColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let observation; columns: processAuditTaskObservationColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="processAuditTaskObservationColumns.length" class="no-data-row">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Non Conformances ({{ nonConformanceCount }})">
        <div class="mat-tab-content-container dialog-mat-tab-content-container">
          <div class="col-sm-12">
            <button *ngIf="authService.canCurrentUserEdit && isProcessAuditOpen()" mat-raised-button color="accent" class="button accent-button" (click)="addNonConformance(processAuditTask, true)">Add Non Conformance</button>
            <table #processAuditTaskNonConformanceTable mat-table [dataSource]="processAuditTaskNonConformanceTableData">
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let nonConformance">
                  <button mat-button class="button table-button" (click)="editProcessAuditTaskNonConformance(nonConformance)">{{nonConformance.title}}</button>
                </td>
              </ng-container>

              <ng-container matColumnDef="reportedBy">
                <th mat-header-cell *matHeaderCellDef>Reported By</th>
                <td mat-cell *matCellDef="let nonConformance">{{nonConformance.reportedByUser.displayName}}</td>
              </ng-container>

              <ng-container matColumnDef="reportedDate">
                <th mat-header-cell *matHeaderCellDef>Reported Date</th>
                <td mat-cell *matCellDef="let nonConformance">{{nonConformance.reportedDate | date: 'dd/MM/yyyy'}}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let nonConformance">{{nonConformance.description}}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let nonConformance">{{ getStatusDescription(nonConformance.status) }}</td>
              </ng-container>

              <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let nonConformance">
                  <button *ngIf="authService.canCurrentUserEdit && isProcessAuditOpen()"
                          mat-icon-button
                          color="accent"
                          matTooltip="Delete"
                          (click)="deleteProcessAuditTaskNonConformance(nonConformance)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processAuditTaskNonConformanceColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let nonConformance; columns: processAuditTaskNonConformanceColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="processAuditTaskNonConformanceColumns.length" class="no-data-row">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()" [disabled]="isBusy">Close</button>
    <button *ngIf="authService.canCurrentUserEdit && isProcessAuditOpen()"
            mat-raised-button
            color="accent"
            class="button accent-button"
            (click)="save()">Save
    </button>
  </mat-dialog-actions>
</section>
