export class TreeNodeModel {
  public id: number | null;
  public parentId: number | null;
  public title: string;
  public children: Array<TreeNodeModel> = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
