
export class UserRoleModel {
  public userRoleId: number;
  public userId: number;
  public roleId: number;

  public userDisplayName: string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
