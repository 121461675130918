import { Component, Inject, KeyValueDiffer, KeyValueDiffers, OnInit } from "@angular/core";
import { ProcessAuditTaskNonConformanceActionModel } from "../../../../models/process-audit-task-non-conformance-action-model";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../../services/auth.service";
import { ValidationService } from "../../../../services/validation-service";
import { NgForm, FormsModule } from "@angular/forms";
import { FileDocumentService } from "../../../../services/file-document-service";
import { FileDocumentMetadataModel } from "../../../../models/file-document-metadata-model";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from "@angular/material/table";
import { FileDocumentUtility } from "../../../../utility/file-document-utility";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";
import { ProcessAuditTaskNonConformanceService } from "../../../../services/process-audit-task-non-conformance.service";
import { ProcessAuditTaskNonConformanceActionRejectDialogComponent } from "../process-audit-task-non-conformance-action-reject-dialog/process-audit-task-non-conformance-action-reject-dialog.component";
import { NgIf, NgFor, DatePipe } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../../shared/loading-spinner/loading-spinner.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatTabGroup, MatTab } from "@angular/material/tabs";
import { MatFormField, MatLabel, MatError, MatHint, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { UserSelectorComponent } from "../../../../shared/user-selector/user-selector.component";
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from "@angular/material/datepicker";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: 'app-process-audit-task-non-conformance-action-dialog',
    templateUrl: './process-audit-task-non-conformance-action-dialog.component.html',
    styleUrls: ['./process-audit-task-non-conformance-action-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatTabGroup, MatTab, MatFormField, MatLabel, MatInput, MatError, UserSelectorComponent, MatDatepickerInput, MatHint, MatDatepickerToggle, MatSuffix, MatDatepicker, MatButton, NgFor, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatDialogActions, DatePipe]
})

export class ProcessAuditTaskNonConformanceActionDialogComponent implements OnInit {
  private formData: FormData | null;
  private modelDiffer: KeyValueDiffer<string, any>;

  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public model: ProcessAuditTaskNonConformanceActionModel;
  public fileDocuments: Array<FileDocumentMetadataModel> = [];
  public fileDocumentsTableData = new MatTableDataSource<FileDocumentMetadataModel>([]);
  public fileDocumentsTableColumns: string[] = ['fileName', 'controls'];
  public fileNamesPendingUpload: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public processAuditNonConformanceAction: ProcessAuditTaskNonConformanceActionModel,
    private dialogRef: MatDialogRef<ProcessAuditTaskNonConformanceActionDialogComponent>,
    private processAuditTaskNonConformanceService: ProcessAuditTaskNonConformanceService,
    private toastr: ToastrService,
    private fileDocumentService: FileDocumentService,
    public authService: AuthService,
    public validationService: ValidationService,
    private differs: KeyValueDiffers,
    private dialog: MatDialog) {
    this.receiveProcessAuditTaskNonConformanceAction(processAuditNonConformanceAction);

    if (this.isNew) {
      this.model.status = ProcessAuditStatusConstant.Open;
    }
  }

  public ngOnInit(): void {
    if (!this.isNew) {
      this.fileDocumentService.getFileDocuments(this.model.processAuditTaskNonConformanceActionId).subscribe({
        next: (response: Array<FileDocumentMetadataModel>) => {
          this.fileDocuments = response;
          this.refreshFileDocumentsTableData();
          this.isLoading = false;
        }
      });
    } else {
      this.isLoading = false;
    }
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.processAuditTaskNonConformanceActionId == null ||
      this.model.processAuditTaskNonConformanceActionId == undefined ||
      this.model.processAuditTaskNonConformanceActionId == 0;
  }

  public isActionOpen(): boolean {
    return this.model.status === ProcessAuditStatusConstant.Open;
  }

  public canSubmitForClosure(): boolean {
    return this.model.closureApproverUser?.userId != null &&
      this.model.status === ProcessAuditStatusConstant.Open;
  }

  public canClose(): boolean {
    return this.authService.currentUser?.userId === this.model.closureApproverUser?.userId &&
      this.model.status === ProcessAuditStatusConstant.PendingClosure;
  }

  public save(form: NgForm) {
    if (!this.authService.canCurrentUserEdit) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.processAuditTaskNonConformanceService.saveProcessAuditTaskNonConformanceAction(this.model).subscribe({
        next: (response: ProcessAuditTaskNonConformanceActionModel) => {
          this.receiveProcessAuditTaskNonConformanceAction(response);
          if (this.formData) {
            this.fileDocumentService.uploadFileDocuments(this.model, this.formData).subscribe({
              next: () => {
                this.isBusy = false;
                this.toastr.success("Action saved");
                this.dialogRef.close(this.model);
              },
              error: () => {
                this.isBusy = false;
              }
            });
          } else {
            this.isBusy = false;
            this.toastr.success("Action saved");
            this.dialogRef.close(this.model);
          }
        }, error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public trySubmitActionForClosure() {
    if (!confirm("Are you sure you want to submit this Action for closure? Changes cannot be made once submitted")) {
      return;
    }

    if (this.modelDiffer.diff(this.model)) {
      // TODO this feels a bit crap
      this.processAuditTaskNonConformanceService.saveProcessAuditTaskNonConformanceAction(this.model).subscribe({
        next: (response: ProcessAuditTaskNonConformanceActionModel) => {
          this.receiveProcessAuditTaskNonConformanceAction(response);
          if (this.formData) {
            this.fileDocumentService.uploadFileDocuments(this.model, this.formData).subscribe({
              next: () => {
                this.submitActionForClosure();
              },
            });
          } else {
            this.submitActionForClosure();
          }
        },
      });
    }
  }

  private submitActionForClosure() {
    this.processAuditTaskNonConformanceService.submitNonConformanceActionForClosure(this.model.processAuditTaskNonConformanceActionId).subscribe({
      next: () => {
        this.model.status = ProcessAuditStatusConstant.PendingClosure;
        this.toastr.success("Action submitted for closure");
        this.dialogRef.close(this.model);
      }
    });
  }

  public rejectClosure() {
    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceActionRejectDialogComponent, {
      data: this.processAuditNonConformanceAction
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.model.status = ProcessAuditStatusConstant.Open
        }
      }
    });
  }

  public closeNonConformanceAction() {
    if (!confirm("Are you sure you want to close this action?")) {
      return;
    }

    this.processAuditTaskNonConformanceService.closeNonConformanceAction(this.model.processAuditTaskNonConformanceActionId).subscribe({
      next: () => {
        this.model.status = ProcessAuditStatusConstant.Closed;
        this.toastr.success("Action closed");
        this.dialogRef.close(this.model);
      }
    });
  }

  public uploadFile(files: FileList | null) {
    if (!this.authService.canCurrentUserEdit || !files || files.length == 0) {
      return;
    }

    this.fileNamesPendingUpload = [];

    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (file.name.includes(";")) {
        this.toastr.error("File name cannot contain the ; character");
        return;
      }

      this.fileNamesPendingUpload.push(file.name);
      formData.append('file', file, file.name);
    }

    this.formData = formData;
  }

  public downloadFileDocument(fileDocument: FileDocumentMetadataModel): void {
    this.fileDocumentService.downloadFileDocument(fileDocument).subscribe({
      next: (response: any) => {
        FileDocumentUtility.openFileDocument(response);
      }
    });
  }

  public deleteFileDocument(fileDocument: FileDocumentMetadataModel): void {
    if (!confirm(`Are you sure you want to delete document ${fileDocument.fileName}`)) {
      return;
    }

    this.fileDocumentService.deleteFileDocument(this.model, fileDocument.fileDocumentId).subscribe({
      next: () => {
        this.fileDocuments.splice(this.fileDocuments.indexOf(fileDocument), 1);
        this.refreshFileDocumentsTableData();
        this.toastr.success("Document deleted");
      }
    });
  }

  private receiveProcessAuditTaskNonConformanceAction(model: ProcessAuditTaskNonConformanceActionModel): void {
    this.model = structuredClone(model);
    this.modelDiffer = this.differs.find(this.model).create();
  }

  private refreshFileDocumentsTableData() {
    this.fileDocumentsTableData.data = this.fileDocuments;
  }

  public close() {
    this.dialogRef.close();
  }
}
