import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChangePasswordModel } from '../../models/change-password-model';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public model: ChangePasswordModel = {
    userId: undefined,
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  };

  constructor(private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router) {
  }

  public changePassword = (form: NgForm) => {
    if (form.valid) {
      this.model.userId = this.authService.currentUser?.userId;
      this.userService.changePassword(this.model).subscribe({
        next: (response: any) => {
          this.toastr.success("Password Reset");
          this.router.navigate(["/"]);
        }
      });
    }
  }
}
