<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>MFA</h2>
  </div>

  <mat-dialog-content>
    <mat-form-field color="accent">
      <mat-label>Code</mat-label>
      <input matInput
             placeholder="Enter code..."
             name="code"
             [(ngModel)]="code">
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()">Close</button>
    <button mat-raised-button color="accent" class="button accent-button" (click)="submit()">Submit</button>
  </mat-dialog-actions>
</section>
