import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuditLogModel } from "../models/audit-log-model";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {
  constructor(private apiService: ApiService) {
  }

  public getAllAuditLogs(): Observable<Array<AuditLogModel>> {
    return this.apiService.get("auditLog/get-all")
      .pipe(map((response: Array<AuditLogModel>) => response.map(i => new AuditLogModel(i))));
  }
}
