import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { FileDocumentMetadataModel } from "../models/file-document-metadata-model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileDocumentService {
  constructor(private apiService: ApiService) {
  }

  public getFileDocument(document: FileDocumentMetadataModel): Observable<any> {
    return this.apiService.postExportRaw(`fileDocument/get-document`, document);
  }
}
