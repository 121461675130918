import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly uri = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public get(endpoint: string, params?: any): Observable<any> {
    return this.http.get(this.uri + endpoint, {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Authorization": "Bearer " + (this.authService.token ?? ""),
      }),
      params: params,
    });
  }

  public post(endpoint: string, body: any): Observable<any> {
    return this.http.post(this.uri + endpoint, body, {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Authorization": "Bearer " + (this.authService.token ?? ""),
      })
    });
  }

  public postExportRaw(endpoint: string, body: any): Observable<any> {
    return this.http.post(this.uri + endpoint,body, {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Authorization": "Bearer " + (this.authService.token ?? ""),
      }),
      responseType: "arraybuffer",
      observe: "response",
    });
  }
}
