import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { RoleModel } from '../../../models/role-model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { RoleService } from '../../../services/role.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ProcessService } from '../../../services/process.service';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { Router } from '@angular/router';
import { RoleTitleUtility } from '../../../utility/role-title-utility';
import { DocumentModel } from '../../../models/document-model';
import { DocumentService } from '../../../services/document.service';
import { Observable, config, forkJoin } from 'rxjs';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { FileDocumentService } from '../../../services/file-document-service';
import { NgForm, FormsModule } from '@angular/forms';
import { ValidationService } from '../../../services/validation-service';
import { UserRoleModel } from '../../../models/user-role-model';
import { UserRoleDialogComponent } from '../user-role-dialog/user-role-dialog.component';
import { ProcessAssociationModel } from '../../../models/process-association-model';
import { DocumentDialogComponent } from '../../document/document-dialog/document-dialog.component';
import { DocumentAssociationModel } from '../../../models/document-association-model';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AutocompleteComponent } from '../../../shared/autocomplete/autocomplete.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-role-dialog',
    templateUrl: './role-dialog.component.html',
    styleUrls: ['./role-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatTabGroup, MatTab, MatFormField, MatLabel, MatInput, MatError, AutocompleteComponent, MatButton, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIconButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatDialogActions]
})
export class RoleDialogComponent implements OnInit {
  public model: RoleModel;
  public dialogTitle: string = "New Role";
  public roles: Array<RoleModel> = [];
  public isLoading: boolean = true;
  public isBusy: boolean = false;

  public processesTableData = new MatTableDataSource<ProcessAssociationModel>([]);
  public processesColumns: string[] = ['title', 'association'];
  private processSort: MatSort;

  public documentTableData = new MatTableDataSource<DocumentAssociationModel>([]);
  public documentColumns: string[] = ['title', 'association'];
  private documentSort: MatSort;

  public userRolesTableData = new MatTableDataSource<UserRoleModel>([]);
  public userRolesColumns: string[] = ['displayName', 'controls'];
  private userSort: MatSort;

  @ViewChild('roleProcessesTable', { read: MatSort, static: false }) set processSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processesTableData.sort = this.processSort;
    }
  };

  @ViewChild('documentsTable', { read: MatSort, static: false }) set documentSortValue(value: MatSort) {
    if (value) {
      this.documentSort = value;
      this.documentTableData.sort = this.documentSort;
    }
  };

  @ViewChild('userRoleTable', { read: MatSort, static: false }) set userSortValue(value: MatSort) {
    if (value) {
      this.userSort = value;
      this.userRolesTableData.sort = this.userSort;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) private role: RoleModel,
    private dialogRef: MatDialogRef<RoleDialogComponent>,
    private roleService: RoleService,
    private toastr: ToastrService,
    public authService: AuthService,
    private processService: ProcessService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private router: Router,
    private fileDocumentService: FileDocumentService,
    private validationService: ValidationService) {
    this.model = role;

    if (!this.isNew) {
      this.dialogTitle = this.model.title;
    }
  }

  public ngOnInit(): void {
    let sources: Array<Observable<any>> =
      [this.roleService.getRoles()
    ];

    if (!this.isNew) {
      let existingSources: Array<Observable<any>> = [
        this.processService.getRoleProcesses(this.model.roleId),
        this.documentService.getRoleDocuments(this.model.roleId),
        this.roleService.getRoleUsers(this.model.roleId)
      ];

      sources = sources.concat(existingSources);
    }

    forkJoin(sources).subscribe({
      next: (response) => {
        this.roles = response[0] as RoleModel[];
        if (!this.isNew) {
          this.processesTableData.data = response[1] as ProcessAssociationModel[];
          this.documentTableData.data = response[2] as DocumentAssociationModel[];
          this.userRolesTableData.data = response[3] as UserRoleModel[];
        }
        this.isLoading = false;
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.roleId == null ||
      this.model.roleId == undefined ||
      this.model.roleId == 0;
  }

  public get processesTabTitle(): string {
    return `Associated Processes (${this.processesTableData.data.length})`;
  }

  public get documentTabTitle(): string {
    return `Associated Documents (${this.documentTableData.data.length})`;
  }

  public get userTabTitle(): string {
    return `Users (${this.userRolesTableData.data.length})`
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.roleService.saveRole(this.model).subscribe({
        next: (response: RoleModel) => {
          this.model = response;
          this.toastr.success("Role saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public openProcess(process: ProcessAssociationModel) {
    this.close();
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${process.processGroupId}/${process.processId}`);
  }

  public getRoleTitle(roleId: number | null): string | null {
    return RoleTitleUtility.getRoleTitle(this.roles, roleId);
  }

  public close() {
    this.dialogRef.close();
  }

  public openDocument(documentId: number) {
    this.documentService.getDocumentById(documentId).subscribe({
      next: (document: DocumentModel) => {
        const dialogConfig: MatDialogConfig = {
          data: { ...document }
        };

        const dialogRef = this.dialog.open(DocumentDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
          next: (result: DocumentModel | null) => {
            if (result) {
              let documentToUpdate = this.documentTableData.data.find(i => i.documentId == result.documentId);
              if (documentToUpdate) {
                documentToUpdate.documentIdentifier = result.identifier;
                documentToUpdate.documentTitle = result.title;
              }
            }
          }
        });
      }
    });
  }

  public downloadDocument(document: DocumentModel): void {
    if (document.fileDocument) {
      this.fileDocumentService.downloadFileDocument(document.fileDocument).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public addUserRole() {
    if (!this.canEdit()) {
      return;
    }

    const selectedUserIds = this.model.userRoles.map(i => i.userId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedUserIds: selectedUserIds,
      },
      width: "60%",
    };

    const dialogRef = this.dialog.open(UserRoleDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: number | null) => {
        if (result) {
          const userRole = new UserRoleModel();
          userRole.userId = result;
          userRole.roleId = this.model.roleId;

          this.roleService.saveUserRole(userRole).subscribe({
            next: (response: UserRoleModel) => {
              this.model.userRoles.push(response);
              this.refreshUserRoleTableData();
              this.toastr.success(`User Added`);
            }
          });
        }
      }
    });
  }

  public deleteUserRole(userRole: UserRoleModel) {
    this.roleService.deleteUserRole(userRole.userRoleId).subscribe({
      next: () => {
        this.model.userRoles.splice(this.model.userRoles.indexOf(userRole), 1);
        this.refreshUserRoleTableData();
        this.toastr.success(`User deleted`);
      }
    });
  }

  private refreshUserRoleTableData(): void {
    this.userRolesTableData.data = this.model.userRoles;
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/roles/${this.model.roleGroupId}/${this.model.roleId}`);
      this.toastr.info("Share link copied to clipboard.")
  }
}
