export class ExceptionLogModel {
  public exceptionLogId: number;
  public endPoint: string | null;
  public path: string | null;
  public message: string | null;
  public stackTrace: string | null;
  public userId: number | null;
  public loggedUtc: Date | null;
  public hasBeenReviewed: boolean | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
