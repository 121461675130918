<mat-card appearance="outlined" class="list-card">
  <mat-card-content>
    <div>
      <nav mat-tab-nav-bar color="accent" [tabPanel]="adminTabPanel" animationDuration="0ms">
        <a mat-tab-link
           *ngIf="authService.currentUser?.isAdministrator"
           routerLink="/{{authService.currentUser!.companyName}}/admin/configuration"
           routerLinkActive #companiesRoute="routerLinkActive"
           [active]="companiesRoute.isActive">Configuration</a>
        <a mat-tab-link
           *ngIf="authService.currentUser?.isAdministrator"
           routerLink="/{{authService.currentUser!.companyName}}/admin/companies"
           routerLinkActive #companiesRoute="routerLinkActive"
           [active]="companiesRoute.isActive">Companies</a>
        <a mat-tab-link
           routerLink="/{{authService.currentUser!.companyName}}/admin/company/{{this.authService.currentUser!.companyId}}"
           routerLinkActive #companyRoute="routerLinkActive"
           [active]="companyRoute.isActive">Company</a>
      </nav>

      <mat-tab-nav-panel #adminTabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </mat-card-content>
</mat-card>
