<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Standard Sections</h2>
  </div>

  <mat-dialog-content>
    <button *ngIf="canEdit()"
            mat-flat-button
            color="accent"
            class="button accent-button"
            (click)="createProcessStandard()">
      Add Standard Section
    </button>
    <table #processStandardsTable mat-table [dataSource]="processStandardsTableData" matSort matSortActive="standardTitle" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="standardTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="standardTitle">Standard Title</th>
        <td mat-cell *matCellDef ="let standard">
          {{ standard.standardTitle }}
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="title">Section Title</th>
        <td mat-cell *matCellDef="let standard">
          {{ standard.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let standard" class="mat-cell-end">
          <button mat-icon-button
                  color="accent"
                  (click)="deleteProcessStandard(standard)"
                  matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="processStandardsColumns"></tr>
      <tr mat-row *matRowDef="let standard; columns: processStandardsColumns;"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Close</button>
  </mat-dialog-actions>
</section>
