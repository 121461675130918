import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { CategoryModel } from "../../../models/category-model";
import { CategoryService } from "../../../services/category.service";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../services/auth.service";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from "@angular/material/table";
import { ProcessModel } from "../../../models/process-model";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { ProcessService } from "../../../services/process.service";
import { Observable, forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { DocumentModel } from "../../../models/document-model";
import { DocumentService } from "../../../services/document.service";
import { FileDocumentService } from "../../../services/file-document-service";
import { FileDocumentUtility } from "../../../utility/file-document-utility";
import { ValidationService } from "../../../services/validation-service";
import { NgForm, FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatTabGroup, MatTab } from "@angular/material/tabs";
import { MatFormField, MatLabel, MatError } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatButton } from "@angular/material/button";

@Component({
    selector: 'app-category-dialog',
    templateUrl: './category-dialog.component.html',
    styleUrls: ['./category-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatTabGroup, MatTab, MatFormField, MatLabel, MatInput, MatError, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatDialogActions]
})
export class CategoryDialogComponent implements OnInit {
  public dialogTitle: string = "New Category";
  public isBusy: boolean = false;
  public isLoading: boolean = true;

  public processesTableData = new MatTableDataSource<ProcessModel>([]);
  public processesColumns: string[] = ['title'];
  private processSort: MatSort;

  public documentTableData = new MatTableDataSource<DocumentModel>([]);
  public documentColumns: string[] = ['title'];
  private documentSort: MatSort;

  @ViewChild('categoryProcessesTable', { read: MatSort, static: false }) set processSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processesTableData.sort = this.processSort;
    }
  }

  @ViewChild('documentsTable', { read: MatSort, static: false }) set documentSortValue(value: MatSort) {
    if (value) {
      this.documentSort = value;
      this.documentTableData.sort = this.documentSort;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public model: CategoryModel,
    private dialogRef: MatDialogRef<CategoryDialogComponent>,
    private categoryService: CategoryService,
    private toastr: ToastrService,
    private authService: AuthService,
    private processService: ProcessService,
    private documentService: DocumentService,
    private router: Router,
    private fileDocumentService: FileDocumentService,
    private validationService: ValidationService) {
    if (!this.isNew) {
      this.dialogTitle = this.model.name
    }
  }

  public ngOnInit(): void {
    if (this.isNew) {
      this.isLoading = false;
      return;
    }

    let sources: Array<Observable<any>> = [
      this.processService.getCategoryProcesses(this.model.categoryId),
      this.documentService.getCategoryDocuments(this.model.categoryId),
    ];

    forkJoin(sources).subscribe({
      next: (response) => {
        this.processesTableData.data = response[0] as ProcessModel[];
        this.documentTableData.data = response[1] as DocumentModel[];
        this.isLoading = false;
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.categoryId == null ||
      this.model.categoryId == undefined ||
      this.model.categoryId == 0;
  }

  public get processesTabTitle(): string {
    return `Associated Processes (${this.processesTableData.data.length})`;
  }

  public get documentTabTitle(): string {
    return `Associated Documents (${this.documentTableData.data.length})`;
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.categoryService.saveCategory(this.model).subscribe({
        next: (response: CategoryModel) => {
          this.model = response
          this.toastr.success("Category Saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false
        }
      });
    }
  }

  public openProcess(process: ProcessModel) {
    this.close();
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${process.processGroupId}/${process.processId}`);
  }

  public close() {
    this.dialogRef.close();
  }

  public downloadDocument(document: DocumentModel): void {
    if (document.fileDocument) {
      this.fileDocumentService.downloadFileDocument(document.fileDocument).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/categories/${this.model.categoryId}`);
      this.toastr.info("Share link copied to clipboard.")
  }
}
