<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="col-sm-12">
    <p>
      <button mat-flat-button color="accent" class="button accent-button" (click)="addCompany()">Add Company</button>
    </p>
    <table #companyTable mat-table [dataSource]="companyTableData" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Title</th>
        <td mat-cell *matCellDef="let company">
          <a mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/admin/company/{{company.companyId}}" class="button table-button">{{company.displayName}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let company" class="mat-cell-end">
          <button mat-icon-button color="accent" (click)="deleteCompany(company)" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let company; columns: columns"></tr>
    </table>
  </div>
</div>

