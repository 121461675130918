import { Component, Inject } from '@angular/core'
import { ResourceGroupModel } from '../../../models/resource-group-model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ResourceService } from '../../../services/resource.service';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from '../../../services/validation-service';
import { NgForm, FormsModule } from '@angular/forms';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-resource-group-dialog',
    templateUrl: './resource-group-dialog.component.html',
    styleUrls: ['./resource-group-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, NgIf, MatFormField, MatLabel, MatInput, MatError, MatDialogActions, MatButton]
})

export class ResourceGroupDialogComponent {
  public model: ResourceGroupModel;
  public dialogTitle: string = 'New Resource Group';
  public isBusy: boolean = false;
  public isNew: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) private resourceGroup: ResourceGroupModel,
    private dialogRef: MatDialogRef<ResourceGroupDialogComponent>,
    private resourceService: ResourceService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService) {
    this.model = resourceGroup;

    if (this.model.resourceGroupId && this.model.resourceGroupId != 0) {
      this.dialogTitle = this.model.title;
      this.isNew = false;
    }
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.resourceService.saveResourceGroup(this.model).subscribe({
        next: (response: ResourceGroupModel) => {
          this.model = response
          this.toastr.success("Resource Group Saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/resources/${this.model.resourceGroupId}`);
      this.toastr.info("Share link copied to clipboard.")
  }

  public close() {
    this.dialogRef.close();
  }
}
