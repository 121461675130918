import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { AutocompleteOptionModel } from '../../models/autocomplete-option-model';
import { NgIf, NgFor } from '@angular/common';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatLabel, MatInput, FormsModule, MatAutocompleteTrigger, MatIcon, MatSuffix, MatAutocomplete, NgFor, MatOption, MatSelect]
})
export class AutocompleteComponent implements OnInit {
  public filteredOptions: AutocompleteOptionModel[];
  public selectedOption: AutocompleteOptionModel | null;

  @Input() canBeNull: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() options: Array<AutocompleteOptionModel> = [];
  @Input() placeholder: string = "";
  @Input() label: string = "";
  @Input() value: number | null = null;
  @Output() valueChange = new EventEmitter<number | null>;

  private nullOption: AutocompleteOptionModel = {
    id: null,
    displayValue: "None"
  };

  private isInitialised: boolean = false;

  ngOnInit() {
    this.setOptions();
    this.isInitialised = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isInitialised) {
      return;
    }

    if (changes["value"] && this.value != this.selectedOption?.id) {
      if (!this.value) {
        this.selectedOption = null;
      } else {
        this.selectedOption!.id = this.value;
      }
    }
    this.setOptions();
  }

  private setOptions(): void {
    this.options = this.options.sort((a, b) =>
      (a.displayValue.toLowerCase() > b.displayValue.toLowerCase()) ? 1 : (b.displayValue.toLowerCase() > a.displayValue.toLowerCase()) ? -1 : 0);

    if (this.value) {
      let option = this.options.find(i => i.id == this.value);
      if (option) {
        this.selectedOption = option;
      }
    }

    this.filteredOptions = this.filter('');
  }

  public applyFilter(value: string) {
    this.filteredOptions = this.filter(value);
  }

  private filter(value: string): AutocompleteOptionModel[] {
    if (typeof value !== 'string') {
      if (this.canBeNull && !this.doesListIncludeNullOption()) {
        this.options.unshift(this.nullOption);
      }
      return this.options;
    }

    if (value == '' && this.canBeNull) {
      if (!this.doesListIncludeNullOption()) {
        this.options.unshift(this.nullOption);
      }
    } else if (this.doesListIncludeNullOption()) {
      this.options.splice(this.options.indexOf(this.nullOption), 1);
    }

    // Ensure null option is placed at the start of the list.
    if (this.canBeNull && this.doesListIncludeNullOption()) {
      let nullIndex = this.options.findIndex(i => i.id == null);
      if (nullIndex != 0) {
        this.options.unshift(this.options.splice(nullIndex, 1)[0]);
      }
    }

    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.displayValue.toLowerCase().includes(filterValue));
  }

  public optionSelected(e: MatAutocompleteSelectedEvent) {
    this.valueChange.emit(this.selectedOption?.id);
  }

  public displayProperty(value: AutocompleteOptionModel) {
    if (value) {
      return value.displayValue;
    }

    return "";
  }

  private doesListIncludeNullOption(): boolean {
    return this.options.some(i => i.id == null);
  }
}
