import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { lastValueFrom } from 'rxjs';

export const companyGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const userService = inject(UserService);

  let companyName = state.url.split('/')[1];
  
  if (authService.currentUser && companyName != authService.currentUser?.companyName) {
    const response = await lastValueFrom(userService.refreshUser(authService.currentUser.userId!, companyName));
    if (response) {
      authService.setToken(response.token);
      authService.setRefreshToken(response.refreshToken);
      authService.setCurrentUser(response.user, true);
      return true;
    }

    router.navigate(['company-select']);
    return false;
  }

  return true;
};
