import { AutocompleteOptionModel } from "./autocomplete-option-model";
import { ProcessTaskCustomModel } from "./process-task-custom-model";
import { ProcessTaskDocumentModel } from "./process-task-document-model";
import { ProcessTaskProcessModel } from "./process-task-process-model";
import { ProcessTaskResourceModel } from "./process-task-resource-model";
import { ProcessTaskStandardSectionModel } from "./process-task-standard-section-model";
import { RoleModel } from "./role-model";

export class ProcessTaskModel extends AutocompleteOptionModel {
  public processTaskId: number;
  public processId: number;
  public title: string;
  public description: string;
  public isHeader: boolean = false;
  public index: number;
  public branchToProcessTaskId: number | null = null;
  public branchToReason: string | null = null;
  public responsibleRoleId: number | null = null;
  public notes: string;
  public isMilestone: boolean = false;
  public milestoneReason: string | null = null;

  public processTaskDocuments: Array<ProcessTaskDocumentModel> = [];
  public processTaskProcesses: Array<ProcessTaskProcessModel> = [];
  public processTaskStandardSections: Array<ProcessTaskStandardSectionModel> = [];
  public processTaskCustoms: Array<ProcessTaskCustomModel> = [];
  public processTaskResources: Array<ProcessTaskResourceModel> = [];
  public branchToProcessTask: ProcessTaskModel;
  public accountableRoles: Array<RoleModel> = [];
  public consultedRoles: Array<RoleModel> = [];
  public informedRoles: Array<RoleModel> = [];

  public headerSequenceNumber: number;
  public subSequenceNumber: number;
  public descriptionTo: string | null;
  public descriptionFrom: string | null;
  public descriptionReason: string | null;

  public showNotes: boolean = false;

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.processTaskId;
      this.displayValue = data.title ?? "";

      if (data.accountableRoles) {
        data.accountableRoles = data.accountableRoles.map((i: any) => new RoleModel(i));
      }

      if (data.consultedRoles) {
        data.consultedRoles = data.consultedRoles.map((i: any) => new RoleModel(i));
      }

      if (data.informedRoles) {
        data.informedRoles = data.informedRoles.map((i: any) => new RoleModel(i));
      }

      if (data.processTaskStandardSections) {
        data.processTaskStandardSections = data.processTaskStandardSections.map((i: any) => new ProcessTaskStandardSectionModel(i))
      }

      if (data.processTaskResources) {
        data.processTaskResources = data.processTaskResources.map((i: any) => new ProcessTaskResourceModel(i));
      }

      if (data.processTaskCustoms) {
        data.processTaskCustoms = data.processTaskCustoms.map((i: any) => new ProcessTaskCustomModel(i));
      }

      Object.assign(this, data);
    }
  }
}
