import { CompanyModel } from "./company-model";
import { FileDocumentMetadataModel } from "./file-document-metadata-model";

export class CompanyWrapperModel {
  public company: CompanyModel;
  public companyLogo: FileDocumentMetadataModel;

  constructor(data?: any) {
    if (data) {
      if (data.company) {
        data.company = new CompanyModel(data.company);
      }

      if (data.logo) {
        data.logo = new FileDocumentMetadataModel(data.logo);
      }

      Object.assign(this, data);
    }
  }
}
