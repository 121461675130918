<mat-card appearance="outlined" class="list-card">
  <mat-card-content class="wiki-card-content">
    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

    <div *ngIf="!isLoading">
      <mat-drawer-container class="mat-drawer-container" [@.disabled]="true">
        <mat-drawer #wikiDrawer mode="side" opened="true" class="wiki-drawer">
          <mat-tree [dataSource]="wikiTreeDataSource" [treeControl]="wikiTreeControl" class="wiki-drawer-tree">
            <ul>
              <mat-tree-node *matTreeNodeDef="let node">
                <li class="mat-tree-node">
                  <button mat-button
                          *ngIf="node.parentId && node.id"
                          (click)="navigateToPage(node)"
                          class="button mat-drawer-tree-button"
                          [class.mat-drawer-active-tree-button]="isActiveNode(node)">
                    {{node.title}}
                  </button>
                  <button mat-button
                          *ngIf="canEdit() && node.parentId && !node.id"
                          class="button mat-drawer-tree-button"
                          (click)="addPage(node)">
                    <mat-icon color="accent">add</mat-icon>
                    {{ node.title }}
                  </button>
                  <button mat-button *ngIf="!node.parentId && !node.id" class="button" (click)="addGroup()">
                    <mat-icon color="accent">add</mat-icon>
                    {{ node.title }}
                  </button>
                </li>
              </mat-tree-node>

              <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChild">
                <li>
                  <div class="mat-tree-node">
                    <button mat-button matTreeNodeToggle class="button mat-drawer-tree-button">
                      <mat-icon>
                        {{wikiTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                      </mat-icon>
                      {{node.title}}
                    </button>
                  </div>
                  <ul [class.wiki-drawer-tree-invisible]="!wikiTreeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </ul>
          </mat-tree>
        </mat-drawer>

        <mat-drawer-content class="mat-drawer-content">
          <div *ngIf="currentPage" class="wiki-container">
            <div *ngIf="isEditMode">
              <button *ngIf="canEdit()"
                      mat-flat-button
                      color="accent"
                      class="button accent-button"
                      (click)="isEditMode = !isEditMode">
                Switch To View Mode
              </button>

              <div>
                <mat-checkbox [(ngModel)]="currentPage.isViewerPage">Viewer Page?</mat-checkbox>
              </div>
              <div>
                <mat-checkbox [(ngModel)]="currentPage.isEditorPage">Editor Page?</mat-checkbox>
              </div>
              <div>
                <mat-checkbox [(ngModel)]="currentPage.isAdminPage">Admin Page?</mat-checkbox>
              </div>

              <button mat-flat-button color="accent" class="button accent-button" (click)="save()">Save</button>

              <h1 class="wiki-group-header">{{currentPage.wikiGroupTitle}}</h1>
              <mat-form-field color="accent">
                <mat-label>Header</mat-label>
                <input matInput
                       [(ngModel)]="currentPage.header" />
              </mat-form-field>
              <quill-editor class="wiki-editor" [(ngModel)]="currentPage.text"></quill-editor>
            </div>

            <div *ngIf="!isEditMode">
              <button *ngIf="canEdit()" mat-flat-button color="accent" class="button accent-button" (click)="isEditMode = !isEditMode">Switch To Edit Mode</button>
              <h1 style="margin-top:0;">{{currentPage.wikiGroupTitle}}</h1>
              <div class="wiki-content">
                <h2>{{currentPage.header}}</h2>
                <div [innerHtml]="currentPage.text"></div>
              </div>
            </div>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </mat-card-content>
</mat-card>
