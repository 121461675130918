export class CustomRegisterModel {
  public customGroupTitle: string;
  public customName: string;
  public value: string | null;
  public unitOfMeasure: string | null;
  public description: string | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
