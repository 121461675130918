import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, NgIf, MatButton]
})
export class InfoDialogComponent {
  public title: string = "";
  public message: string = "";
  public showCloseButton: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<InfoDialogComponent>) {

    if (data.title) {
      this.title = data.title;
    }

    if (data.message) {
      this.message = data.message;
    }

    if (data.showCloseButton) {
      this.showCloseButton = data.showCloseButton;
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
