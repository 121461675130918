import { Component, Inject, OnInit } from '@angular/core';
import { ProcessGroupModel } from '../../../models/process-group-model';
import { ProcessService } from '../../../services/process.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { CategoryModel } from '../../../models/category-model';
import { CategoryService } from '../../../services/category.service';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-process-group-dialog',
  templateUrl: './process-group-dialog.component.html',
  styleUrls: ['./process-group-dialog.component.scss']
})
export class ProcessGroupDialogComponent {
  public model: ProcessGroupModel;
  public dialogTitle: string = "New Process Group";
  public isBusy: boolean = false;
  public isLoading: boolean = true;
  public isNew: boolean = true;
  public categories: Array<CategoryModel> = [];

  constructor(@Inject(MAT_DIALOG_DATA) private processGroup: ProcessGroupModel,
    private dialogRef: MatDialogRef<ProcessGroupDialogComponent>,
    private processService: ProcessService,
    private toastr: ToastrService,
    private authService: AuthService,
    private categoryService: CategoryService,
    private validationService: ValidationService) {
    this.model = structuredClone(processGroup);

    if (this.model.processGroupId && this.model.processGroupId != 0) {
      this.dialogTitle = this.model.title;
      this.isNew = false;
    }
  }

  public ngOnInit(): void {
    this.categoryService.getCategories().subscribe({
      next: (response: Array<CategoryModel>) => {
        this.categories = response;
        this.isLoading = false;
      }
    })
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.processService.saveProcessGroup(this.model).subscribe({
        next: (response: ProcessGroupModel) => {
          this.model = response;
          this.isBusy = false;
          this.toastr.success("Process Group saved");
          this.dialogRef.close(this.model);
        }, error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/processes/${this.model.processGroupId}`);
    this.toastr.info("Share link copied to clipboard.")
  }

  public close() {
    this.dialogRef.close();
  }
}
