import { ProcessAuditTaskNonConformanceTypeConstant } from "../constants/process-audit-task-non-conformance-type-constant";
import { UserModel } from "./user-model";

export class ProcessAuditTaskNonConformanceModel {
  public processAuditTaskNonConformanceId: number;
  public processAuditTaskId: number;
  public type: string;
  public title: string;
  public identifier: number;
  public reportedByUser: UserModel;
  public reportedDate: Date;
  public description: string;
  public clause: string | null;
  public status: string;
  public rejectionComment: string;
  public closedByUser: UserModel;
  public closureApproverUser: UserModel;
  public closedUtc: Date;

  public get typeDescription(): string {
    return this.type === ProcessAuditTaskNonConformanceTypeConstant.NonConformance ? "Non Conformance" : "Observation";
  }

  constructor(data?: any) {
    if (data) {
      if (data.reportedByUser) {
        data.reportedByUser = new UserModel(data.reportedByUser);
      }

      if (data.closedByUser) {
        data.closedByUser = new UserModel(data.closedByUser);
      }

      if (data.closureApproverUser) {
        data.closureApproverUser = new UserModel(data.closureApproverUser);
      }

      Object.assign(this, data);
    }
  }
}
