export class FileDocumentMetadataModel {

  public fileDocumentId: number;
  public fileName: string;
  public size: number;
  public icon: string;
  public uploadedBy: string;
  public uploadedDate: Date;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
