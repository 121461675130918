import { Component, Inject } from "@angular/core";
import { ResourceModel } from "../../../../models/resource-model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ResourceService } from "../../../../services/resource.service";
import { ToastrService } from "ngx-toastr";
import { ProcessTaskResourceModel } from "../../../../models/process-task-resource-model";

@Component({
  selector: 'app-process-task-resource-dialog',
  templateUrl: './process-task-resource-dialog.component.html',
  styleUrls: ['./process-task-resource-dialog.component.scss']
})

export class ProcessTaskResourceDialogComponent {
  public resourceOptions: Array<ResourceModel> = [];
  public selectedResourceIds: Array<number> = [];
  public isLoading: boolean = true;
  public quantity: string | null;
  public resourceSelectionId: number | null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProcessTaskResourceDialogComponent>,
    private resourceService: ResourceService,
    private toastr: ToastrService) {
    this.selectedResourceIds = data.selectedResourceIds;
  }

  public ngOnInit() {
    this.resourceService.getResources().subscribe({
      next: (response: Array<ResourceModel>) => {
        this.resourceOptions = response.filter(i => !this.selectedResourceIds.includes(i.resourceId));
        this.isLoading = false;
      }
    });
  }

  public save() {
    if (!this.resourceSelectionId) {
      this.toastr.error("Resource must be selected");
      return;
    }

    let model = new ProcessTaskResourceModel();
    model.resourceId = this.resourceSelectionId;
    model.quantity = this.quantity;

    this.dialogRef.close(model);
  }

  public close() {
    this.dialogRef.close(null);
  }
}
