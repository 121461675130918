import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { AuthApiService } from '../../../services/auth-api.service';
import { MultiFactorAuthenticationSubmitModel } from '../../../models/multi-factor-authentication-submit-model';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-multi-factor-authentication-dialog',
    templateUrl: './multi-factor-authentication-dialog.component.html',
    styleUrls: ['./multi-factor-authentication-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, MatButton]
})
export class MultiFactorAuthenticationDialogComponent {
  public code: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MultiFactorAuthenticationDialogComponent>,
    private authApiService: AuthApiService) {
  }

  public submit() {
    if (!this.code || this.code == "") {
      return;
    }

    let model = new MultiFactorAuthenticationSubmitModel;
    model.emailAddress = this.data.emailAddress;
    model.code = this.code;

    this.authApiService.submitMultiFactorAuthenticationCode(model).subscribe({
      next: () => {
        this.dialogRef.close(true);
      }
    });
  }

  public close() {
    this.dialogRef.close(false);
  }
}
