import { Component, Inject, OnInit } from "@angular/core";
import { UserModel } from '../../../models/user-model';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../../services/user.service";
import { ToastrService } from "ngx-toastr";
import { AutocompleteOptionModel } from "../../../models/autocomplete-option-model";

@Component({
  selector: 'app-user-roles-dialog',
  templateUrl: './user-role-dialog.component.html',
  styleUrls: ['./user-role-dialog.component.scss']
})
export class UserRoleDialogComponent implements OnInit {
  public users: Array<UserModel> = [];

  public selectedUserIds: Array<number> = [];
  public isLoading: boolean = true;

  private _selectionId: number | null;

  public get selectionId(): number | null {
    return this._selectionId;
  }

  public set selectionId(value: number | null) {
    if (this._selectionId != value) {
      this._selectionId = value;
      this.dialogRef.close(this._selectionId);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserRoleDialogComponent>,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.selectedUserIds = data.selectedUserIds;
  }

  public ngOnInit() {
    this.userService.getUsers().subscribe({
      next: (response: Array<UserModel>) => {
        this.users = response.filter(i => !this.selectedUserIds.includes(i.userId) && i.isEnabled);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastr.error("Failed to load users");
      }
    });
  }

  public close() {
    this.dialogRef.close(null);
  }
}
