import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from "@angular/material/table";
import { ResourceRegisterModel } from "../../../models/resource-register-model";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { ResourceService } from "../../../services/resource.service";
import { MatTableUtility } from "../../../utility/mat-table-utility";
import { FileDocumentUtility } from "../../../utility/file-document-utility";
import { NgIf } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MatCard, MatCardContent } from "@angular/material/card";
import { MatButton } from "@angular/material/button";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";

@Component({
    selector: 'app-resource-register',
    templateUrl: './resource-register.component.html',
    styleUrls: ['./resource-register.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatButton, MatFormField, MatInput, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})

export class ResourceRegisterComponent implements OnInit {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public resourceRegisterTableData = new MatTableDataSource<ResourceRegisterModel>([]);
  public resourceRegisterColumns: string[] = ['resourceGroup', 'resourceName', 'quantity', 'unitOfMeasure', 'description'];

  private resourceSort: MatSort;

  @ViewChild('resourceRegisterTable', { read: MatSort, static: false }) set resourceRegisterSortValue(value: MatSort) {
    if (value) {
      this.resourceSort = value;
      this.resourceRegisterTableData.sort = this.resourceSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.resourceRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private resourceService: ResourceService) {
  }

  public ngOnInit(): void {
    this.resourceRegisterTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.resourceService.getResourceRegister().subscribe({
      next: (response: Array<ResourceRegisterModel>) => {
        this.resourceRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.resourceService.exportRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
