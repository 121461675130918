<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>{{ title }}</h2>
  </div>

  <mat-dialog-content>
    <span>{{ message }}</span>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button *ngIf="!showCloseButton" mat-raised-button class="button" (click)="close()">Close</button>
  </mat-dialog-actions>
</section>
