import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ProcessModel } from '../../../../models/process-model';
import { AuthService } from '../../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { StandardSectionModel } from '../../../../models/standard-section-model';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { StandardService } from '../../../../services/standard.service';
import { StandardSectionSelectDialogComponent } from '../../../../shared/standard-section-select-dialog/standard-section-select-dialog.component';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableUtility } from '../../../../utility/mat-table-utility';
import { NgIf } from '@angular/common';
import { LoadingSpinnerComponent } from '../../../../shared/loading-spinner/loading-spinner.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-process-standard-list-dialog',
    templateUrl: './process-standard-list-dialog.component.html',
    styleUrls: ['./process-standard-list-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, MatButton, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatDialogActions]
})
export class ProcessStandardListDialogComponent implements OnInit {
  public isLoading: boolean = true;
  public standardSections: Array<StandardSectionModel> = [];
  public processStandardsTableData = new MatTableDataSource<StandardSectionModel>([]);
  public processStandardsColumns: string[] = ['standardTitle', 'title'];

  private standardSort: MatSort;

  @ViewChild('processStandardsTable', { read: MatSort, static: false }) set standardSortValue(value: MatSort) {
    if (value) {
      this.standardSort = value;
      this.processStandardsTableData.sort = this.standardSort;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) public process: ProcessModel,
    private dialogRef: MatDialogRef<ProcessStandardListDialogComponent>,
    private authService: AuthService,
    private standardService: StandardService,
    private toastr: ToastrService,
    private dialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.processStandardsTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    if (this.canEdit()) {
      this.processStandardsColumns.push("controls");
    }

    this.standardService.getStandardSectionsForProcess(this.process.processId).subscribe({
      next: (response: Array<StandardSectionModel>) => {
        this.standardSections = response;
        this.refreshProcessStandardsTableData();
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  private refreshProcessStandardsTableData(): void {
    this.processStandardsTableData.data = this.standardSections;
  }

  public createProcessStandard() {
    if (!this.canEdit()) {
      return;
    }

    const selectedStandardSectionIds = this.standardSections.map(i => i.standardSectionId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedStandardSectionIds: selectedStandardSectionIds,
      },
      width:"60%",
    }

    const dialogRef = this.dialog.open(StandardSectionSelectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: number | null) => {
        if (result) {
          this.standardService.saveProcessStandardSection(this.process.processId, result).subscribe({
            next: (response: StandardSectionModel) => {
              this.standardSections.push(response);
              this.refreshProcessStandardsTableData();
              this.toastr.success("Standard section added");
            }
          });
        }
      }
    });
  }

  public deleteProcessStandard(section: StandardSectionModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Process Standard "${section.title}"?`)) {
      return;
    }

    this.standardService.deleteProcessStandardSection(this.process.processId, section.standardSectionId!).subscribe({
      next: () => {
        this.standardSections.splice(this.standardSections.indexOf(section), 1);
        this.refreshProcessStandardsTableData();
        this.toastr.success("Process Standard deleted");
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
