import { Component, inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserVerificationModel } from '../../models/user-verification-model';
import { TokenTypeConstant } from '../../constants/token-type-constant';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent {
  private route = inject(ActivatedRoute);

  public isLoading: boolean;
  public model: UserVerificationModel;
  public title: string | null;

  constructor(private userService: UserService,
    private toastr: ToastrService,
    private router: Router) { }

  public ngOnInit() {
    this.isLoading = true;
    const verifyType = this.route.snapshot.url[0].path;
    const token = this.route.snapshot.paramMap.get('token');
    if (verifyType === TokenTypeConstant.Verify) {
      this.title = "Verify Account";
      this.userService.getVerification(token).subscribe({
        next: (response: UserVerificationModel) => {
          this.model = response;
          this.isLoading = false;
        }
      });
    } else if (verifyType === TokenTypeConstant.Reset) {
      this.title = "Reset Password";
      this.userService.getReset(token).subscribe({
        next: (response: UserVerificationModel) => {
          this.model = response;
          this.isLoading = false;
        }
      });
    }
  }

  public submit = (form: NgForm) => {
    if (form.valid) {
      this.userService.postVerification(this.model).subscribe({
        next: () => {
          this.toastr.success("Account Verified, please sign in to continue");
          this.router.navigate(["/sign-in"]);
        }
      });
    }
  }
}
