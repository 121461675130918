export class ProcessAuditTaskModel {
  public processAuditTaskId: number;
  public processAuditId: number;
  public headerSequenceNumber: number;
  public subSequenceNumber: number;
  public title: string | null;
  public description: string | null;
  public responsibleRole: string | null;
  public evidence: string | null;
  public observation: string | null;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}
