export class ProcessRevisionRegisterModel {
  public reference: string;
  public processTitle: string;
  public processGroupId: number;
  public processId: number;
  public draft: boolean = false;
  public revision: string | null;
  public revisionDate: Date | null;
  public reason: string | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
