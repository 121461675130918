import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StandardSectionModel } from '../../../models/standard-section-model';
import { StandardService } from '../../../services/standard.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { RoleModel } from '../../../models/role-model';
import { RoleService } from '../../../services/role.service';
import { RoleTitleUtility } from '../../../utility/role-title-utility';
import { DocumentService } from '../../../services/document.service';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentModel } from '../../../models/document-model';
import { ProcessModel } from '../../../models/process-model';
import { MatSort } from '@angular/material/sort';
import { MatTableUtility } from '../../../utility/mat-table-utility';
import { DocumentSelectDialogComponent } from '../../../shared/document-select-dialog/document-select-dialog.component';
import { ProcessService } from '../../../services/process.service';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { FileDocumentService } from '../../../services/file-document-service';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-standard-section-dialog',
  templateUrl: './standard-section-dialog.component.html',
  styleUrls: ['./standard-section-dialog.component.scss']
})
export class StandardSectionDialogComponent implements OnInit {
  public dialogTitle: string = "New Section";
  public isBusy: boolean = false;
  public isLoading: boolean = true;
  public roles: Array<RoleModel> = [];
  public documents: Array<DocumentModel> = [];
  public documentTableData = new MatTableDataSource<DocumentModel>([]);
  public documentColumns: string[] = ['documentName']

  public processesTableData = new MatTableDataSource<ProcessModel>([]);
  public processesColumns: string[] = ['title'];

  private documentSort: MatSort;
  private processSort: MatSort;

  @ViewChild('documentsTable', { read: MatSort, static: false }) set documentSortValue(value: MatSort) {
    if (value) {
      this.documentSort = value;
      this.documentTableData.sort = this.documentSort;
    }
  };

  @ViewChild('standardSectionProcessesTable', { read: MatSort, static: false }) set standardSectionSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processesTableData.sort = this.processSort;
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public model: StandardSectionModel,
    private dialogRef: MatDialogRef<StandardSectionDialogComponent>,
    private standardService: StandardService,
    private documentService: DocumentService,
    private roleService: RoleService,
    private processService: ProcessService,
    private toastr: ToastrService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private fileDocumentService: FileDocumentService,
    private validationService: ValidationService) {

    if (this.model.standardSectionId && this.model.standardSectionId != 0) {
      this.dialogTitle = this.model.title;
    }
  }

  public ngOnInit(): void {
    if (this.canEdit()) {
      this.documentColumns.push("controls");
    }

    this.documentTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.roleService.getRoles().subscribe({
      next: (response: Array<RoleModel>) => {
        this.roles = response;

        if (this.isNew) {
          this.isLoading = false;
          return;
        }

        let sources: Array<Observable<any>> = [
          this.documentService.getStandardSectionDocuments(this.model.standardSectionId),
          this.processService.getStandardSectionProcesses(this.model.standardSectionId)
        ];

        forkJoin(sources).subscribe({
          next: (response) => {
            this.documents = response[0] as DocumentModel[];
            this.refreshDocumentTableData();
            this.processesTableData.data = response[1] as ProcessModel[];
            this.isLoading = false;
          }
        });
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.standardSectionId == null ||
      this.model.standardSectionId == undefined ||
      this.model.standardSectionId == 0;
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  private refreshDocumentTableData(): void {
    this.documentTableData.data = this.documents;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.standardService.saveStandardSection(this.model).subscribe({
        next: (response: StandardSectionModel) => {
          this.model = response;
          this.toastr.success("Section saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public getRoleTitle(roleId: number | null): string | null {
    return RoleTitleUtility.getRoleTitle(this.roles, roleId);
  }

  public get documentsTabTitle(): string {
    return `Documents (${this.documentTableData.data.length})`;
  }

  public get processesTabTitle(): string {
    return `Associated Processes (${this.processesTableData.data.length})`;
  }

  public createStandardSectionDocument() {
    if (!this.canEdit()) {
      return;
    }

    const selectedDocumentIds = this.documentTableData.data.map(i => i.documentId);

    const dialogConfig: MatDialogConfig = {
      data: {
        selectedDocumentIds: selectedDocumentIds,
      },
      width: "60%",
    };

    const dialogRef = this.dialog.open(DocumentSelectDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: number | null) => {
        if (result) {
          this.documentService.saveStandardSectionDocument(this.model.standardSectionId, result).subscribe({
            next: (response: DocumentModel) => {
              this.documents.push(response);
              this.refreshDocumentTableData();
              this.toastr.success("Section Document added");
            }
          });
        }
      }
    });
  }

  public deleteStandardSectionDocument(document: DocumentModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Section Documnet "${document.documentName}"?`)) {
      return;
    }

    this.documentService.deleteStandardSectionDocument(this.model.standardSectionId, document.documentId).subscribe({
      next: () => {
        this.documents.splice(this.documents.indexOf(document), 1);
        this.refreshDocumentTableData();
        this.toastr.success("Section Document deleted");
      }
    });
  }

  public openProcess(process: ProcessModel) {
    this.close();
    this.router.navigateByUrl(`/${this.authService.currentUser?.companyName}/processes/${process.processGroupId}/${process.processId}`);
  }

  public close() {
    this.dialogRef.close();
  }

  public downloadDocument(document: DocumentModel): void {
    if (document.fileDocument) {
      this.fileDocumentService.getFileDocument(document.fileDocument).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public share(): void {
    navigator.clipboard.writeText(
      `${document.baseURI}${this.authService.currentUser?.companyName}/standards/${this.model.standardId}/${this.model.standardSectionId}`);
      this.toastr.info("Share link copied to clipboard.")
  }
}
