import { Injectable } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserModel } from "../models/user-model";
import { StorageKeyConstant } from "../constants/storage-key-constant";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _currentUser: UserModel | null;
  private _token: string | null;
  private _refreshToken: string | null;
  private attemptedUrl: string | null;

  constructor(private jwtHelper: JwtHelperService,
    private toastr: ToastrService,
    private router: Router) {
    this._token = this.loadTokenFromStorage();
    this._refreshToken = this.loadRefreshTokenFromStorage();
    this._currentUser = this.loadUserFromStorage();
  }

  public get token(): string | null {
    return this._token;
  }

  public get refreshToken(): string | null {
    return this._refreshToken;
  }

  public get currentUser(): UserModel | null {
    return this._currentUser;
  }

  public isUserAuthenticated(): boolean {
    const token = localStorage.getItem(StorageKeyConstant.TOKEN_KEY);

    try {
      if (token && !this.jwtHelper.isTokenExpired(token)) {
        return true;
      }

      return false;
    } catch {
      this.clearAuthentication();
      return false;
    }
  }

  public get hasUserCompanyBeenSelected(): boolean {
    if (this.currentUser && this.currentUser.hasCompanyBeenSelected) {
      return true;
    }

    return false;
  }

  public setToken(token: string): void {
    this._token = token;
    localStorage.setItem(StorageKeyConstant.TOKEN_KEY, token);
  }

  public setRefreshToken(refreshToken: string): void {
    this._refreshToken = refreshToken;
    localStorage.setItem(StorageKeyConstant.REFRESH_TOKEN_KEY, refreshToken);
  }

  public setCurrentUser(user: UserModel, isCompanySelected: boolean): void {
    this._currentUser = user;
    // GC TODO maybe a bit hacky
    this._currentUser.hasCompanyBeenSelected = isCompanySelected;
    localStorage.setItem(StorageKeyConstant.USER_KEY, JSON.stringify(user));
  }

  private loadTokenFromStorage(): string | null {
    return localStorage.getItem(StorageKeyConstant.TOKEN_KEY);
  }

  private loadRefreshTokenFromStorage(): string | null {
    return localStorage.getItem(StorageKeyConstant.REFRESH_TOKEN_KEY);
  }

  private loadUserFromStorage(): UserModel | null {
    const userData = localStorage.getItem(StorageKeyConstant.USER_KEY);

    if (!userData) {
      return null;
    }

    try {
      return JSON.parse(userData);
    } catch (e) {
      return null;
    }
  }

  public handleExpiredSession() {
    const currentUrl = window.location.pathname;
    this.attemptedUrl = currentUrl;

    if (this.token || this.currentUser) {
      this.clearAuthentication();
      this.toastr.error("Session expired");
    }

    this.router.navigate(["sign-in"]);
  }

  public clearAuthentication(): void {
    this._token = null;
    this._currentUser = null;

    localStorage.removeItem(StorageKeyConstant.TOKEN_KEY);
    localStorage.removeItem(StorageKeyConstant.REFRESH_TOKEN_KEY);
    localStorage.removeItem(StorageKeyConstant.USER_KEY);
  }

  public get canCurrentUserEdit(): boolean {
    if (this.currentUser) {
      return this.currentUser.isEditor ||
        this.currentUser.isCompanyAdministrator ||
        this.currentUser.isAdministrator;
    }

    return false;
  }

  public hasMultipleCompanies(): boolean {
    if (this.currentUser && this.currentUser.companies) {
      return this.currentUser.companies.length > 1;
    }
    return false
  }

  public getAttemptedUrl(): string | null {
    return this.attemptedUrl;
  }

  public clearAttemptedUrl() {
    this.attemptedUrl = null;
  }
}
